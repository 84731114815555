<template>
  <div>
    <el-row>
      <el-col :xs="24" :sm="0">
        <Header/>
        <p style="font-size:25px;color: #286040;margin:20px;">TSUJIRI Design</p>
      </el-col>
      <el-col :xs="24" :sm="0">
        <img style="margin-top:10px;width:100%" :src="require('../assets/designbg.webp')">
      </el-col>
      <el-col :xs="22" :sm="0" :push="1">
        <p style="font-size:18px;text-align:left;">{{exContent.first}}</p>
        <p style="font-size:18px;text-align:left;">{{exContent.second}}</p>
      </el-col>
    </el-row>
    <el-row id="designBg" class="hidden-xs-only">
      <el-col :sm="24">
        <Header/>
        <p style="font-size:45px;color: #286040;margin-top:20px;">TSUJIRI Design</p>
      </el-col>
      <el-col :sm="8" style="background-color:rgba(255,255,255,0.75)" id="designText" :push="15">
        <p style="font-size:21px;text-align:left;margin:70px 30px 0px 30px;">{{exContent.first}}</p>
        <p style="font-size:21px;text-align:left;margin:0px 30px 70px 30px;">{{exContent.second}}</p>
      </el-col>
      <el-col :sm="1">&nbsp;</el-col>
    </el-row>
    <el-row style="margin-top:230px;display: flex;" v-for="(content,index) in contents" type="flex">
      <el-col :xs="0" :sm="1">&nbsp;</el-col>
      <el-col :xs="0" :sm="9" :class="'backdesign'+index" style="align-self: flex-end;text-align:left;margin-top:140px;background-color:rgba(255,255,255,0.7);z-index:999" :push="1">
        <el-row>
          <el-col :xs="0" :sm="2"><p style="background-color:#12312b;width: 15px;height: 50px;"></p></el-col>
          <el-col :xs="0" :sm="22" style="text-align:left;"><div style="font-size: 35px;margin-top:25px;" >{{content.title}}</div></el-col>
          <el-col :xs="0" :sm="20" :pull="2">
            <p :class="'design'+index" style="font-size: 21px;margin:0px;line-height: 1.43;">{{content.first}}</p>
            <p :class="'design'+index" style="font-size: 21px;margin:0px;margin-bottom:50px;line-height: 1.43;">{{content.second}}</p>
          </el-col>
          <el-col :xs="0" :sm="2">&nbsp;</el-col>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="0" :class="'backdesign'+index" style="align-self: flex-end;text-align:left;margin-top:140px;">
        <el-row style="margin-top:-370px;">
          <el-col :xs="24" :sm="0"><img style="width:100%" :src="require('../assets/design'+index+'.jpg')"></el-col>
          <el-col :xs="1" :sm="0" :push="1"><p style="background-color:#12312b;width: 8px;height: 30px;"></p></el-col>
          <el-col :xs="23" :sm="0" style="text-align:left;"><div style="font-size: 20px;margin:20px 0px 0px 20px;" >{{content.title}}</div></el-col>
          <el-col :xs="22" :sm="0" style="">
            <p :class="'design'+index" style="font-size: 18px;margin:0px;line-height: 1.43;">{{content.first}}</p>
            <p :class="'design'+index" style="font-size: 18px;margin:0px;margin-bottom:50px;line-height: 1.43;">{{content.second}}</p>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="0" :sm="15"><img :id="'design'+index" style="margin-top:50px;" v-designscroll="handleScroll" :src="require('../assets/design'+index+'.jpg')"></el-col>
    </el-row>
    <el-row style="margin-bottom:120px;margin-top:50px;" type="flex">
      <el-col :xs="2" :sm="11">&nbsp;</el-col>
      <el-col :xs="22" :sm="10" v-bridgescroll="handleScroll">
        <svg version="1.1" id="圖層_1" width="90%" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 700 113" style="enable-background:new 0 0 240 113;" xml:space="preserve">
          <path id="_x31_" d="M240,7.3V0.2H0v7.2h27.7v19.1H0v7.2h27.7v19.1H0v7.2h27.7V79H0v7.2h27.7v19.1H0v7.2h240v-7.2h-26.2V86.1H240V79
              h-26.2V59.9H240v-7.2h-26.2V33.6H240v-7.2h-26.2V7.3H240z M34.9,33.6h82.3v19.1H34.9V33.6z M34.9,59.9h82.3V79H34.9V59.9z
               M206.7,105.2H34.9V86.1h171.8V105.2z M206.7,79h-82.3V59.9h82.3V79z M206.7,52.7h-82.3V33.6h82.3V52.7z M206.7,26.4H34.9V7.3h171.8
              V26.4z"/>
          <path id="_x32__1_" class="st1" d="M126.4,85.9V68.5H87.6V44.5h38.7V27.1l34.7,29.4L126.4,85.9z"/>
          <g id="bridgeicon">
              <path class="st0" d="M34.9,33.6h82.3v19.1H34.9V33.6z"/>
              <path class="st0" d="M34.9,59.9h82.3V79H34.9V59.9z"/>
              <path class="st0" d="M206.7,105.2H34.9V86.1h171.8V105.2z"/>
              <path class="st0" d="M206.7,79h-82.3V59.9h82.3V79z"/>
              <path class="st0" d="M206.7,52.7h-82.3V33.6h82.3V52.7z"/>
              <path class="st0" d="M206.7,26.4H34.9V7.3h171.8V26.4z"/>
          </g>
        </svg>
      </el-col>
      <el-col :xs="0" :sm="3">&nbsp;</el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import {gsap} from 'gsap';
export default {
  name: 'Design',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      /*exContent:{
        first:this.$t('At TSUJIRI CHAHO, we value the interaction of history and modern lifestyle. Such concepts become the theme of our band design.'),
        second:this.$t('By reviewing our brand’s history and Japanese culture, we continue to discover the core element which can be incorporated into our designs, offering customers who visit TSUJIRI CHAHO a revolutionary experience of tradition and innovation.'),
      },
      contents:[
        {
          title:this.$t('Tea Box (Chabitsu)'),
          first:this.$t('Riemon Tsuji, the founder of TSUJIRI CHAHO, invented the Chabitsu, a wooden cabinet which can maintain the temperature and humidity inside. This invention allows fresh Uji tea leaves to be transported throughout the country. In more ways than one, the Chabitsu has been vital in driving the development of Japanese tea culture.'),
          second:this.$t('To this day, TSUJIRI CHAHO stores still feature Chabitsu on the walls to remember its humble beginnings and to underscore the constant evolution as a brand.'),
        },
        {
          title:this.$t('Vertical Wood Slats (Tategoshi)'),
          first:this.$t('The Tategoshi-style counter design with vertical wood slats exudes elegance and humbleness – two important characteristics of Japanese culture. The TSUJIRI brand name is shown prominently on the shop fronts as a token of TSUJIRI CHAHO’s resolution to spread Japanese tea culture all over the world.'),
        },
        {
          title:this.$t('Tokiwa Bridge'),
          first:this.$t('TSUJIRI CHAHO was first established beside Tokiwa Bridge which across Murasaki River in Kokura City. Tokiwa Bridge marked the east end of the Nagasaki Kaido (Road to Nagasaki) in the old days. During the time, TSUJIRI CHAHO served hundreds of travelers every day with a cup of tea and a place to rest. The heartwarming service coupled with the emotional connection to the location made TSUJIRI CHAHO the top choice for many on their journeys.'),
          second:this.$t('The new store design features effigies of the Tokiwa bridge, symbolizing TSUJIRI CHAHO as a “bridge” for human connections.'),
        },
      ]*/
    };
  },
  computed: {
    exContent() {
      return {
        first:this.$t('At TSUJIRI CHAHO, we value the interaction of history and modern lifestyle. Such concepts become the theme of our band design.'),
        second:this.$t('By reviewing our brand’s history and Japanese culture, we continue to discover the core element which can be incorporated into our designs, offering customers who visit TSUJIRI CHAHO a revolutionary experience of tradition and innovation.'),
      }
    },
    contents() {
      return [
        {
          title:this.$t('Tea Box (Chabitsu)'),
          first:this.$t('Riemon Tsuji, the founder of TSUJIRI CHAHO, invented the Chabitsu, a wooden cabinet which can maintain the temperature and humidity inside. This invention allows fresh Uji tea leaves to be transported throughout the country. In more ways than one, the Chabitsu has been vital in driving the development of Japanese tea culture.'),
          second:this.$t('To this day, TSUJIRI CHAHO stores still feature Chabitsu on the walls to remember its humble beginnings and to underscore the constant evolution as a brand.'),
        },
        {
          title:this.$t('Vertical Wood Slats (Tategoshi)'),
          first:this.$t('The Tategoshi-style counter design with vertical wood slats exudes elegance and humbleness – two important characteristics of Japanese culture. The TSUJIRI brand name is shown prominently on the shop fronts as a token of TSUJIRI CHAHO’s resolution to spread Japanese tea culture all over the world.'),
        },
        {
          title:this.$t('Tokiwa Bridge'),
          first:this.$t('TSUJIRI CHAHO was first established beside Tokiwa Bridge which across Murasaki River in Kokura City. Tokiwa Bridge marked the east end of the Nagasaki Kaido (Road to Nagasaki) in the old days. During the time, TSUJIRI CHAHO served hundreds of travelers every day with a cup of tea and a place to rest. The heartwarming service coupled with the emotional connection to the location made TSUJIRI CHAHO the top choice for many on their journeys.'),
          second:this.$t('The new store design features effigies of the Tokiwa bridge, symbolizing TSUJIRI CHAHO as a “bridge” for human connections.'),
        },
      ]
    }
  },
  methods: {
    handleScroll: function (evt, el) {
      function getPosition (element) {
        var y = 0;
        // 搭配上面的示意圖可比較輕鬆理解為何要這麼計算
        while ( element ) {
          y += element.offsetTop - element.scrollLeft + element.clientTop;
          element = element.offsetParent;
        }

        return {y: y};
      }
      var position = getPosition(el);
      return position.y-300
    },
  },
  directives: {
    designscroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (window.scrollY>binding.value(evt, el)) {
            let text = document.getElementsByClassName(el.id);
            let back = document.getElementsByClassName('back'+el.id);
            var tx = gsap.timeline({repeat: 0, repeatDelay: 0.5});
            tx.to(el, 1, {
              y: 0,
              opacity: 1,
              zIndex:10,
            },0)
            tx.to(text,1,{y:40,opacity:1},0.5)
            tx.to(back,1,{x:0},0)
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    },
    bridgescroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (window.scrollY+300>binding.value(evt, el)) {
            gsap.set('.st1',{x:200,opacity:0})
            var tx = gsap.timeline({repeat: 0, repeatDelay: 0.5});
            tx.to('#bridgeicon',2,{x:500,scaleX:0.6,attr:{fill:'#10312A'}},0.5)
            tx.to('.st1',1,{x:270,opacity:1})
            //tx.to('#bridgeicon',1,{scaleX:0.6})
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    }
  },
  mounted(){
    window.scroll(0,0)
    let obj = document.getElementById("designBg");
    let text = document.getElementById("designText");
    var tl = gsap.timeline({repeat: 0, repeatDelay: 0.5});
    tl.from(obj,1,{opacity:0.1})
    tl.from(text,1,{opacity:0.1,y:20})
    gsap.set('.design0',{opacity:0,y:100})
    gsap.set('.design1',{opacity:0,y:100})
    gsap.set('.design2',{opacity:0,y:100})
    gsap.set('.backdesign0',{x:-20})
    gsap.set('.backdesign1',{x:-20})
    gsap.set('.backdesign2',{x:-20})
    gsap.set('#design0',{opacity:0})
    gsap.set('#design1',{opacity:0})
    gsap.set('#design2',{opacity:0})
    //tl.to('#bridge',2,{x:500})
    //tl.to('#bridge',1,{scaleX:0.6})
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.st0{fill:#10312A;}
.st1{fill-rule:evenodd;clip-rule:evenodd;}
#designBg{
  background-repeat: no-repeat;
  min-height: 940px;
  background-size: cover;
  background-image: url('../assets/designbg.webp');
}
#smallBg{
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../assets/designbg.webp');
}
</style>
