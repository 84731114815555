<template>
  <div id="history">
    <el-row>
      <Header/>
    </el-row>
    <el-row type="flex">
      <el-col :xs="2" :sm="2">&nbsp;</el-col>
      <el-col :xs="20" :sm="20">
        <el-tabs v-model="activeName">
          <el-tab-pane>
            <span slot="label">
              <el-row>
                <el-col style="font-size: 45px;" :xs="0" :sm="12">
                  The Founder
                </el-col>
                <el-col style="font-size: 18px;" :xs="12" :sm="0">
                  The Founder
                </el-col>
              </el-row>
            </span>
            <el-row v-for="story in stories" style="font-size: 18px;margin-top:20px;" type="flex">
              <el-col :xs="0" :sm="1">&nbsp;</el-col>
              <el-col :xs="2" :sm="2" style="text-align:left;">
                {{story.time}}
              </el-col>
              <el-col :xs="2" :sm="0">&nbsp;</el-col>
              <el-col :xs="20" :sm="21" style="text-align:left;">
                {{story.content}}<br>
                <el-row>
                  <el-col :xs="0" :sm="24">
                    <span v-for="img in story.imgs"><img v-show="img!=undefined" :src="img" alt="" style="margin-top:5px;"></span>
                  </el-col>
                  <el-col :xs="24" :sm="0">
                    <span v-for="img in story.imgs"><img v-show="img!=undefined" :src="img" alt="" style="margin-top:5px;width:100%;"></span>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <el-row>
                <el-col style="font-size: 45px;" :xs="0" :sm="12">
                  TSUJIRI CHAHO
                </el-col>
                <el-col style="font-size: 16px;" :xs="12" :sm="0">
                  TSUJIRI CHAHO
                </el-col>
              </el-row>
            </span>
            <el-row v-for="chaho in chahos" style="font-size: 18px;margin-top:20px;" type="flex">
              <el-col :xs="0" :sm="1">&nbsp;</el-col>
              <el-col :xs="2" :sm="2" style="text-align:left;">
                {{chaho.time}}
              </el-col>
              <el-col :xs="2" :sm="0">&nbsp;</el-col>
              <el-col :xs="20" :sm="21" style="text-align:left;">
                {{chaho.content}}<br>
                <el-row>
                  <el-col :xs="0" :sm="24">
                    <span v-for="img in chaho.imgs"><img v-show="img!=undefined" :src="img" alt="" style="margin-top:5px;"></span>
                  </el-col>
                  <el-col :xs="24" :sm="0">
                    <span v-for="img in chaho.imgs"><img v-show="img!=undefined" :src="img" alt="" style="margin-top:5px;width:100%;"></span>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :xs="2" :sm="2">&nbsp;</el-col>
    </el-row>
    <el-footer style="height:143px;padding:0px;margin-top:30px;">
      <Footer/>
    </el-footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'History',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      activeName:'0',
      /*stories:[
        {
          time:this.$t('1844'),
          content:this.$t('The founder, Riemon Tsuji, was born in Uji, Kyoto in 1844 May 24th.'),
        },{
          time:this.$t('1860'),
          content:this.$t('At the age of 17, Riemon Tsuji first established TSUJIRI at the end of Japan\'s Edo period in Uji, Kyoto.'),
        },{
          time:this.$t('1868'),
          content:this.$t('Refined method for producing Gyokuro by refining the kneading process for shaping Gyokuro leaves, Riemon Tsuji enhanced their Umami (sweetness) and Kaori (aroma), resulting in the highest grade of Japanese green tea ever since.'),
        },{
          time:this.$t('1889'),
          content:this.$t('Riemon Tsuji invented “Chabitsu”, by coating the interior of wooden cabinet with tin, a highly crystalline silvery-white metal which prevents oxidation and resists corrosion from moisture, enabled fresh tea leaves transporting from Kyoto to other cities in Japan.'),
          imgs:[require('../assets/history0.jpg'),]
        },{
          time:this.$t('1933'),
          content:this.$t('In honor of Riemon Tsuji contribution to the Japanese tea industry, a statue was erected in Byodoin, Kyoto’s world heritage temple.'),
          imgs:[require('../assets/history1.jpg'),]
        },
      ],
    chahos:[
      {
        time:this.$t('1925'),
        content:this.$t('The Kokura branch, TSUJIRICHAHO, was then established next to Ichikawa Tokiwa Bridge which was the starting point of old Nagasaki street in the Edo period.'),
        imgs:[require('../assets/1925.jpg'),]
      },{
        time:this.$t('1946'),
        content:this.$t('Opened Uomachi Shop in Uomachi shopping district.'),
        imgs:[require('../assets/1946-1.jpg'),require('../assets/1946-2.jpg')]
      },{
        time:this.$t('1947'),
        content:this.$t('Opened TSUJIRI main shop in Kyomachi shopping district.'),
        imgs:[require('../assets/1947.jpg'),]
      },{
        time:this.$t('1956'),
        content:this.$t('Established TSUJIRICHAHO Ltd.'),
      },{
        time:this.$t('1993'),
        content:this.$t('Traditionally, tea shops only serve tea and sell tea leaves. As a pioneer in this industry, TSUJIRI CHAHO created a cafe corner and serve matcha soft serve ice cream in the tea shop.'),
      },{
        time:this.$t('2010'),
        content:this.$t('TSUJIRI CHAHO has opened the first overseas store in Taipei, Republic of Taiwan. We aim to promote Japanese tea to be enjoyed on a daily basis.'),
        imgs:[require('../assets/2010.jpg'),]
      },{
        time:this.$t('2012'),
        content:this.$t('Opened the first store in Singapore.'),
      },{
        time:this.$t('2013'),
        content:this.$t('Opened the first store in China.'),
      },{
        time:this.$t('2016'),
        content:this.$t('Opened the first store in Australia, Canada, Hong Kong, Indonesia, Malaysia, Thailand, UK.'),
      },{
        time:this.$t('2017'),
        content:this.$t('Opened the first store in the Philippines.'),
      },{
        time:this.$t('2018'),
        content:this.$t('Started from Taiwan, we renovated a new branding concept including logo and store.'),
        imgs:[require('../assets/2018.jpg'),]
      },{
        time:this.$t('2018'),
        content:this.$t('Opened the first store in New Zealand.'),
      },
    ]*/
    };
  },
  computed: {
    stories() {
      return [
        {
          time:this.$t('1844'),
          content:this.$t('The founder, Riemon Tsuji, was born in Uji, Kyoto in 1844 May 24th.'),
        },{
          time:this.$t('1860'),
          content:this.$t('At the age of 17, Riemon Tsuji first established TSUJIRI at the end of Japan\'s Edo period in Uji, Kyoto.'),
        },{
          time:this.$t('1868'),
          content:this.$t('Refined method for producing Gyokuro by refining the kneading process for shaping Gyokuro leaves, Riemon Tsuji enhanced their Umami (sweetness) and Kaori (aroma), resulting in the highest grade of Japanese green tea ever since.'),
        },{
          time:this.$t('1889'),
          content:this.$t('Riemon Tsuji invented “Chabitsu”, by coating the interior of wooden cabinet with tin, a highly crystalline silvery-white metal which prevents oxidation and resists corrosion from moisture, enabled fresh tea leaves transporting from Kyoto to other cities in Japan.'),
          imgs:[require('../assets/history0.jpg'),]
        },{
          time:this.$t('1933'),
          content:this.$t('In honor of Riemon Tsuji contribution to the Japanese tea industry, a statue was erected in Byodoin, Kyoto’s world heritage temple.'),
          imgs:[require('../assets/history1.jpg'),]
        },
      ]
    },
    chahos() {
      return [
        {
          time:this.$t('1925'),
          content:this.$t('The Kokura branch, TSUJIRICHAHO, was then established next to Ichikawa Tokiwa Bridge which was the starting point of old Nagasaki street in the Edo period.'),
          imgs:[require('../assets/1925.jpg'),]
        },{
          time:this.$t('1946'),
          content:this.$t('Opened Uomachi Shop in Uomachi shopping district.'),
          imgs:[require('../assets/1946-1.jpg'),require('../assets/1946-2.jpg')]
        },{
          time:this.$t('1947'),
          content:this.$t('Opened TSUJIRI main shop in Kyomachi shopping district.'),
          imgs:[require('../assets/1947.jpg'),]
        },{
          time:this.$t('1956'),
          content:this.$t('Established TSUJIRICHAHO Ltd.'),
        },{
          time:this.$t('1993'),
          content:this.$t('Traditionally, tea shops only serve tea and sell tea leaves. As a pioneer in this industry, TSUJIRI CHAHO created a cafe corner and serve matcha soft serve ice cream in the tea shop.'),
        },{
          time:this.$t('2010'),
          content:this.$t('TSUJIRI CHAHO has opened the first overseas store in Taipei, Republic of Taiwan. We aim to promote Japanese tea to be enjoyed on a daily basis.'),
          imgs:[require('../assets/2010.jpg'),]
        },{
          time:this.$t('2012'),
          content:this.$t('Opened the first store in Singapore.'),
        },{
          time:this.$t('2013'),
          content:this.$t('Opened the first store in China.'),
        },{
          time:this.$t('2016'),
          content:this.$t('Opened the first store in Australia, Canada, Hong Kong, Indonesia, Malaysia, Thailand, UK.'),
        },{
          time:this.$t('2017'),
          content:this.$t('Opened the first store in the Philippines.'),
        },{
          time:this.$t('2018'),
          content:this.$t('Started from Taiwan, we renovated a new branding concept including logo and store.'),
          imgs:[require('../assets/2018.jpg'),]
        },{
          time:this.$t('2018'),
          content:this.$t('Opened the first store in New Zealand.'),
        },
      ]
    }
  },
  mounted() {
    window.scroll(0,0)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-tabs__nav{
  left:50%;
  transform: translateX(-50%) !important;
}
.el-tabs__active-bar{
  background-color:#286040;
}
.el-tabs__header {
  margin-top: 40px;
}
.el-tabs__item{
  height:80px;
  color: #979797;;
}
.el-tabs__item.is-active{
  color:#286040;
}
</style>
<style scoped>
#history{
  background-repeat: repeat-y;
  background-image: url('../assets/aboutbg.webp');
  background-size: 100%;
  min-height: 800px;;
}
</style>
