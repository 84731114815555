<template>
  <div>
    <el-row id="franchise" style="background-attachment: fixed;" >
      <el-row>
        <el-col :span="24">
          <Header/>
          <p style="font-size: 45px;color: #286040;">Global Franchising</p>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :xs="2" :sm="3">&nbsp;</el-col>
        <el-col :xs="20" :sm="12" class="content">
          <div style="margin-top:40px;">“Who could resist undertaking such a rewarding endeavor?”</div>
          <div style="margin-top:40px;">…and so began the series of new challenges for TSUJIRI CHAHO and I.</div>
          <div>2010 saw this century-old brand set sail and anchor in overseas markets marking the company’s most significant milestone in the company’s 150 years of operations since being founded in 1860.</div>
          <div>Since then, TSUJIRI CHAHO has formed numerous alliances with business partners worldwide, marking a new chapter in our international ventures.</div>
          <div>TSUJIRI CHAHO will continue to grow drawing on the various encounters it has with other cultures and societies.</div>
          <div>I often think deeply about how we can promote Japanese tea in countries and regions where people know nothing of our tea culture and I’ve concluded that communicating our brand’s values and the meaning of Japanese tea and matcha with our partners is the best way to do this, and thus our top priority.</div>
          <div>It is our hope that with the help of our partners, and through interacting with a diverse range of cultures, TSUJIRI CHAHO can bring the spirit of tea drinking to customers all over the world.</div>
          <div>As such, inventing new ways to realize and communicate our beliefs with the customer is both a personal challenge for me and a necessary challenge for TSUJIRI CHAHO.</div>
          <div style="margin-top:40px;">Shotaro Uchida</div>
          <div style="margin-bottom:50px;">CEO, TSUJIRI Global Managemnt</div>
        </el-col>
      </el-row>
    </el-row>
    <el-row type="flex">
      <el-col :xs="1" :sm="3">&nbsp;</el-col>
      <el-col :xs="22" :sm="12" style="text-align:left;" v-frantextscroll="handleScroll" id="frantext">
        <p style="font-size: 35px;font-weight: bold;" >Strength of TSUJIRI</p>
        <p style="font-size: 21px;">Going through multiple charms of Matcha and Japanese tea, TSUJIRI CHAHO continuously creates new topics and actively supports the development of attractive products to always find something fresh for our customers.</p>
      </el-col>
      <el-col :xs="1" :sm="9">&nbsp;</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :xs="1" :sm="3">&nbsp;</el-col>
      <el-col :xs="0" :sm="6" style="text-align:left;"><img id="franimg0" src="../assets/franchise0.jpg" style="width:90%;" v-franchisescroll="handleScroll" /></el-col>
      <el-col :xs="22" :sm="0" ><img id="franimg0" src="../assets/franchise0.jpg" style="width:90%;" v-franchisescroll="handleScroll" /></el-col>
      <el-col :xs="1" :sm="1" >&nbsp;</el-col>
      <el-col :xs="0" :sm="11" style="text-align:left;">
        <p style="font-size: 30px;" class="franimg0">{{franchises[0].first.title}}</p>
        <p style="font-size: 21px;" class="franimg0">{{franchises[0].first.content}}</p>
        <p style="font-size: 30px;" class="franimg0">{{franchises[0].second.title}}</p>
        <p style="font-size: 21px;" class="franimg0">{{franchises[0].second.content}}</p>
      </el-col>
      <el-col :xs="0" :sm="3">&nbsp;</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :xs="1" :sm="0">&nbsp;</el-col>
      <el-col :xs="22" :sm="0" style="text-align:left;">
        <p style="font-size: 30px;" class="franimg0">{{franchises[0].first.title}}</p>
        <p style="font-size: 21px;" class="franimg0">{{franchises[0].first.content}}</p>
        <p style="font-size: 30px;" class="franimg0">{{franchises[0].second.title}}</p>
        <p style="font-size: 21px;" class="franimg0">{{franchises[0].second.content}}</p>
      </el-col>
      <el-col :xs="1" :sm="0">&nbsp;</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :xs="1" :sm="0" >&nbsp;</el-col>
      <el-col :xs="22" :sm="0" ><img id="franimg1" src="../assets/franchise1.jpg" style="margin-top:30px;width:90%;" v-franchisescroll="handleScroll"/></el-col>
      <el-col :xs="1" :sm="0">&nbsp;</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :xs="1" :sm="3">&nbsp;</el-col>
      <el-col :xs="22" :sm="11" style="text-align:left;">
        <p style="font-size: 30px;" class="franimg1">{{franchises[1].first.title}}</p>
        <p style="font-size: 21px;" class="franimg1">{{franchises[1].first.content}}</p>
        <p style="font-size: 30px;" class="franimg1">{{franchises[1].second.title}}</p>
        <p style="font-size: 21px;" class="franimg1">{{franchises[1].second.content}}</p>
      </el-col>
      <el-col :xs="1" :sm="1" >&nbsp;</el-col>
      <el-col :xs="0" :sm="6" ><img id="franimg1" src="../assets/franchise1.jpg" style="margin-top:30px;width:90%;" v-franchisescroll="handleScroll"/></el-col>
      <el-col :xs="0" :sm="3">&nbsp;</el-col>
    </el-row>
    <el-row id="franchiseForm" type="flex" style="margin-top:30px;">
      <el-col :xs="2" :sm="5">&nbsp;</el-col>
      <el-col :xs="20" :sm="14">
        <p style="font-size: 35px;font-weight: bold;margin-bottom:50px;">Join TSUJIRI Franchise Business</p>
        <p class="formCentent">Thank you for your interest in the TSUJIRI brand!</p>
        <p class="formCentent">We are continuously looking for franchise partners from all over the world.</p>
        <p class="formCentent">Submit your information below, and we will contact you if there is a fit for you.</p>
        <el-form :model="sheet" :rules="rules" ref="sheet">
          <el-row type="flex">
            <el-col>
              <el-form-item label="Name" class="formitem" prop="name" id="contact">
                <el-col :xs="24" :sm="0">
                  <el-input style="width:47%" placeholder="First Name" v-model="sheet.name[0]"/>
                  <span style="width:6%;display:inline-block">&nbsp;</span>
                  <el-input style="width:47%" placeholder="Last Name" v-model="sheet.name[1]"/>
                </el-col>
                <el-col :xs="24" :sm="0" style="text-align:left;margin-top:10px;">
                  <span style="font-size:21px;margin-left:10px;margin-right:10px;">Gender</span>
                  <svg xmlns="http://www.w3.org/2000/svg" style="margin:0px 15px -10px 10px;" width="18" viewBox="0 0 21 41" @mouseover="gender('male')" @mouseout="ungender('male')" @click="genderSelect('male')">
                      <g :fill="male" fill-rule="evenodd">
                          <circle cx="10.5" cy="10.5" r="10.5"/>
                          <path d="M11 22L21 41 1 41z" transform="matrix(1 0 0 -1 0 63)"/>
                      </g>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" style="margin:0px 15px -10px 10px;" width="18" viewBox="0 0 21 41" @mouseover="gender('female')" @mouseout="ungender('female')" @click="genderSelect('female')">
                      <g :fill="female" fill-rule="evenodd">
                          <circle cx="10.5" cy="10.5" r="10.5"/>
                          <path d="M11 22L21 41 1 41z"/>
                      </g>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:0px 15px -10px 10px;" width="18" height="35" viewBox="0 0 21 41" @mouseover="gender('third')" @mouseout="ungender('third')" @click="genderSelect('third')">
                      <defs>
                          <circle id="prefix__a" cx="10.5" cy="10.5" r="10.5"/>
                          <path id="prefix__c" d="M0 0H13V19H0z"/>
                      </defs>
                      <g fill="none" fill-rule="evenodd">
                          <mask id="prefix__b" fill="#fff">
                              <use xlink:href="#prefix__a"/>
                          </mask>
                          <use fill="#D8D8D8" xlink:href="#prefix__a"/>
                          <g mask="url(#prefix__b)">
                              <g>
                                  <path :fill="third[0]" d="M0 0H24V7H0z" transform="translate(-1)"/>
                                  <path :fill="third[1]" d="M0 7H24V14H0z" transform="translate(-1)"/>
                                  <path :fill="third[2]" d="M0 14H24V21H0z" transform="translate(-1)"/>
                                  <path :fill="third[3]" d="M0 20H24V27H0z" transform="translate(-1)"/>
                              </g>
                          </g>
                          <g transform="translate(4 22)">
                              <mask id="prefix__d" fill="#fff">
                                  <use xlink:href="#prefix__c"/>
                              </mask>
                              <use fill="#D8D8D8" xlink:href="#prefix__c"/>
                              <g mask="url(#prefix__d)">
                                  <g>
                                      <path :fill="third[4]" d="M0 14H24V21H0z" transform="translate(-5 -2)"/>
                                      <path :fill="third[3]" d="M0 0H24V7H0z" transform="translate(-5 -2)"/>
                                      <path :fill="third[5]" d="M0 7H24V14H0z" transform="translate(-5 -2)"/>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </el-col>
                <el-row type="flex" class="hidden-xs-only">
                  <el-col :xs="0" :sm="1">&nbsp;</el-col>
                  <el-col :xs="0" :sm="8">
                    <el-input placeholder="First Name" v-model="sheet.name[0]"/>
                  </el-col>
                  <el-col :xs="0" :sm="0">
                    <el-input placeholder="First Name" v-model="sheet.name[0]"/>
                  </el-col>
                  <el-col :xs="0" :sm="1">&nbsp;</el-col>
                  <el-col :xs="0" :sm="8">
                    <el-input placeholder="Last Name" v-model="sheet.name[1]"/>
                  </el-col>
                  <el-col :xs="0" :sm="2">
                    <div style="font-size:21px;margin-left:15px;">Gender</div>
                  </el-col>
                  <el-col :xs="0" :sm="4">
                    <svg xmlns="http://www.w3.org/2000/svg" style="margin-left:10px;margin-right:15px;" width="21" viewBox="0 0 21 41" @mouseover="gender('male')" @mouseout="ungender('male')" @click="genderSelect('male')">
                        <g :fill="male" fill-rule="evenodd">
                            <circle cx="10.5" cy="10.5" r="10.5"/>
                            <path d="M11 22L21 41 1 41z" transform="matrix(1 0 0 -1 0 63)"/>
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:15px;" width="21" viewBox="0 0 21 41" @mouseover="gender('female')" @mouseout="ungender('female')" @click="genderSelect('female')">
                        <g :fill="female" fill-rule="evenodd">
                            <circle cx="10.5" cy="10.5" r="10.5"/>
                            <path d="M11 22L21 41 1 41z"/>
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21" height="41" viewBox="0 0 21 41" @mouseover="gender('third')" @mouseout="ungender('third')" @click="genderSelect('third')">
                        <g fill="none" fill-rule="evenodd">
                            <mask id="prefix__B" fill="#fff">
                                <use xlink:href="#prefix__a"/>
                            </mask>
                            <use fill="#D8D8D8" xlink:href="#prefix__a"/>
                            <g mask="url(#prefix__B)">
                                <g>
                                    <path :fill="third[0]" d="M0 0H24V7H0z" transform="translate(-1)"/>
                                    <path :fill="third[1]" d="M0 7H24V14H0z" transform="translate(-1)"/>
                                    <path :fill="third[2]" d="M0 14H24V21H0z" transform="translate(-1)"/>
                                    <path :fill="third[3]" d="M0 20H24V27H0z" transform="translate(-1)"/>
                                </g>
                            </g>
                            <g transform="translate(4 22)">
                                <mask id="prefix__D" fill="#fff">
                                    <use xlink:href="#prefix__c"/>
                                </mask>
                                <use fill="#D8D8D8" xlink:href="#prefix__c"/>
                                <g mask="url(#prefix__D)">
                                    <g>
                                        <path :fill="third[4]" d="M0 14H24V21H0z" transform="translate(-5 -2)"/>
                                        <path :fill="third[3]" d="M0 0H24V7H0z" transform="translate(-5 -2)"/>
                                        <path :fill="third[5]" d="M0 7H24V14H0z" transform="translate(-5 -2)"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                  </el-col>
                  <el-col :xs="0" :sm="1">&nbsp;</el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="Email" class="formitem" prop="email">
                <el-col :sm="24">
                  <el-input v-model="sheet.email"/>
                </el-col>
              </el-form-item>
              <el-form-item label="Phone Number" class="formitem" prop="phone">
                <el-col :xs="24" :sm="24">
                  <el-input v-model="sheet.phone"/>
                </el-col>
              </el-form-item>
              <el-form-item label="Current Occupation" class="formitem" prop="occupation">
                <el-col :sm="24">
                  <el-input v-model="sheet.occupation"/>
                </el-col>
              </el-form-item>
              <el-form-item label="Net Worth" class="formitem">
                <el-col :sm="24">
                  <el-select v-model="sheet.worth" placeholder="Select" style="width:100%">
                    <el-option
                      v-for="worth in worths"
                      :key="worth.value"
                      :label="worth.value"
                      :value="worth.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item label="I'm interested in doing the franchise business in this city/region" class="formitem" prop="region">
                <el-col :sm="24">
                  <el-input v-model="sheet.region"/>
                </el-col>
              </el-form-item>
              <el-form-item label="Liquid assets you are willing to invest in the franchise?" class="formitem" prop="assets">
                <el-col :sm="24">
                  <el-select v-model="sheet.assets" placeholder="Select" style="width:100%">
                    <el-option
                      v-for="asset in assets"
                      :key="asset.value"
                      :label="asset.value"
                      :value="asset.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item style="text-align:left;" label="Please check all that apply" class="formitem">
                <br>
                <el-col :xs="24" :sm="24">
                  <el-checkbox-group v-model="sheet.apply" >
                    <el-checkbox v-for="option in options" :label="option"></el-checkbox>
                  </el-checkbox-group>
                </el-col>
              </el-form-item>
              <el-form-item>
                <el-row type="flex">
                <el-col :xs="6" :sm="9">&nbsp;</el-col>
                <el-col :xs="12" :sm="6">
                  <el-button id="submit" @click="email('sheet')">Submit</el-button>
                </el-col>
                <el-col :xs="6" :sm="8" style="margin-bottom:200px;">&nbsp;</el-col>
                <el-col :xs="0" :sm="1" style="margin-bottom:560px;">&nbsp;</el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <el-col :xs="2" :sm="5">&nbsp;</el-col>
    </el-row>
    <el-footer style="height:143px;padding:0px;">
      <Footer/>
    </el-footer>
  </div>
</template>

<script>
import api from '../axios.js';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import {gsap} from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
export default {
  name: 'Franchise',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      third:['#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8'],
      male:'#D8D8D8',
      female:'#D8D8D8',
      sheet:{
        gender:'',
        name:[],
        email:'',
        phone:'',
        occupation:'',
        worth:'',
        region:'',
        assets:'',
        apply:[],
      },
      rules: {
        name: [
          { type: 'array', required: true, trigger: 'blur' }
        ],
        email: [
          { required: true, trigger: 'blur' }
        ],
        phone: [
          { required: true, trigger: 'blur' }
        ],
        address: [
          { required: true, trigger: 'blur' }
        ],
        occupation: [
          { required: true, trigger: 'blur' }
        ],
        region: [
          { required: true, trigger: 'blur' }
        ],
        assets: [
          { required: true, trigger: 'change' }
        ],
      },
      assets: [{
        value: 'Under $200,000',
      }, {
        value: '$200,000 - $300,000',
      }, {
        value: '$300,000 - $400,000',
      }, {
        value: '$400,000 - $500,000',
      }, {
        value: 'Over $500,000',
      }],
      worths: [{
        value: 'Under $1 million',
      }, {
        value: '$1 million - $1.5 million',
      }, {
        value: '$1.5 million - $2.5 million',
      }, {
        value: '$2.5 million - $5 million',
      }, {
        value: 'Over $5 million',
      }],
      options:['I have restaurant business experience','I currently am (or have previously been) a business owner','I have a current line of credit or access to capital','I am currently a multi-unit franchisee','I am seeking a multi-unit opportunity'],
      franchises:[
        {
          first:{
            title:'Diversified Products',
            content:'With accumulated public praise of actual performance and rich experience for many years, TSUJIRI CHAHO carefully selected the highest grade of Matcha and Japanese tea to provide the top quality goods as our original intention and purpose.',
          },
          second:{
            title:'Products Development',
            content:'Seasonal new products including limited products not released in Japan during the period will be developed according to seasonality and regionality, causing visitors to feel different appearances of TSUJIRI Chaho every time.',
          },
        },{
          first:{
            title:'Quality',
            content:'TSUJIRI CHAHO faithfully presents specific and strongly sweet flavor of Matcha and Japanese tea in its exclusive private menu, so as to taste and experience various products in a wide range.',
          },
          second:{
            title:'Service',
            content:'Based on the spirit of “Ichi-go Ichi-e” in the Japanese tea ceremony, TSUJIRI CHAHO adheres to the highest code of service to be considerate, understanding and wholehearted, expecting customers to feel the best tea-tasting experience.',
          },
        },
      ]
    };
  },
  methods: {
    email(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formContent = this.sheet
          api.email(formContent).then(({
                    data
                }) => {
                  console.log('data',data)
                    if (data.success) {
                        this.$message({
                          message: 'Thanks for contact us, we will reply you as soon as possible',
                          type: 'success',
                          duration: 5000,
                        });
                        this.$refs['sheet'].resetFields();
                        gsap.to(window,{duration: 0.8, scrollTo: 0, ease: "power4"})
                    } else {
                      this.$message.error({
                        message: 'Oops, email fail, please try again',
                        duration: 5000,
                      });
                    }
                }).catch((err) => {
                    console.log(err);
                })
          // alert('submit!');
        } else {
          alert('Please answer above questions!!');
          gsap.to(window,{duration: 0.8, scrollTo: "#contact", ease: "power4"})
          return false;
        }
      });
    },
    gender(x){
      if(this.sheet.gender==''&& x!='third'){
        this[x]='#286040'
      }else if(this.sheet.gender==''&& x=='third'){
        this.third=['#F80000','#FF8400','#FDF500','#008A22','#840089','#3608FF']
      }else{

      }
    },
    ungender(y){
      if(this.sheet.gender==''&& y!='third'){
        this[y]='#D8D8D8'
      }else if(this.sheet.gender==''&& y=='third'){
        this.third=['#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8']
      }else{

      }
    },
    genderSelect(z){
      console.log(z)
      if(this.sheet.gender!=z){
        if(z=='male'){
          this.male='#286040'
          this.female='#D8D8D8'
          this.third=['#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8']
        }else if(z=='female'){
          this.female='#286040'
          this.male='#D8D8D8'
          this.third=['#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8']
        }else{
          this.third=['#F80000','#FF8400','#FDF500','#008A22','#840089','#3608FF']
          this.female='#D8D8D8'
          this.male='#D8D8D8'
        }
        this.sheet.gender=z
      }else if(this.sheet.gender==z&&this.sheet.gender!='third'){
        this.sheet.gender=''
        this[z]='#D8D8D8'
      }else{
        this.sheet.gender=''
        this.third=['#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8','#D8D8D8']
      }
    },
    drawer(){
      this.$store.state.drawer=true
    },
    handleScroll: function (evt, el) {
      function getPosition (element) {
        var y = 0;
        // 搭配上面的示意圖可比較輕鬆理解為何要這麼計算
        while ( element ) {
          y += element.offsetTop - element.scrollLeft + element.clientTop;
          element = element.offsetParent;
        }

        return {y: y};
      }
      var position = getPosition(el);
      return position.y-300
    },
  },
  directives: {
    frantextscroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (window.scrollY>binding.value(evt, el)) {
            var tx = gsap.timeline({repeat: 0, repeatDelay: 0.5});
            tx.to(el, 0.5, {
              y: 0,
              opacity: 1,
              zIndex:10,
            })
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    },
    franchisescroll: {
      inserted: function (el, binding) {
        console.log('fire scroll')
        let f = function (evt) {
          if (window.scrollY>binding.value(evt, el)) {
            const text = document.getElementsByClassName(el.id)
            console.log(el.id)
            var ty = gsap.timeline({repeat: 0, repeatDelay: 0.5});
            ty.to(el, 1, {
              y: 0,
              opacity: 1,
              zIndex:10,
            })
            ty.to(text,1,{opacity:1,x:0},0.3)
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    }
  },
  mounted(){
    window.scroll(0,0)
    var content = document.getElementsByClassName("content");
    gsap.set('#frantext',{opacity:0,y:20})
    gsap.set(content,{opacity:0,y:20})
    gsap.to(content,2,{opacity:1,y:-20})
    gsap.set('#franimg0',{opacity:0})
    gsap.set('.franimg0',{opacity:0,x:-30})
    gsap.set('#franimg1',{opacity:0})
    gsap.set('.franimg1',{opacity:0,x:-30})
  }
};
</script>
<style>
.el-checkbox__inner is-focus{
  border-color: #286040 !important;
}
.el-checkbox__inner:hover{
  border-color: #286040 !important;
}
.el-radio__input.is-checked .el-radio__inner{
  background-color:#286040;
  border-color: #286040;
}
.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color:#286040;
  border-color: #286040;
}
.el-radio__input.is-checked+.el-radio__label{
  color: #286040;
}
.el-checkbox__input.is-checked+.el-checkbox__label{
  color:black;
}
.el-checkbox{
  color:black;
}
.el-checkbox__label{
  font-size:21px;
  word-break: normal;
  display: inline-grid;
  white-space: pre-line;
  word-wrap: break-word;
  overflow: hidden;
}
.el-form-item{
  margin-bottom:8px;
}
.el-form-item__label{
  text-align: left;
  color:black;
  font-size:21px;
}
.el-radio__label{
  font-size:20px;
  color:black;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content{
  text-align:left;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #000000;
}
.formCentent{
  text-align:left;
  font-size: 21px;
}
#submit{
  margin-top:20px;
  color:white;
  font-size:20px;
  width:100%;
  height: 66px;
  border-radius: 8px;
  background-color: #286040;
}
#franchiseForm{
  background-repeat: no-repeat;
  background-image: url('../assets/formbg.jpg');
  background-size: 100%;
  background-position: center bottom;
}
#franchise{
  background-image: url('../assets/franchisebg.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>
