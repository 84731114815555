import axios from 'axios';
//import router from './router/index.js';
//import store from './store/index.js';
// import * as types from './store/types'
axios.default.timeout = 5000;
axios.defaults.headers.post['Content-Type'] = 'application/json'

const instance = axios.create();
instance.defaults.headers.post['Content-Type'] = 'application/json'

export default {
  // 用户注册
  email(data) {
    return instance.post('/api/email',data);
  },
};
