<template>
  <div id="about">
        <el-row id="about0" type="flex">
          <Header/>
          <el-col :xs="2" :sm="4">&nbsp;</el-col>
          <el-col :xs="0" :sm="20" style="margin-bottom:40px;">
            <p class="abouttitle" style="margin-top:86px;">TSUJIRI CHAHO</p>
            <p class="abouttitle">Brand Statement</p>
          </el-col>
          <el-col :xs="20" :sm="0" style="color:white;text-align:left;font-size:24px;margin-bottom:0px;">
            <p style="margin:20px 0px 0px 0px;">TSUJIRI CHAHO</p>
            <p style="margin:0px;">Brand Statement</p>
          </el-col>
          <el-col :xs="2" :sm="0">&nbsp;</el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="2" :sm="4">&nbsp;</el-col>
          <el-col :xs="20" :sm="16">
            <el-col :sm="24" >
              <p class="opa" style="font-size:21px;line-height: 1.43;text-align:left;margin-top:35px;" >{{$t('About0')}}</p>
            </el-col>
            <el-col :xs="24" :sm="8" style="text-align:left;float:right;font-size:18px;" class="opa">
              <div style="float:right;">
                <span style="">{{$t('Shiro Tsuji')}}</span><br>
                <span style="">{{$t('CEO of TSUJIRI CHAHO (Japan) Limited')}}</span>
              </div>
            </el-col>
            <el-col :xs="0" :sm="24" style="margin-top:20px;"><img class="opa" src="../assets/shirosan.svg" style="float:right;" alt=""></el-col>
            <el-col :xs="6" :sm="0" :push="18" style="margin:5px 0px 10px 0px;" ><img class="opa" src="../assets/shirosan.svg" style="float:right;width:100%;" alt=""></el-col>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="0" :sm="1">&nbsp;</el-col>
          <el-col :xs="0" :sm="11" style="text-align:right;"><img id="abimg0" class="aboutimg" v-scroll="handleScroll" src="../assets/about1.webp" style="width:100%;display:block;"/></el-col>
          <el-col :xs="24" :sm="0" style="text-align:right;"><img  v-scroll="handleScroll" src="../assets/about1.webp" style="width:100%;display:block;"/></el-col>
          <el-col :xs="0" :sm="11">
            <div class="aboutdiv" >
              <p class="aboutp white abimg0" style="margin-top:40px;">{{$t('About1')}}</p>
            </div>
          </el-col>
          <el-col :xs="0" :sm="1">&nbsp;</el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="2" :sm="0">&nbsp;</el-col>
          <el-col :xs="20" :sm="0">
            <p style="margin-top:40px;text-align:left;font-size:18px;">{{$t('About1')}}</p>
          </el-col>
          <el-col :xs="2" :sm="0">&nbsp;</el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="0" :sm="1">&nbsp;</el-col>
          <el-col :xs="0" :sm="10" style="align-self: flex-end;background-color:rgba(255,255,255,.5);height:auto;">
              <p class="aboutp white abimg1" style="margin-top:30px;margin-bottom:50px;">{{$t('About2')}}</p>
          </el-col>
          <el-col :xs="24" :sm="12" style="text-align:left;"><img id="abimg1" class="aboutimg" v-scroll="handleScroll" src="../assets/about2.webp" style="width:100%;display:block;border-style:none;"/></el-col>
          <el-col :xs="0" :sm="1">&nbsp;</el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="2" :sm="0">&nbsp;</el-col>
          <el-col :xs="20" :sm="0" style="align-self: flex-end;height:auto;text-align:left;">
              <p style="margin-top:30px;margin-bottom:10px;font-size:18px;">{{$t('About2')}}</p>
          </el-col>
          <el-col :xs="2" :sm="0">&nbsp;</el-col>
        </el-row>
        <el-row>
          <el-col :xs="0" :sm="24" style="color:transparent;">
            <el-image v-scroll="handleScroll" class="aboutimg" id="newtest" :src="require('../assets/about3.webp')" style="width:100%;display:block;" alt=""/>
          </el-col>
          <el-col :xs="24" :sm="0" style="color:transparent;">
            <el-image v-scroll="handleScroll" class="aboutimg" id="newtest" :src="require('../assets/about3.webp')" style="width:115%;display:block;margin:0% 0% 0% -15%" alt=""/>
          </el-col>
        </el-row>
      <el-footer style="height:143px;padding:0px;">
        <Footer/>
      </el-footer>
  </div>
</template>
<script>
//import * as types from '../types'
import api from '../axios.js'
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import {gsap} from 'gsap';

export default {
  name: 'About',
  components: {
    Header,
    Footer,
  },
  data() {
    return {

    };
  },
  methods: {
    handleScroll: function (evt, el) {
      function getPosition (element) {
        var y = 0;
        while ( element ) {
          y += element.offsetTop - element.scrollLeft + element.clientTop;
          element = element.offsetParent;
        }

        return {y: y};
      }
      var position = getPosition(el);
      return position.y-300
    },
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        //console.log('fire scroll')
        let f = function (evt) {
          if (window.scrollY>binding.value(evt, el)) {
            const text=document.getElementsByClassName(el.id)
            var tl = gsap.timeline({repeat: 0, repeatDelay: 0.5});
            tl.to(el, 1, {
              y: 0,
              opacity: 1,
            })
            tl.to(text,1,{y:0,opacity:1},0.5)
            //console.log(el,'fire this')
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    },
  },
  mounted(){
    let obj = document.getElementsByClassName("opa");
    gsap.from(obj,1,{opacity:0.1,y:30})
    let imgs = document.getElementsByClassName("aboutimg");
    gsap.set(imgs,{opacity:0,y:20});
    let white = document.getElementsByClassName("white");
    gsap.set(white,{opacity:0,});
    window.scroll(0,0)
  }
};
</script>
<style>
#el-drawer__title{
  height:0px;
  visibility: hidden;
  padding: 0px;
  margin: 0px;
}
.el-drawer__header{
  height:0px;
  visibility: hidden;
}
</style>
<style scoped>
div{
  margin:0px;
  padding: 0px;
  border: 0px;
}
.abouttitle{
  text-align: left;
  color:white;
  font-size:60px;
  margin-bottom:0px;
  margin-top:0px;
}
#test{
  position:absolute !important;
  bottom:0px;
}
.aboutp{
  text-align: left;
  padding-top: 30px;
  margin: 0px 50px 0px 50px;
  font-size: 21px;
  line-height: 1.43;
}
.aboutdiv{
  position:absolute;
  bottom:0px;
  background-color:rgba(255,255,255,.5);
  height:300px;
  margin-top:413px;
  width:45.833%;
}
#about{
  background-repeat: repeat-y;
  background-image: url('../assets/aboutbg.webp');
  background-size: 100%;
}
#about0{
  background-repeat: no-repeat;
  background-image: url('../assets/about0.webp');
  background-size: 100%;
}

</style>
