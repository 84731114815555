<template>
  <div id="shop">
    <Header/>
    <el-container>
      <el-main style="min-height:500px;">
        <el-row>
          <el-col :xs="0" :sm="24" style="font-size: 48px;color: #286040;">{{$t("Global Shop")}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="0" style="font-size: 28px;color: #286040;">{{$t("Global Shop")}}</el-col>
        </el-row>
        <el-row type="flex" style="margin-bottom:30px;">
          <el-col :xs="0" :sm="3">&nbsp;</el-col>
          <el-col :xs="0" :sm="18">
            <svg id="mapsvg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            	 viewBox="0 0 800 419.5" style="enable-background:new 0 0 800 419.5;" xml:space="preserve">
            <g id="eu" @mouseover="hover('eu',this)" @mouseout="hover('eu')" @click="select('eu')">
            	<path :class="eucolor" class="eu" d="M463.5,157c0-0.2,0.6-0.5,0.6-0.8c0-0.4-0.6-0.4-1.2-0.7c-0.6-0.3-1.2-0.2-1.7-0.4c-0.5-0.3-1.6-0.1-1.6-0.3
            		c0-0.2-0.7-0.8-0.9-1.1c-0.2-0.3-0.8-0.1-1.1,0.1c-0.4,0.2-0.7,0.3-1,0.1c-0.4-0.2-0.7-0.6-1-0.4c-0.3,0.1-0.9,0-0.8-0.4
            		c0.1-0.4-0.6-1.4-0.9-1.6c-0.3-0.1-1.2,0.1-1.5-0.4c-0.2-0.4-0.6-0.8-0.4-1.1c0.2-0.3,0-1.2-0.3-1.5c-0.2-0.4-1.3-0.3-1.6-0.2
            		c-0.3,0.1-1-0.1-1.3,0c-0.3,0.2-0.5,0.5-0.9,0.5c-0.2,0-0.4,0-0.5-0.2c-0.1-0.2-0.2-0.4-0.3-0.8c-0.2-1-0.6-1.6-0.3-1.9
            		c0.3-0.3,1,0.4,1.4,0.2c0.4-0.2,1.1-0.5,1-0.9c-0.1-0.4-0.8-0.9-1.1-1c-0.4-0.1-0.6-0.9-1.2-1.1c-0.6-0.2-0.4-0.9-0.9-1.2
            		c-0.5-0.3-0.2-0.9-0.2-1.4c0-0.5,0.1-1.2-0.2-1.4c-0.3-0.1-1.4-0.8-1.9-0.5c-0.5,0.2-0.8,0.4-1.1,0c-0.2-0.4-0.5-0.6-1-0.5
            		c-0.5,0.1-0.8-0.1-1.1-0.3c0,0-0.1-0.1-0.1-0.1c-0.2-0.3-0.2-1-0.4-1.2c-0.3-0.3-0.8-0.8-0.6-1.2c0.1-0.3,0.1-0.8-0.2-1.1
            		c-0.2-0.2-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4,0.1-0.6c0.3-0.2,0.8-0.2,0.5-0.7c-0.3-0.4-0.4-0.5-0.4-1.1c0-0.5-0.2-0.8-0.1-1.2
            		c0-0.4,0.4-0.4,0.7-1c0.2-0.5,0.9-0.6,0.4-1.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.9,0.2-2.7-0.6-3.8-0.4c-1.1,0.2-3.2,0.2-3.7,0.7
            		c-0.5,0.5-1.8,0.7-1.2,1.2c0.6,0.5,0,0.7,0.4,1.3c0.4,0.6,0.6,0.8,1.2,0.7c0.6-0.1,1,0.2,0.4,0.8c0,0,0,0,0,0
            		c-0.5,0.6-0.2,1.1-0.2,2c0,1-1.1,0.8-1.6,0.8c-0.6,0-1.5-1.7-1.8-2.1c-0.3-0.4-1.3,0.5-1.9,0.9c-0.7,0.4-0.3,1.2-0.8,1.6
            		c-0.5,0.4-0.8,1-0.3,1.9c0,0,0,0.1,0.1,0.1c0.4,0.8,0.3,1.7-0.2,2.2c-0.3,0.4-0.1,0.4,0.1,0.6c0.1,0.1,0.2,0.2,0.3,0.3
            		c0.3,0.5-1.1,0.5-0.7,1.1c0.4,0.6-1,0.6-1,0c0-0.6-1.4-0.3-1.4,0.1c0,0.1,0,0.3-0.1,0.5c-0.2,0.3-0.5,0.6-1.1,0.6
            		c-0.9,0-0.9-0.9-1-1.2c0-0.4-3.5,0.3-4.4,1.2c-0.9,0.9-3.1,0.6-3.1,1.1c0,0.4-0.4,0.6-0.6,0.5c-0.1,0-0.1-0.1-0.2-0.2
            		c-0.1-0.5-0.4-0.7-1.2-0.7c-0.8,0,0.5-0.8,0.2-1.2c-0.3-0.4-1.1,0.7-1.8,0.5c-0.7-0.2-1.6,0.8-2.3,1.3c-0.6,0.5-1.5-0.1-1-0.8
            		c0.5-0.7,0-0.6-0.7-0.4c-0.7,0.3-1.6-0.1-1.5-0.7c0-0.2,0-0.3-0.1-0.5c-0.3-0.4-0.8-0.8-0.8-1.1c0.1-0.4,0.6-0.2,1,0.2
            		c0.4,0.4,1.3,0.4,1.4,0c0.1-0.3-0.1-1.5-0.7-1.2c-0.6,0.3-0.7,0.2-0.9-0.1c-0.2-0.3,0.4-0.8,0.6-1.2c0.1-0.5,1.1-0.3,1.3-0.7
            		c0.1-0.4-0.7-0.8-1-1c-0.3-0.1-0.4-1.1-0.1-1.4c0.4-0.3,0.5-1.4,0.2-1.7c-0.3-0.3-1.4,0-1.5,0.8c-0.1,0.8-1,0.4-1.2,0.6
            		c-0.2,0.2,0.5,0.5,0.1,0.9c-0.4,0.4-0.5-0.5-1.2-0.6c-0.6-0.1-0.7,0.9-1,1.5c-0.3,0.5-0.1,1.6,0,2.3c0.1,0.7,1,1.2,0.8,1.8
            		c-0.1,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.7,0.3,1c-0.3,0.5,0.1,1,0.5,1.7c0.4,0.7-0.8,0.3-0.9,0.8c0,0.5-1.1,0-1.5-0.2
            		c-0.4-0.2-1.5-0.1-1.5,0.2c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.4-0.5,0.2c-0.4-0.3-1.1-0.1-2,0.1c-0.9,0.2-1.2,1-0.8,1.4
            		c0.3,0.4-0.9,0.5-0.9,0.1c-0.1-0.5-1-0.1-0.9,0.5c0.1,0.6-1,0.9-0.9,1.6c0.1,0.8-1,1.5-1.6,1.9c-0.1,0.1-0.2,0.1-0.3,0.2
            		c-0.4,0.2-0.9,0.5-1.3,0.6c-0.2,0-0.3,0-0.5,0c-0.7,0-1.3,0.5-1.2,1.7c0.1,1.2-0.9,1.3-2.1,1.4c-1.2,0.1-0.8,1-1.3,1.4
            		c-0.5,0.4-2.3-0.2-2.5-0.9c-0.2-0.7-1.8,0-1.2,0.8c0.7,0.8,0.7,1.8,0.3,2.1c-0.4,0.3-1.2-0.5-1.8-0.1c-0.5,0.4-0.6-0.4-1.2-0.6
            		c-0.7-0.2-0.9,0.3-2,0.2c-1-0.1-1.6,0.5-1.2,1c0.4,0.4-0.1,0.9,0.2,1.2c0.3,0.4,1.7,0.3,2.7,0.9c1,0.5,1.2,0.1,1.8,0.5
            		c0.5,0.4,0.6,0.1,0.7,0.9c0,0.7,0.6,1.5,1.6,1.7c1,0.3,0.2,0.9,0.5,1.6c0.3,0.7-0.4,1.6-0.3,2.4c0.1,0.8-0.5,3.4-1,3.6
            		c0,0-0.1,0-0.1,0c-0.6,0.1-2.3-0.5-3.5-0.4c-1.2,0.1-5.1-0.2-6.6-0.1c-1.5,0.1-1.6-0.6-2.4-0.6c-0.8,0-0.5,1-1.7,1
            		c-1.2,0-1.3,0.8-0.4,1.5c0.5,0.4,0.4,1.2,0.4,2c-0.1,0.6-0.1,1.1,0.1,1.4c0.4,0.6-0.5,3.9-1.2,4.8c-0.8,0.9,0,1.1,0.8,1.8
            		c0.8,0.6,0.3,2.9,0.3,3.4c0,0.5,0.7,0.4,1.5,0.4c0.5,0,1.1-0.2,1.6-0.3c0.4-0.1,0.7-0.1,1.1,0.1c0.8,0.4,1.3,2.5,2.4,2.3
            		c0.5-0.1,0.2-0.4,0.5-0.7c0.2-0.2,0.7-0.1,1.2-0.1c0.5,0,0.7-0.7,1.8-0.7c1.1,0,1.8-0.1,2.6-0.1c0.7,0,1.3-0.2,1.5-0.7
            		c0.2-0.5,0.8-1,1.6-1.1c0.8-0.1,0.7-1,0.7-1.4c0.1-0.5,1.4-1.2,1.5-1.5c0.1-0.4-0.8-1.7,0-2.9c0.8-1.2,1.7-1.3,1.7-2
            		c0-0.6,0.8-0.9,1.8-1c1-0.1,1.3-0.8,2.2-1.2c0.7-0.3,1-0.8,0.8-1.7c0-0.2-0.1-0.3-0.1-0.5c-0.4-1.2,0.7-1.8,1.7-2
            		c1-0.1,3.1,0.4,3.7,0.8c0.6,0.4,1.3,0.3,2.3-0.8c0.5-0.5,0.9-0.7,1.3-0.8c0.4-0.1,0.7-0.1,1-0.3c0.6-0.3,0.4-0.9,1.4-1.2
            		c1-0.3,2.4,0.6,2.8,1.2c0.4,0.6,0.5,0.7,0.6,1.2c0.1,0.5,0.5,1.5,1.9,2.4c1.4,0.9,2,2,2.7,2.3c0.7,0.4,1.5,0.3,1.9,0.8
            		c0.4,0.5,1,0.8,1.8,1.2c0.7,0.3,0.7,1.1,1.4,1.2c0.7,0.1,0.2,0.7,0.7,1.2c0.4,0.5,0.5,1.6,0.1,2.2c-0.4,0.6-0.2,1.6,0.1,1.6
            		c0.3,0,0.9-1.1,1-1.7c0.1-0.6,0.7-0.6,1.2-1.2c0.5-0.6-0.7-0.8-0.9-1.6c-0.2-0.8,0.9-1.5,1.9-0.9c1,0.7,1.5,1.1,1.5,0.1
            		c0.1-1-3.2-2.3-4-2.8c-0.8-0.5-0.3-1.2-1.5-1.2c-1.2,0.1-2.7-1.5-3.1-3.2c-0.4-1.8-2.3-1.8-2.8-2.9c-0.5-1,0.4-1.2,0-2
            		c-0.2-0.5,0.3-0.9,0.9-1.1c0.5-0.2,1-0.3,1.3-0.2c0.2,0.1,0.3,0.3,0.4,0.6c0.1,0.4,0,0.9,0.4,0.9c0.5,0,1.5-0.1,1.5,0.7
            		c0,0.7,1.3,2.3,1.8,2.8c0.5,0.5,1.5,0.4,1.6,0.9c0.1,0.4,0.8,0.9,1.7,0.9c0.2,0,0.5,0.2,0.8,0.4c0.3,0.2,0.7,0.5,1,0.8
            		c0.5,0.4,1,0.9,1.4,1.1c0,0,0.1,0,0.1,0.1c0.8,0.4,1.1,0.7,0.5,1.3c-0.6,0.6-0.7,2.5-0.1,3c0.6,0.5,0.8,1.4,1.3,1.7
            		c0,0,0.1,0,0.1,0c0.7,0.2,1.2,1,1.1,1.7c-0.1,0.7,1,1,1.4,0.5c0.5-0.5,1.8,0.3,1.8,0.7c0,0.4-1.4-0.4-2.1,0.2
            		c-0.8,0.6,0.6,1.1,0.4,1.7c-0.2,0.6,0.4,1.4,1,1.4c0.7,0,1.5,1.1,1.9,0.9c0.4-0.2-0.5-1.8-0.4-2c0.1-0.2,1,0.4,1.4-0.1
            		c0.4-0.5-0.3-0.8-0.9-0.8c-0.6,0,0-0.8,0.7-0.4c0.7,0.4,1.1-0.1,1.5-0.2c0.4-0.1,0.4-1.3-0.7-1.6c-1.1-0.3-1,0.8-1.7,0.1
            		c-0.7-0.7,0.7-0.4,0.7-1.1c0-0.6-1.1-1.5-1.6-2.3c-0.5-0.7,0.7-1.1,1-0.6c0.3,0.5,1.1,0.4,1.6,0.2c0.5-0.2-0.5-1.2,0.4-1.5
            		c0.9-0.4,0.9,0.3,1.2,0.4c0.3,0.1,0.6-0.7,1.5-0.7c0.5,0,0.9,0.3,1.4,0.6c0.3-0.3,0.5-0.4,0.5-0.7c0-0.3,0.6-0.4,0.6-0.9
            		c0-0.2-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.5,0-0.5c0.2-0.1,1.5-0.3,2.1,0c0.3,0.1,0.6,0,0.8-0.2c-0.1-0.2-0.1-0.4-0.3-0.5
            		c-0.3-0.1-0.8-1.4-0.4-1.5c0.4-0.1,1-1.5,1.6-1.7c0.3-0.1,0.4-0.2,0.4-0.4c0.1-0.3,0-0.7,0-1.1c0-0.6,0.4-1.6,1-1.6
            		c0.6-0.1,1.1-0.1,1-1.1c0,0,0-0.1,0-0.1c-0.1-0.9,0.1-0.9,1-1.5c0.9-0.7,1-1.9,1.7-2c0.8-0.1,1.1-0.5,2.2-0.3
            		c1.1,0.2-0.6,0.9,0.2,1.3c0.8,0.4,3.2-0.1,3.3,0.5c0.1,0.6-2.7,1.2-2.7,1.5c0.1,0.3,1.5,0.6,2,0.9c0.5,0.3-0.1,1.2,0.1,1.5
            		c0.2,0.3,1,0.1,1.6-0.4c0.6-0.4,1.1-0.3,1.9-0.8c0.8-0.5,2.5-0.1,2.7-0.4c0.2-0.3-0.5-0.7-2-0.5c-1.5,0.1-1.6-1.3-1.6-1.6
            		c0-0.3,1.6-1.5,2.5-1.6c0.9-0.1,1.7-0.4,2.6-0.8c0.4-0.2,1-0.3,1.6-0.5c-0.1-0.4-0.2-0.9,0-1c0.3,0,1.2-1.1,1.6-1.1s1.6,0.3,1.6,0
            		c0-0.2,0.4-1,0.4-1.2c-0.1-0.2-0.5-0.5-0.4-0.8c0.1-0.2,0.7-0.2,0.7-0.5C464,157.3,463.5,157.1,463.5,157z M426.1,177.6
            		C426.1,177.6,426.1,177.6,426.1,177.6C426.1,177.6,426.1,177.6,426.1,177.6C426.1,177.6,426.1,177.6,426.1,177.6z M425.1,174.3
            		c-0.1-0.1-0.3-0.2-0.4-0.3c0.3,0.2,0.5,0.4,0.5,0.4S425.2,174.4,425.1,174.3z M428.7,174c0,0.1,0,0.1,0,0.2
            		C428.7,174.1,428.7,174.1,428.7,174C428.7,174,428.7,174,428.7,174C428.7,174,428.7,174,428.7,174z M437.1,139.3
            		C437.1,139.3,437.1,139.3,437.1,139.3C437.1,139.3,437.1,139.3,437.1,139.3z M431.1,150.8C431.1,150.8,431.1,150.7,431.1,150.8
            		C431.1,150.7,431.1,150.8,431.1,150.8z M431,150.6c0,0,0-0.1,0-0.1C431,150.5,431,150.6,431,150.6z M431,150.4c0-0.1,0-0.1,0-0.2
            		C431,150.3,431,150.4,431,150.4z M431,149.1c0,0.1,0,0.1,0,0.2C431,149.3,431,149.2,431,149.1z M431,149.4c0,0.1,0,0.2,0,0.2
            		C430.9,149.5,430.9,149.4,431,149.4z M430.9,149.6c0,0.1,0,0.2,0,0.3C430.9,149.8,430.9,149.7,430.9,149.6z M431,150.2
            		c0-0.1,0-0.2,0-0.3C430.9,150,431,150.1,431,150.2z M429.5,159.8c0-0.1,0-0.1,0-0.2C429.6,159.7,429.6,159.7,429.5,159.8z
            		 M428.8,169.7c0,0,0.1-0.1,0.1-0.1C428.9,169.6,428.8,169.6,428.8,169.7z M428.9,169C428.9,168.9,428.9,168.9,428.9,169
            		C428.9,168.9,428.9,168.9,428.9,169z M428.8,168.8c0-0.1,0-0.1,0-0.2C428.8,168.7,428.8,168.8,428.8,168.8z M428.3,158.3
            		c0,0.1,0,0.2,0.1,0.3C428.4,158.5,428.3,158.4,428.3,158.3z M428.4,158.7c0,0.1,0.1,0.2,0.2,0.2
            		C428.5,158.8,428.5,158.7,428.4,158.7z M428.7,159c0.1,0.1,0.1,0.1,0.2,0.2C428.8,159.1,428.8,159.1,428.7,159
            		C428.7,159,428.7,159,428.7,159z M429.2,159.3c-0.1,0-0.2-0.1-0.3-0.1C429,159.2,429.1,159.3,429.2,159.3c0.1,0,0.2,0.1,0.2,0.1
            		C429.4,159.4,429.3,159.4,429.2,159.3z M429.3,156.8c0,0,0-0.1,0-0.1C429.3,156.7,429.3,156.8,429.3,156.8z M429.3,156.7
            		c0-0.3-0.1-0.8-0.1-1C429.2,155.9,429.3,156.4,429.3,156.7z M429.2,155.5c0,0,0,0.1,0,0.1C429.2,155.6,429.2,155.5,429.2,155.5z
            		 M425.7,164.2c0,0-0.1,0-0.1,0.1C425.7,164.3,425.7,164.3,425.7,164.2z M425.3,164.5c0,0-0.1,0-0.1,0.1
            		C425.2,164.6,425.2,164.6,425.3,164.5z M425,164.7C425,164.7,425,164.7,425,164.7C425,164.7,424.9,164.8,425,164.7
            		C424.9,164.8,425,164.8,425,164.7z M428.7,173.4c0-0.1,0-0.2,0-0.3C428.7,173.2,428.7,173.3,428.7,173.4z M428.9,169.5
            		C428.9,169.5,428.9,169.5,428.9,169.5C428.9,169.5,428.9,169.5,428.9,169.5C428.9,169.5,428.9,169.5,428.9,169.5z M429.4,160
            		c0.1-0.1,0.1-0.1,0.2-0.2C429.5,159.9,429.4,159.9,429.4,160z M429.6,159.6C429.5,159.6,429.5,159.5,429.6,159.6
            		C429.5,159.5,429.5,159.5,429.6,159.6C429.5,159.6,429.5,159.6,429.6,159.6z M437.9,158.7c-0.1,0-0.3,0.1-0.4,0.1
            		C437.7,158.7,437.8,158.7,437.9,158.7z M439,158.4c-0.1,0-0.1,0-0.2,0C438.8,158.4,438.9,158.4,439,158.4z M438.7,158.5
            		c-0.1,0-0.2,0-0.3,0.1C438.5,158.5,438.6,158.5,438.7,158.5z M438.3,158.6c-0.1,0-0.2,0.1-0.3,0.1
            		C438.1,158.6,438.2,158.6,438.3,158.6z M437.4,138.8c0.1-0.1,0.1-0.2,0.2-0.2C437.5,138.6,437.5,138.7,437.4,138.8z M431.1,143.8
            		c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0C431.1,143.7,431.1,143.8,431.1,143.8z M431,143.4C431,143.4,431,143.5,431,143.4
            		C431,143.5,431,143.4,431,143.4z M430.9,143.3C430.9,143.3,430.8,143.3,430.9,143.3C430.8,143.3,430.9,143.3,430.9,143.3z
            		 M430.5,143.1c0,0,0.1,0,0.1,0.1C430.6,143.1,430.6,143.1,430.5,143.1z M430.3,142.9c0,0,0.1,0,0.1,0.1
            		C430.4,143,430.3,142.9,430.3,142.9z M429.7,142.7c0,0,0.1,0,0.1,0C429.8,142.8,429.8,142.7,429.7,142.7z M430,142.8
            		c0,0,0.1,0,0.1,0.1C430.1,142.8,430,142.8,430,142.8z M429.5,142.7C429.5,142.7,429.5,142.7,429.5,142.7
            		C429.5,142.7,429.5,142.7,429.5,142.7c0,0,0.1,0,0.1,0C429.6,142.7,429.6,142.7,429.5,142.7z M429.5,141.5c0-0.1,0-0.2,0-0.3
            		C429.5,141.3,429.5,141.4,429.5,141.5z M418.4,159.2c0,0.1,0.1,0.1,0.1,0.2C418.5,159.3,418.5,159.3,418.4,159.2z M418.1,158.4
            		C418.1,158.4,418.1,158.5,418.1,158.4C418.1,158.5,418.1,158.4,418.1,158.4z M418.3,159C418.3,159,418.3,159,418.3,159
            		C418.3,159,418.3,159,418.3,159z M417.1,163.5C417.1,163.5,417.1,163.5,417.1,163.5c-0.1,0.1-0.1,0.1-0.1,0.1
            		C417,163.5,417.1,163.5,417.1,163.5z M416.9,163.6c0,0-0.1,0.1-0.2,0.1C416.8,163.7,416.9,163.6,416.9,163.6z M417.9,157.9
            		C417.9,157.9,417.9,157.9,417.9,157.9C417.9,157.9,417.9,157.9,417.9,157.9z M412.4,158C412.4,158,412.4,158,412.4,158
            		C412.4,158,412.4,158,412.4,158z M414.5,151.7c0,0,0-0.1,0-0.1C414.5,151.6,414.5,151.6,414.5,151.7z M411.6,158.6
            		C411.6,158.6,411.5,158.6,411.6,158.6C411.5,158.6,411.6,158.6,411.6,158.6z M411.4,158.7C411.4,158.7,411.4,158.7,411.4,158.7
            		C411.4,158.7,411.4,158.7,411.4,158.7z M409.8,163.2C409.8,163.2,409.8,163.2,409.8,163.2C409.8,163.2,409.8,163.2,409.8,163.2z
            		 M416.6,163.9c0,0-0.1,0.1-0.1,0.1C416.5,164,416.5,163.9,416.6,163.9z M416.2,164.2C416.1,164.2,416.1,164.2,416.2,164.2
            		C416.1,164.2,416.1,164.2,416.2,164.2z M422.6,167.7c0,0,0,0.1,0,0.2C422.7,167.8,422.6,167.7,422.6,167.7z M422.7,167.9
            		c0,0.1,0,0.1,0,0.2C422.7,168,422.7,168,422.7,167.9z M422.3,170.9C422.3,170.9,422.3,170.9,422.3,170.9
            		C422.3,170.9,422.3,170.9,422.3,170.9z M422.1,171.1c0,0-0.1,0.1-0.1,0.1C422.1,171.1,422.1,171.1,422.1,171.1z M421.9,171.3
            		c0,0-0.1,0.1-0.1,0.2C421.8,171.4,421.8,171.4,421.9,171.3z M422.5,170.8C422.5,170.8,422.5,170.8,422.5,170.8
            		C422.5,170.8,422.5,170.8,422.5,170.8z M422.8,169.3c0-0.3,0-0.6,0-0.8C422.8,168.8,422.8,169.1,422.8,169.3z M422.6,170.7
            		c0,0,0-0.1,0.1-0.2C422.6,170.6,422.6,170.7,422.6,170.7z M422.6,170.5c0-0.1,0-0.2,0.1-0.3C422.7,170.3,422.7,170.4,422.6,170.5z
            		 M422.7,170.2c0-0.1,0-0.2,0-0.4C422.7,170,422.7,170.1,422.7,170.2z M422.7,169.8c0-0.1,0-0.3,0-0.4
            		C422.8,169.5,422.8,169.6,422.7,169.8z M422.7,168.1c0,0.1,0,0.2,0,0.4C422.7,168.4,422.7,168.2,422.7,168.1z M424.5,173.9
            		C424.4,173.9,424.4,173.8,424.5,173.9C424.4,173.8,424.4,173.9,424.5,173.9z M424.2,173.7C424.1,173.7,424.1,173.7,424.2,173.7
            		C424.1,173.7,424.1,173.7,424.2,173.7z M423.9,173.5C423.8,173.5,423.8,173.5,423.9,173.5C423.8,173.5,423.8,173.5,423.9,173.5z
            		 M423.6,173.4C423.6,173.4,423.6,173.4,423.6,173.4C423.6,173.4,423.6,173.4,423.6,173.4z M428.7,173.7
            		C428.7,173.8,428.7,173.8,428.7,173.7C428.7,173.8,428.7,173.8,428.7,173.7C428.7,173.8,428.7,173.7,428.7,173.7z M436.8,159.1
            		c0,0-0.1,0-0.1,0.1C436.7,159.1,436.8,159.1,436.8,159.1z M437.5,158.8c-0.1,0-0.2,0.1-0.3,0.1
            		C437.3,158.9,437.4,158.8,437.5,158.8z M437.1,158.9c-0.1,0-0.1,0.1-0.2,0.1C437,159,437,159,437.1,158.9z M441.6,164.5
            		C441.6,164.6,441.5,164.6,441.6,164.5C441.5,164.6,441.6,164.6,441.6,164.5z M441.5,164.8C441.4,164.8,441.4,164.8,441.5,164.8
            		C441.4,164.8,441.4,164.8,441.5,164.8z M441.3,165C441.3,165.1,441.3,165.1,441.3,165C441.3,165.1,441.3,165.1,441.3,165z
            		 M441.1,165.3C441.1,165.3,441.1,165.4,441.1,165.3C441.1,165.4,441.1,165.3,441.1,165.3z M440.9,165.6
            		C440.9,165.6,440.9,165.6,440.9,165.6C440.9,165.6,440.9,165.6,440.9,165.6z M421.6,155.7C421.6,155.7,421.6,155.7,421.6,155.7
            		c0.1,0.1,0.2,0.2,0.3,0.2C421.8,155.9,421.7,155.8,421.6,155.7z M421.5,155.5c0-0.1-0.1-0.1-0.1-0.2
            		C421.4,155.4,421.5,155.5,421.5,155.5z M421.2,155C421.2,155,421.2,155,421.2,155C421.2,155,421.2,155,421.2,155z M414.5,151.4
            		c0-0.1,0-0.1,0-0.2C414.5,151.3,414.5,151.4,414.5,151.4z M414.5,151.1c0-0.1-0.1-0.2-0.1-0.2C414.4,151,414.5,151.1,414.5,151.1z
            		 M404,161.1C404,161.1,404,161.1,404,161.1C404,161.1,404,161.1,404,161.1z M403.9,162.1c0,0,0-0.1,0-0.1
            		C403.9,162,403.9,162.1,403.9,162.1z M405.3,162.6c0,0-0.1,0-0.1,0C405.2,162.6,405.3,162.6,405.3,162.6z M396.8,154.3
            		c0,0.1,0,0.1,0,0.2C396.8,154.4,396.8,154.3,396.8,154.3z M396.8,154.2c0-0.1,0.1-0.1,0.1-0.2C396.9,154.1,396.8,154.2,396.8,154.2
            		C396.8,154.3,396.8,154.3,396.8,154.2z M397.7,156.2C397.7,156.2,397.7,156.2,397.7,156.2C397.7,156.2,397.7,156.2,397.7,156.2z
            		 M397,153.9C397.1,153.9,397.1,153.9,397,153.9C397.1,153.9,397.1,153.9,397,153.9z M396.7,152.2c0-0.1,0-0.3,0-0.5
            		C396.7,151.9,396.7,152.1,396.7,152.2z M396.7,151.6c0-0.1,0-0.2,0-0.3C396.8,151.4,396.8,151.5,396.7,151.6z M396.8,151.2
            		c0-0.1,0.1-0.3,0.1-0.4C396.9,150.9,396.8,151,396.8,151.2z M396.1,156C396.1,156,396.1,156,396.1,156
            		C396.1,156,396.1,156,396.1,156C396.1,156,396.1,156,396.1,156z M396.4,156C396.4,156,396.4,156,396.4,156
            		C396.4,156,396.4,156,396.4,156z M396.8,156C396.8,156,396.8,156,396.8,156C396.8,156,396.8,156,396.8,156z M398.8,166.3
            		c-0.2-0.1-0.4-0.4-0.2-1c0,0,0,0,0,0C398.4,165.9,398.6,166.2,398.8,166.3z M399.5,161.1C399.6,161.1,399.6,161.1,399.5,161.1
            		C399.6,161.1,399.6,161.1,399.5,161.1z M399.7,160.9C399.7,160.9,399.7,160.9,399.7,160.9C399.7,160.9,399.7,160.9,399.7,160.9
            		C399.7,160.9,399.7,160.9,399.7,160.9z M399.8,159.6c0,0.1,0,0.2,0,0.3C399.8,159.8,399.8,159.7,399.8,159.6z M399.8,160.1
            		c0,0,0,0.1,0,0.1C399.8,160.1,399.8,160.1,399.8,160.1z M405.5,162.6c0,0,0.1,0,0.1,0C405.6,162.6,405.5,162.6,405.5,162.6z
            		 M405.9,162.8c0,0-0.1,0-0.1-0.1c0.1,0.1,0.3,0.1,0.4,0.2C406.1,162.8,406,162.8,405.9,162.8z M409.6,163.2
            		C409.6,163.2,409.6,163.2,409.6,163.2C409.6,163.2,409.6,163.2,409.6,163.2z M421,172.8c0,0.1-0.1,0.2,0,0.2
            		C420.9,173,421,172.9,421,172.8z M421.7,171.6c-0.1,0.1-0.1,0.2-0.2,0.3C421.5,171.8,421.6,171.7,421.7,171.6z M421.4,172
            		c-0.1,0.1-0.1,0.2-0.1,0.2C421.3,172.2,421.3,172.1,421.4,172z M421.1,172.5c0,0.1-0.1,0.2-0.1,0.2
            		C421.1,172.6,421.1,172.5,421.1,172.5z M422.5,174.3C422.5,174.3,422.5,174.3,422.5,174.3C422.5,174.3,422.5,174.3,422.5,174.3z
            		 M422.8,173.8C422.8,173.8,422.8,173.8,422.8,173.8C422.8,173.8,422.8,173.8,422.8,173.8z M426.7,177.6c0.1,0,0.1,0,0.2,0
            		C426.8,177.6,426.7,177.6,426.7,177.6z"/>
            	<path :class="eucolor" d="M403,176.2c0.9-0.1,0.7-3.7,0.4-3.7c-0.3,0-0.4,0.6-1.2,1.1C401.3,174,402.2,176.3,403,176.2z"/>
            	<path :class="eucolor" d="M389.4,181c0.9,0.6,1.9,0.4,1.8-0.6C391,179.4,388.9,180.7,389.4,181z"/>
            	<path :class="eucolor" d="M435.1,190.4c-0.5-0.3-2.6,0.2-3.2-0.4c-0.6-0.5-1.7,0.4-1.2,0.5c0.7,0.1,1.8,0.7,2.9,0.8
            		c1.2,0,2.7-0.5,2.7-0.8C436.4,190.2,435.6,190.7,435.1,190.4z"/>
            	<path :class="eucolor" d="M452.5,189.6c-0.4,0.4-1.5,0.8-2.9,0.9c-1.3,0.1-1.8,0.9-0.9,1.4c1.1,0.6,2.8-0.3,2.7-0.6
            		c-0.1-0.3,0.4-1,1.1-1.3C453.2,189.7,452.9,189.2,452.5,189.6z"/>
            	<path :class="eucolor" d="M355.7,108.6c-0.7,0-1.2-0.6-1.1-1c0.1-0.4-0.4-1-0.4-1.2c0-0.3-0.9,0-1.4,0.1c-0.5,0.1-1.5-1.2-1.9-0.9
            		c-0.4,0.2-0.1,0.9-0.2,1.2c-0.1,0.4-1.2-0.3-1.2,0.1c0,0.4-0.4,0.5-0.8,0c-0.4-0.4-1.2-0.2-1.2,0.2c0,0.4-0.4,0.1-1-0.4
            		c-0.7-0.4-1.4,0.5-1.3,0.9c0.1,0.4-0.2,0.6-0.8-0.4c-0.6-1-1.2,0.1-0.9,0.6c0.3,0.5,0.2,1.2-0.3,0.9c-0.4-0.3-1,0.2-1.3,0.6
            		c-0.3,0.4-1.2-1.2-0.5-1.6c0.7-0.4-2.2-2-3.1-2c-0.9,0-0.3,0.7,0.3,1.1c0.6,0.5-0.4,0.4-0.7-0.1c-0.3-0.4-1-0.1-1.3,0.5
            		c-0.4,0.6-1,0.9-1,1.3c-0.1,0.4,1,0.9,1.9,0.5c0.9-0.4,2-0.4,2.4,0c0.5,0.4-0.9,0.6-0.4,1c0.5,0.4,0.1,0.7-1,0.6
            		c-1.1-0.1-2.5,0.2-2.3,0.6c0.2,0.4,2.9-0.3,2.9,0.1c0,0.4,0,1,0.7,0.7c0.7-0.3,0.2,0.5,0.6,0.7c0.4,0.1,0.3,0.9-0.7,0.9
            		c-1,0.1-1.4,0.2-1,0.7c0.5,0.5,1.5,0.1,2.6-0.1c1-0.2,2.5,1,3.7,1.5c1.2,0.5,3.2-0.2,3.5-0.8c0.2-0.6,1.8-0.4,2.5-0.8
            		c0.7-0.4,1.5-1,2.7-1c1.2-0.1,1.1-1.3,1.6-1.3c0.5,0.1,1.2-0.9,1.5-1.5C357.1,109.6,356.5,108.6,355.7,108.6z"/>
            	<path :class="eucolor" d="M368.9,149.1c1-1,1.5,0,2.5-0.5c0.9-0.5,0.4-4,0-4.7c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.4,1.1-0.5,1.6-1
            		c0.6-0.6-0.3-1.5-1.1-2.3c-0.8-0.7-1.7,0.4-2.5,0.4c-0.1,0-0.2,0-0.2,0c-0.8-0.3-2-0.3-2,0.4c0,0.7,0.9,0.5,1,1
            		c0,0.5-1,0.4-1.6,0.8c-0.7,0.5-1.4-0.3-2.1,0c-0.7,0.4,0.8,0.8,0.2,1.5c-0.5,0.7,0,0.7,0.9,1.4c0.9,0.7-0.5,0.9-0.5,1.7
            		c0,0.8-1.1,0.8-1.3,1.3c-0.2,0.5,1,1.6,2,1.6C366.2,150.6,367.9,150.1,368.9,149.1z"/>
            	<path :class="eucolor" d="M381.7,126.4c0.5-0.5,1.1-2.4,0.3-2.2C381.2,124.4,381.4,126.7,381.7,126.4z"/>
            	<path :class="eucolor" d="M370.2,132.9c0.8,0,1.7-1.4,1.3-1.8C371.1,130.7,369.6,132.9,370.2,132.9z"/>
            	<path :class="eucolor" d="M371,133.8c-0.6,0.5,0.7,1.1,1.5,1.2c0.8,0.1-0.6,0.7-0.7,1.5c-0.1,0.8,1,0.5,1.1,1.1c0.1,0.6-1.7,0.7-1.7,1.2
            		c0,0.6,1-0.3,1.4-0.5c0.4-0.2-0.2,1.4,0.8,1.2c1.1-0.2,0.8-1.6,1.2-1.6c0.4,0.1-0.2,0.8,0.1,1.4c0.3,0.6-0.8,1.6-0.7,2
            		c0.1,0.4,2.2,0.4,2.9-0.3c0.7-0.7,1,0.1,0.5,0.6c-0.5,0.5-0.3,1,0.3,1.2c0.6,0.2,0.8,0.3,0.5,0.7c-0.3,0.5,0,1.4-0.2,1.8
            		c-0.1,0.4-2,0.4-2.1,0.1c-0.1-0.3-0.8-0.1-0.6,0.3c0.2,0.4-0.7,0.9-0.6,1.2c0.1,0.4,1.2,0.3,1.2,0.8c0,0.5-0.8,1-1.9,1.4
            		c-1.1,0.3,0.2,1.2,0.8,0.8c0.5-0.4,0.4,0.3,1.2,0.3c0.8,0,1.2,0.6,2.1,0.3c0.9-0.3,0.8,0,0.2,0.5c-0.7,0.5-1.6,0-2.2,0.3
            		c-0.6,0.3-3,2.6-2.6,3.2c0.3,0.3,0.6-0.5,1.5-0.8c0.9-0.4,1,0.2,1.5,0.3c0.5,0.1,0.5-1,0.9-0.9c0.4,0.1,0.7-0.3,1.5-0.2
            		c0.7,0.1,1.2-0.1,1.6-0.4c0.4-0.3,1.3,0.6,1.6,0.4c0.3-0.2,1.6-0.4,2.3-0.4c0.7,0,2.3-0.7,2.8-1.2c0.4-0.4-0.3-0.5-0.9-0.4
            		c-0.6,0.1-0.5-0.6,0.1-1.2c0.6-0.6,1.6-1,1.7-1.9c0.1-0.9-1.9-1.5-2.1-1c-0.2,0.5-0.7,0.4-1-0.1c-0.3-0.5,0.3-1,0-1.1
            		c-0.3-0.1-0.7-0.8-0.7-1.1c0.1-0.3-0.5-2.2-1.5-2.4c-1-0.2-1.2-1.7-1.3-2.4c-0.2-0.8-0.8-0.4-1.3-1c-0.5-0.7-1.4-0.3-1.8-0.3
            		c-0.4,0,0-0.8,0.7-1.3c0.7-0.5,1.5-3,1.5-3.5c0-0.5-3.1-0.5-3.7-0.2c-0.6,0.3-1.2-0.4-0.7-0.7c0.5-0.2,1.6-1.2,1.5-1.7
            		c-0.1-0.4,0.7-0.8,0.3-1.2c-0.4-0.4-0.5,0.5-0.9,0.8c-0.4,0.3-1.2,0.2-2.2,0c-0.9-0.2-1.3,1.1-1.2,1.7c0,0.6-1.1,0.9-0.9,1.4
            		c0.2,0.5-0.2,0.8-0.5,0.6C371.9,134,371.6,133.3,371,133.8z"/>
            	<path :class="eucolor" d="M407.4,141.1c-0.5-0.1-0.2,0.8,0.5,0.9c0.7,0.1,1.5-1.3,1.5-1.7c-0.1-0.4,0.8-2,0.5-2.2
            		c-0.2-0.1-0.4,0.4-0.7,0.5c-0.3,0.1-0.6-1.1-0.7-0.5c-0.1,0.7-0.7-0.3-1.3,0.6c-0.6,0.8,0.4,1.6,0.8,1.8
            		C408.4,140.9,408,141.2,407.4,141.1z"/>
            	<path :class="eucolor" d="M420.5,135.3c0.2,0.3,2.5-2.6,1.4-2.6C420.7,132.7,420.2,135,420.5,135.3z"/>
            	<path :class="eucolor" d="M429.8,129.5c0.2-0.4-1.7-0.2-1.3,0.2C428.9,129.9,429.6,129.9,429.8,129.5z"/>
            	<path :class="eucolor" d="M428.1,132.1c0.5,0,1.8-1,2.1-1.4c0.3-0.3-0.2-0.7-1.3-0.4C427.9,130.7,427.7,132.1,428.1,132.1z"/>
            	<path :class="eucolor" d="M406.9,59.4c0.5,0.1,0.9,1,1.4,1c0.2,0-0.8-1.1-1.3-1.7c-0.6-0.6-0.5-1.2-1.3-1
            		C404.9,57.8,406.4,59.2,406.9,59.4z"/>
            	<path :class="eucolor" d="M408.8,55.8c0.8,0.2-0.9,1.1-1.2,2c-0.3,0.8,1.3,1.6,2,2.2c0.7,0.6,2.3,0.5,2.9,0.2c0.6-0.4,0.4-1.6,1.2-1.6
            		c0.8,0,0.4-0.7,1-0.9c0.6-0.2,0.7,0.6,0.2,1.2c-0.5,0.6,0.9,0.6,1.7-0.2c0.8-0.8,1,0,0.8,0.7c-0.2,0.7-1.8,0.3-2.4,1.1
            		c-0.6,0.8-2.4,0.2-3.2,1.1c-0.8,0.9,0.7,0.9,1.8,0.7c1.1-0.2,3.2-0.4,4-0.2c0.8,0.2-0.5,0.4-1.8,0.4c-1.3,0-1.8,0.4-1.7,0.8
            		c0,0.4-1.9-0.4-2,0.4c-0.1,0.8,1.8,1.1,2,1.6c0.2,0.5,1.5,0,2,0.2c0.5,0.2-0.8,0.5-1,0.8c-0.2,0.2,1.4,0.5,1.7,1
            		c0.3,0.4,1.4,0.2,1.3-0.4c-0.1-0.8,1-3.1,2.3-3.6c1.3-0.5,0.6-0.9,0.8-1.7c0.2-0.8,1.2-0.1,0.9-0.8c-0.3-0.6-0.1-0.9,0.7-1.7
            		c0.8-0.8,1.4-0.2,2.4-0.7c1-0.5,1.5,0.4,0.4,0.4c-1,0.1-0.5,1.2,0.3,1.5c0.8,0.3,0.1,0.6,0.7,0.7c0.6,0.1,0.4,1.1-0.4,1.9
            		c-0.8,0.8,0.2,1,1.9,0.4c1.7-0.6,0.6,0.5,1.1,1c0.5,0.5,1.8-0.4,2.5-1.2c0.7-0.8,1.8-0.7,1.7-1.2c0-0.5-0.8-0.6-1.4-0.4
            		c-0.6,0.3-2.2,0.1-1.7-0.4c0.5-0.5,0.4-0.9-0.4-0.8c-0.8,0.1-2.2-0.3-1.6-0.6c0.7-0.3-0.5-1.2-1.1-1.1c-0.6,0-0.4-0.7-0.4-1.2
            		c0-0.5-1.8-0.7-1.4-0.9c0.4-0.2-0.8-0.6-1-0.2c-0.3,0.4-0.8,0.3-0.8-0.3c0-0.5-1.3-0.4-1.9-0.4c-0.5,0-0.3-1.2-0.8-1.6
            		c-0.5-0.4-1.2,0.8-1.6,0.7c-0.4-0.1,0.3-0.9,0.5-1.4c0.2-0.5-1.9-1.2-2.1-0.7c-0.2,0.5-0.6-0.8-1.1-0.9c-0.4-0.1-0.1,0.9-0.5,1
            		c-0.4,0.1-0.9,0.5-0.3,0.9c0.7,0.4,1.3,2.7,1.2,3c-0.1,0.4-2.1-1.6-2.2-2.5c-0.1-0.9-1.1-1.7-1.4-1c-0.3,0.7-1,0.9-0.8,1.6
            		c0.2,0.7-0.5,0.9-0.5,0.4c0-0.5-1.1-1.2-1.5-1.2c-0.4,0,1-0.3,1.7-0.6c0.7-0.3-0.6-0.8-1.2-0.4c-0.6,0.4-1.3-0.2-1.7,0.3
            		c-0.4,0.5-0.8,0-1.4-0.1s-1.2,0.9-1.6,0.7c-0.4-0.2-0.7,0.9,0,2C407.4,57,407.9,55.6,408.8,55.8z"/>
            	<path :class="eucolor" d="M421.1,52.1c0.4,0.3-0.7,0.3-0.2,0.8c1.3,1.3,6.6-0.2,7.3,0.2c0.7,0.4-4.4,0.9-4.2,1.4c0.2,0.5,4.4,1,4.7,0.6
            		c0.3-0.4,1,0.7,2.4,0.8c1.4,0.1,1.2-0.5,2.3-0.6c1,0,2.5-0.5,2.6-1.1c0-0.6,3-1.3,2.8-2.3c-0.3-1-3.2-0.3-4-0.7
            		c-0.8-0.4-2.7-0.9-3-0.3c-0.3,0.6-0.8,0.8-1.1,0.5c-0.3-0.3,0.1-1.7-1.2-1.3c-1.3,0.4-0.1,1.8-0.4,2c-0.3,0.2-1.5-0.3-1.6-0.9
            		c-0.1-0.6-1.5,0.7-1.6-0.1c-0.2-0.8-1.8-1.3-2.2-1.2c-0.4,0,0.4,0.5,0.1,0.9c-0.2,0.4-1.1-0.4-1.5-0.4c-0.4,0,0.2,0.8,0,1.1
            		c-0.2,0.4-0.9-1.1-1.5-1.1c-0.6,0-0.4,0.7-0.8,0.8C419.6,51.3,420.7,51.8,421.1,52.1z"/>
            	<path :class="eucolor" d="M395.3,121.8c0,0.2-0.8,0.6-0.2,0.7c0.5,0.1,0.1,0.6-0.2,0.7c-0.4,0.2-0.4,0.5,0,1c0.4,0.4-0.6,0.5-0.1,1.2
            		c0.5,0.7,0.8-0.2,1,0.3c0.2,0.5,0.8,0.1,1.4-0.5c0.7-0.6,0.9,0.7,0.4,0.6c-0.5,0-1.2,0.3-1,0.8c0.1,0.5-0.8,0.4-0.8-0.2
            		c0-0.7-1.1,0.1-0.7,0.6c0.4,0.5-0.1,0.7-0.1,1.2c0,0.4,0.8,0.4,1.1-0.1c0.3-0.5,0.9-0.4,1.1,0.1c0.2,0.5-0.5,0.1-0.5,0.8
            		c0,0.7-0.4,0.1-0.8,0.6c-0.4,0.5,1.5,1.5,2.1,1.7c0.6,0.1,1.2,0.8,2.3,0.6c1.2-0.2,3.4-2.4,3.7-2.8c0.3-0.4,1,0.1,1.4-0.3
            		c0.4-0.4,0.2-1.3,0.5-1.5c0.3-0.2,0.5,1,0.8,1.1c0.3,0.1,0.5,0.3,0.5,0.9c0,0.1,0,0.1,0,0.2c0,0.7,0.2,1.5,0.9,2
            		c0.7,0.5,0.1,1.3,0.7,2.1c0.6,0.7,0.4,1.3,1,1.8c0.6,0.5,0.7,0.8,0.4,1.5c-0.3,0.7,0.5,0.5,0.6,0.9c0,0.4-0.2,1.3,0,1.7
            		c0.2,0.4,0.6,0,1.6,0c1,0,0.7-0.6,0.7-1.2c0-0.6,0.5-0.3,0.6-0.7c0.1-0.4,1-0.4,1.7-0.2c0.7,0.3,1-0.2,1.2-0.9
            		c0.1-0.7,0.5,0.2,0.7,0.3c0.2,0.1,0.8-1.1,1.1-1.9c0.3-0.8,0-0.7-0.7,0.1c-0.7,0.8-0.3-0.8,0-1.4c0.3-0.6,0.2-2.4,0.3-2.9
            		c0.1-0.4,0.3-1,1.1-1.2c0.9-0.2,2-1.2,1.8-1.7c-0.2-0.4,1.2-1.2,1.2-1.6c0-0.4-1.4-1.8-1.9-2.1c-0.5-0.3-1.5-0.1-1.4-0.4
            		c0.1-0.3-0.1-1.6-0.2-2.3c-0.1-0.7,0.7-1.3,0.7-2c0-0.6,0-1.3,0.5-1.5c0.6-0.2,0.2-0.7,1-0.9c0.7-0.2,0.6-1.2,1.4-1.6
            		c0.8-0.3,0.9-0.6,1.9-1.1c1-0.5,2.8-1.7,3.1-2.3c0.3-0.6-1.1-1.3-0.2-1.9c0.9-0.7,0.1-1.4,0.8-1.6c0.6-0.2,0.9-0.6,1.3-1
            		c0.4-0.5,1,0,2.1-0.2c0.4-0.1,0.8,0,1.1,0c0.6,0.1,1.1,0.4,1.6,0.7c0.8,0.4,0.7,0.9,0.7,1.5c0,0.6,0.1,1-0.4,0.7
            		c-0.4-0.3-0.9,0-1.1,0.9c-0.2,0.9-1.4,2-2.1,2c-0.7,0.1-0.6,0.8-1.2,0.9c-0.6,0.1-1.1,0.5-1.1,1.1c0,0.6-0.8,0.7-1.4,0.7
            		c-0.6,0-0.4,1-0.9,1.3c-0.4,0.3-0.4,0.6,0.1,1.2c0.4,0.5,0.1,1,0.2,1.4c0.2,0.4,0.8,1.3,0.4,2.3c-0.4,1-0.9,2.2-0.5,2.1
            		c0.4-0.1,1.3,0.8,1.9,0.7c0.6-0.1,0.7,0.8,1.2,0.7c0.6-0.2,0.6,0.5,2.2,0.5c1.6-0.1,4.9-1.4,6.2-1.4c0.7,0,1.3-0.1,1.8-0.2
            		c0.3-0.4,0.6-0.9,1-1.2c0.8-0.8,2.6-1.4,3.1-2.5c0.5-1.1,2.6-1.9,3-2.9c0.2-0.5,0.2-0.7-0.5-1.6c-0.8-0.9-2.5-1.5-2.6-2
            		c-0.1-0.5,1.3-0.5,1.2-1.1c-0.1-0.6-0.8-0.6-1.1-1.1c-0.3-0.5,0.4-0.8,0.3-1c-0.1-0.2-1-0.4-1-0.7c-0.1-0.3,0.6-0.2,0.5-0.6
            		c-0.1-0.4-0.7-1-0.3-1.5c0.4-0.5,1.3,0,0.7-1.2c-0.7-1.2-1.7-2.7-1.9-3c-0.2-0.4,0.2-1,0.6-1.2c0.4-0.2,1.2-1.1,1.2-1.5
            		c0-0.4-1.1-1.3-1.4-1.5c-0.3-0.2-1-0.1-1.2-0.7c-0.3-0.6-0.6-0.8-0.3-1.1c0.3-0.4,0.3-0.7,0.3-1.1c0-0.4,0.7-0.1,0.8-0.6
            		c0.1-0.2,0.2-0.5,0.4-0.7c0.3-0.3,0.6-0.5,0.9-0.5c0.6,0,1.1-0.9,1.7-0.8c0.4,0.1,0.9,0,1-0.7c-0.5-0.3-1.4-0.3-1.5,0.1
            		c-0.1,0.4-0.9,0.4-0.9-0.1c0-0.4-1.2-0.8-1.3-1c-0.1-0.2,0.5-0.2,1,0.1c0.5,0.3,0.8,0.1,1.3-0.4c0.5-0.5,1.3-0.1,1.4-0.5
            		c0.1-0.4-0.9-0.7-1-1c-0.2-0.3-0.9-0.6-1.5-0.5c-0.7,0.1-0.7-0.1-1.1-0.5c-0.4-0.4-1.6,0.2-1.6,1.1c0,0.8-0.7,0.6-0.5,0.2
            		c0.3-0.4-0.4-1,0.2-1.1c0.6-0.1,0.3-1-0.8-1.1c-1.1-0.1-1.5,0.8-1.2,1.1c0.3,0.3-0.7,1.6-1.2,1.6c-0.4,0,0.1-1.1,0-1.7
            		c0-0.6-0.4-0.3-1.3,0.7c-0.8,1-1.5,1.8-1.9,1.9c-0.5,0.1-0.4-0.9,0.4-1.3c0.8-0.4,0.8-1.5,1.3-1.5c0.5,0,0.5-0.6-0.2-0.8
            		c-0.7-0.2-0.9,0.7-1.1,0.9c-0.2,0.2-1.5-0.4-1.4-0.2c0,0.2-0.7,0.3-0.4,0.8c0.3,0.4-0.1,0.8-0.4,0.3c-0.3-0.5-1-0.1-1.4,0.5
            		c-0.4,0.6-0.8,0.6-0.5,0.8c0.4,0.2,0.3,1-0.2,1.2c-0.5,0.1-0.3-1.5-0.7-1.3c-0.4,0.1-0.1-0.8,0.4-0.8c0.6,0,1.1-0.8,1.1-1
            		c-0.1-0.3-0.7-0.3-0.7,0.2c0,0.4-0.9,0.4-1.6,0.3c-0.7,0-0.7,0.9,0.1,1.3c0.8,0.5-0.1,0.8-0.6,0.4c-0.4-0.4-1.1-0.2-1.5,0
            		c-0.4,0.2,1.1,0.7,1.1,1c0.1,0.3-0.8-0.3-1-0.1c-0.2,0.2-1-0.1-1.1-0.7c-0.1-0.5-1.4,0.2-0.7,0.4c0.7,0.2,0.5,0.7,0.5,1.3
            		c0,0.7-0.9,0.1-0.7-0.4c0.2-0.6-0.5-0.4-1.1-0.1c-0.6,0.4,0.1-0.7-0.1-1.2c-0.2-0.5-0.8,0-1.4,0.1c-0.6,0.1-1,0.1-0.6,0.6
            		c0.4,0.4,0.3,1-0.2,0.9c-0.5-0.1-0.7,0-0.7,0.5c0.1,0.5-0.7,0.5-0.7,0.1c-0.1-0.4-1.1-0.3-1.4,0.2c-0.3,0.5-1.1,0.8-1,1.4
            		c0.1,0.5,0.7-0.3,1.3-0.1c0.5,0.2,0,0.4,0.3,0.7c0.3,0.3,0.2,0.9-0.2,0.5c-0.4-0.4-1-0.5-1,0.1c-0.1,0.6-0.5-0.1-0.9-0.5
            		c-0.4-0.4-0.7,0.8-1.1,0.5c-0.4-0.3,0.8-1.2,0.5-1.7c-0.3-0.4-0.5,0.3-1.2,1c-0.7,0.7-1.6,0.7-1.3,1c0.3,0.3-0.7,0.5-0.9,1.1
            		c-0.2,0.6-1.6,0.8-2.5,1.4c-0.9,0.6,0.2,0.6,0.7,0.1c0.5-0.6,0.7-0.2,1.5-0.7c0.8-0.5,1.7-0.9,2-0.7c0.3,0.2,0.8,0.4,1.2-0.2
            		c0.4-0.5,0.9-0.4,1.2-0.1c0.4,0.3-0.7,0.6-0.2,1.1c0.5,0.5-0.6,0.7-0.6,0.3c0-0.4-0.6-0.8-0.8-0.4c-0.2,0.4-0.6,0.8-1,0.8
            		c-0.4,0-0.8,0.7-0.8,1.5c0,0.7-0.7,0-0.7,0.6c-0.1,0.6-1,1.7-1.8,2.5c-0.8,0.8-0.1,1-0.3,1.5c-0.3,0.5-1.2,0.1-1.6,0.3
            		c-0.3,0.2,0.2,1.5-0.3,1.8c-0.4,0.4,0,0.9,0,1.4c0,0.4-1.2-0.3-1.2,0.2c-0.1,0.4-1.1,0.3-1.4,0.4c-0.3,0.1,0.6,0.8,1.1,1.2
            		c0.5,0.4-0.5,0.7-0.6,0.3c-0.1-0.5-0.9,0.3-1.8,0.7c-0.9,0.4-0.5,1.2-1.2,1.3c-0.7,0-0.5,1.2-1,1.5c-0.5,0.4-0.4-0.8-1.1-0.9
            		c-0.7,0-0.7,0.5-0.2,1.1c0.4,0.6-0.7,0.1-1.1,0.7c-0.4,0.5-1.6,0.3-1.9,0.7c-0.3,0.4,0.9,0.4,1.1,0.8c0.2,0.4-1.1,0.2-1.7,0
            		c-0.7-0.1-0.5,0.8-1.1,0.7c-0.6-0.1-1.3,0.5-0.9,0.9c0.4,0.4-0.3,0.5-0.7,0.2c-0.4-0.3-0.8,0.4-0.9,0.9
            		C394.4,121.3,395.3,121.6,395.3,121.8z M427.3,97.8c0.1,0,0.1,0.1,0.2,0.1C427.4,97.9,427.4,97.9,427.3,97.8z M426.5,97.2
            		C426.5,97.2,426.5,97.1,426.5,97.2C426.5,97.1,426.5,97.2,426.5,97.2z M426,96.8c0.1,0,0.1,0.1,0.2,0.1c-0.1-0.1-0.2-0.2-0.4-0.3
            		C425.8,96.7,425.9,96.7,426,96.8z"/>
            	<path :class="eucolor" d="M402.3,177.4c-0.7,0.6-1.2-0.1-1.5,0.4c-0.3,0.6,1,1.4,0.7,2.6c-0.3,1.2,0.1,2.6,0.8,1.9
            		c0.7-0.7,1-0.4,1.4-0.9c0.4-0.5,0.1-2.4,0.3-3.3C404.4,177.2,402.9,176.8,402.3,177.4z"/>
            	<path :class="eucolor" d="M413.8,184.1c-1.2,0.3-1.7,0.4-2.4,0c-0.7-0.4-1.9,0.3-1.7,0.8c0.1,0.3,0.6,0.7,2.1,1.1
            		c1.5,0.4,1.7,1.4,2.3,1.4c0.6,0,0.7-0.6,0.5-1.4c-0.1-0.8,0.7-1.7,0.8-1.9C415.5,184.1,415,183.9,413.8,184.1z"/>
            </g>
            <g id="Asia" @mouseover="hover('asia')" @mouseout="hover('asia')" @click="select('asia')">
            	<path :class="asiacolor" d="M439,177.3c0.7-0.3,2.3,0.1,2.7-0.3c0.4-0.4-1-0.8-1.5-1.2c-0.3-0.2-0.4-0.7-0.5-1c-0.2,0.2-0.5,0.3-0.8,0.2
            		c-0.5-0.3-1.8-0.1-2.1,0c-0.2,0.1,0.2,0.8,0.2,1.3c0,0.5-0.6,0.6-0.6,0.9c0,0.2-0.2,0.4-0.5,0.7c0.4,0.3,0.8,0.6,1.2,0.6
            		C437.9,178.4,438.3,177.5,439,177.3z"/>
            	<path :class="asiacolor" d="M664.3,178.3c-0.6,0.3-1,2.1-0.4,2.7c0.7,0.5-1.1,1.5-1,2.7c0.1,1.1-1.1,1.1-1.2,2c-0.2,0.8-0.8,0.7-1.8,1.4
            		c-1,0.8-1.5-0.1-1.3-0.7c0.2-0.5-1.5,0.3-1.5,1.6c0.1,1.3-1.7,1.4-1.2,2c0.4,0.6-1.3,1-1.2,0.4c0.1-0.5-1.1-0.2-2.4,0.3
            		c-1.4,0.5-2.2-0.6-3,0.2c-0.8,0.8-2.1,2.1-3.1,2.3c-1,0.2-0.4,1.4,0,1.2c0.7-0.4,1.8,0,2.2-0.5c0.4-0.5,3.3-1.2,5.1-1.2
            		c1.8-0.1,0.7,0.8,0.8,1.8c0.1,1,1.6,0.8,2.4-0.1c0.8-0.9,1.4-0.8,0.7-1.4c-0.7-0.5,0.1-1,0.6-0.3c0.5,0.7,2,0.8,2.6-0.2
            		c0.6-1,0.8,0.2,1.5,0.1c0.7-0.1,1.1-1.8,1.2-1.2c0.1,0.7,1.2,0.5,1.9-0.3c0.7-0.8,0.1-2.1,0.5-2.9c0.5-0.7,1-2.2,0.5-3.1
            		c-0.5-0.9,1.1-1.5,1.8-2.2c0.8-0.7-0.9-4.3-1-5.3C666.8,176.5,664.9,178,664.3,178.3z"/>
            	<path :class="asiacolor" d="M645.3,200.8c0.6-0.1,1.4-2.3,2.3-3.4c0.9-1.1-0.4-0.7-0.4-1.3c0-0.6-1.3-0.5-1.4-1c-0.1-0.5-1-0.2-1.8,0.4
            		s-1.2,0.8-0.8,1.4c0.4,0.7,0.9,0.1,1.1-0.6c0.2-0.7,0.8,1,0.3,2C644,199.4,644.6,200.9,645.3,200.8z"/>
            	<path :class="asiacolor" d="M650.2,196.4c0.2-0.7,1.1-1,1.7-0.7c0.6,0.3,1.4-0.9,1.4-1.8c0-0.9-1.8-0.9-2-0.2s-1,0.4-1.9,0.5
            		c-1,0.2-1,2.4-0.3,2.7C649.6,197.1,650,197.1,650.2,196.4z"/>
            	<path :class="asiacolor" d="M675.2,170.1c-0.4-0.5-1.1,0.3-2,0.5c-0.9,0.2-3.7-2.1-4.4-3.2c-0.7-1.1-1.9-0.2-1.3,0.6
            		c0.6,0.8-0.1,1-0.1,2.1c0,1.1-0.7,1.1-0.6,1.8c0.1,0.7-1,0.6-1.7,0.7c-0.7,0.1,0.1,0.8-0.8,1.2c-0.8,0.4-0.8,1-0.4,1.2
            		c0.4,0.2,0.1,1,0,1.5c-0.1,0.5,0.5,0.1,1-0.4c0.5-0.5,1,0.3,1.1-0.2c0.1-0.6-1.2-0.9-1.2-1.5c0.1-0.6,1,0.2,1.7-0.2
            		c0.7-0.4,2.1-0.1,3,0.7c0.9,0.8,1.1,0.5,1.3-0.5c0.2-1,1.5-1.4,2.9-1.4c1.4,0,1.5-0.7,1.1-1c-0.4-0.3,2.3-2,2.2-2.5
            		C677,169.1,675.7,170.7,675.2,170.1z"/>
            	<path :class="asiacolor" d="M604,227.3c0.1-0.7,1.2-1.8,1.1-2.4c-0.1-0.4-1.7-0.2-2.5-0.2c-0.8,0-2.7,1.1-2,2.7
            		C601.4,229.1,603.9,228,604,227.3z"/>
            	<path :class="asiacolor" d="M624.8,220.1c0.6-0.4,3-5.5,2.5-6.2c-0.5-0.7-2.9,0.4-3.6,2.8C623.1,219.1,624.6,220.3,624.8,220.1z"/>
            	<path :class="asiacolor" d="M620.4,245.5c-0.8,1.1-2.8,2.8-2.6,3.3c0.4,0.7,2.6-2.6,3.6-3.4c1.1-0.8,0.9-1.4,0.5-2.1
            		C621.7,242.6,621.3,244.4,620.4,245.5z"/>
            	<path :class="asiacolor" d="M632.5,245.8c-0.5-0.6-1.7,0.5-1.5,1C631.3,247.3,633,246.3,632.5,245.8z"/>
            	<path :class="asiacolor" d="M624.1,238.6c0.2,0.8,0.6,2.6,1.9,2.1C627.3,240.3,624,238,624.1,238.6z"/>
            	<path :class="asiacolor" d="M634.5,246.8c0.5,0.9-1,0.2-1.1,1.1c-0.1,0.9-1.2,0.2-1.2,0.9c0.1,0.7-1.2,0-1.8-0.4c-0.6-0.4-1.4,1.1-2.3,1.4
            		c-0.9,0.2-1.4,2.1-0.8,2.1c0.7-0.1,1.1-1,1.8-1c0.7,0.1,0.5-0.7,1.5-0.5c1,0.2,0.3,3.2,1.7,3.4c1.4,0.2,1.4,1.1,2.1,1.1
            		c0.7,0-0.1-1.9-0.1-2.6c0.1-0.7,1.4-0.1,2.1-0.9c0.7-0.8-0.5-3-0.5-4.5C635.9,245.5,633.9,245.9,634.5,246.8z"/>
            	<path :class="asiacolor" d="M627.3,241.7c-0.9,0.2-0.6,2.9,0.1,2.7c0.3-0.1,1,0,1.1-0.6c0.1-0.6,0.7,1.1,0.2,1.6c-0.5,0.5-0.5,2,0.3,2.2
            		c0.8,0.2,2.4-2.7,2.1-3.2c-0.2-0.5-1,0.4-0.8-0.4C630.5,243.3,628.2,241.5,627.3,241.7z"/>
            	<path :class="asiacolor" d="M623,232.6c-0.2,0.5,0.1,2.7,0.5,2.7c0.5,0,0.8,0.6,0.5,1.5c-0.3,1,0.9,1.1,2,0.7c1.1-0.4,0.7,0.8,1.8,0.6
            		c1.1-0.2,2.7,1.2,2.4,1.8c-0.2,0.5,0.1,2.3,0.6,1.8c0.5-0.5,1.4,0.1,0.8,0.9c-0.5,0.8,0.8,1.5,1,2.5c0.2,1,0.8,0.2,0.7-0.5
            		c-0.1-0.7,0.1-0.7,0.9-1.2c0.8-0.5-0.1-1.4-0.5-2.4c-0.5-1-2.3-0.7-2.3-1.6c0-1-1.3-1.2-1.4-1.8c-0.1-0.5-1.8-1.1-2.8-0.5
            		c-1,0.6-0.3-1.1-1-1.8c-0.7-0.8,0.5-1.9,1.4-2.9c0.8-1-0.2-2.6-0.1-3.3c0.1-0.7,0.1-1-0.4-0.7c-0.4,0.2-2.1-0.7-2.9-0.2
            		c-0.8,0.5-0.1,4.4-0.4,4.7C623.6,233.1,623.1,232.2,623,232.6z"/>
            	<path :class="asiacolor" d="M545.2,246.2c-0.5,0.1-0.4,1.1-0.7,1.9c-0.3,0.8-0.2,1.5-0.1,2.7c0.1,1.1,0,2.3,1,3c1.2,0.8,3-1.2,3.1-2.9
            		C548.6,249.2,545.7,246.1,545.2,246.2z"/>
            	<path :class="asiacolor" d="M490.8,240.4c0.7,0.7,2-0.1,2-0.4C492.9,239.7,490,239.6,490.8,240.4z"/>
            	<path :class="asiacolor" d="M514.4,49c2.3-0.2,2.2-1.5,1-1.7c-1.2-0.2-1.4,0.7-2,0.7c-0.6-0.1-2.8,0.4-2.4,0.9
            		C511.6,49.5,513.3,49.2,514.4,49z"/>
            	<path :class="asiacolor" d="M494.1,79.4c1.1,0.1-0.7,0.2-0.5,1.1c0.1,0.9-1.8,0.3-1.8,0.9c0.1,0.6-0.3,1-1.4,1c-1.1,0,0.5,0.7,0.7,1.4
            		c0.1,0.7-1.1,0.2-1.2,1.2c-0.1,1-2.2,0-2.5,1.3c-0.3,1.3,1.1,1.1,2,1.2c1,0.1-0.4,1,0.4,1.5c0.7,0.5,1.2,0.4,0.8-0.7
            		c-0.5-1,2.5,0.4,1.6,1.1c-0.9,0.8,1.4,1.4,2.6,1.4c1.1,0.1,5.1,0.9,5.2,0c0.1-0.6-1.5-1.1-2.7-2.4c-1.2-1.3-2.1-3.4-0.7-4.3
            		c1.4-0.9,0.2-1.3,1.6-2.3c1.4-1,0.6-2,1.8-2.1c1.2-0.1-0.1-1.2,1.1-1.4c1.2-0.2,1.4-1.5,1.2-2c-0.2-0.5,1.2,0.1,1.9-0.7
            		c0.7-0.7,2.2-0.2,2.7-1.2c0.5-1,7.3-3.3,11.2-4.2c3.9-0.9,6.5-2.5,5.2-3.6c-1.4-1.1-5.2,0.4-6.2,1.2c-1,0.8-2.3,0.2-3.3,0.8
            		c-1,0.5-3,1-4,0.4c-1-0.7-2.2,0.8-3,0.7c-0.8-0.1-1.7,0.8-2.5,0.7c-0.8-0.1-2.5,0.7-2.6,1.1c-0.1,0.5-1.5,0.4-1.5,1
            		c0,0.6-1.1,1.1-1.7,0.6c-0.6-0.5-1.3,0.6-0.5,1.4c0.8,0.8-1.5,0.8-1.1,1.3c0.4,0.5-0.9,0.8-0.6,1.3c0.3,0.5-0.8,0.7-1.7,0.9
            		S493,79.3,494.1,79.4z"/>
            	<path :class="asiacolor" d="M499.6,47.3c1.4,0,1-0.9,2-0.8c1,0.1,2.2,0.1,1.7-0.5c-0.5-0.6-3.9-0.4-3.6,0.1c0.4,0.5-2.8,0.3-2.7,0.5
            		C497.1,46.9,498.2,47.3,499.6,47.3z"/>
            	<path :class="asiacolor" d="M500.6,48.4c0.2-1.2-1.9-0.2-3.4-0.9c-1.5-0.7-2.5-0.6-1.4,0.3c0.5,0.4-2.6,0.7-2.1,1.2
            		C494.8,50.2,500.5,49.6,500.6,48.4z"/>
            	<path :class="asiacolor" d="M525.9,82.1c0.8,0.1,2.9-0.3,3.1-0.7c0.2-0.4-0.8-0.9-2.2-0.9C525.4,80.5,525.2,82,525.9,82.1z"/>
            	<path :class="asiacolor" d="M483.9,49.3c0.8,0.6-2.3,0.4-2.7,1c-0.4,0.6-2.2,0.4-1.9,1.1c0.4,0.7,3.3,0.8,3.3,0.2s1.5-0.1,1.6-0.6
            		c0.1-0.5,0.7-1.1,2.4-1.2c1.7-0.1,1.7-0.7,0.1-1.4C485.1,47.6,483,48.7,483.9,49.3z"/>
            	<path :class="asiacolor" d="M478.3,49.8c1.4-1.2,3.2-0.1,3.4-0.7c0.2-0.6-2.6-0.8-3.6-0.2c-1,0.5-3.4,0.1-3.1,0.8
            		C475.2,50.1,476.9,51.1,478.3,49.8z"/>
            	<path :class="asiacolor" d="M481.3,97.3c1.6,1.5,3-0.7,3.7-0.8c0.8-0.1-0.5-1-1.8-1.3C482,94.9,480.1,96.2,481.3,97.3z"/>
            	<path :class="asiacolor" d="M499.9,50.5c0.2,0.5-0.3,0.5-1.5,0.5c-1.2,0-1.8,0.7-1.2,1.2c0.7,0.6,3.9,0.4,4.4-0.4c0.5-0.7,1.9-0.1,2.1-0.8
            		C504,50.3,499.8,50,499.9,50.5z"/>
            	<path :class="asiacolor" d="M510.1,49.3c0.4-0.5-0.8-0.5-0.9-1.1c-0.1-0.5-3.4-0.7-3.3-0.1c0.2,0.6-2.3,1.2-1.5,1.8
            		C505.8,51.2,509.7,49.9,510.1,49.3z"/>
            	<path :class="asiacolor" d="M492.3,51.2c0-1-3.1,0.1-2.5,0.3C490.3,51.7,492.3,52.2,492.3,51.2z"/>
            	<path :class="asiacolor" d="M668.6,151.9c0.5,1.1-0.7,6.8-0.2,7.7c0.5,1-0.8,5.2-0.4,5.6c0.7,0.8,0.2-1.1,1.3-1.3c1.1-0.2,1.1,1.2,1.7,1.5
            		c0.7,0.3,0.2-1.9-0.4-1.8c-0.6,0.1-1.1-1.4-1.4-2.4c-0.3-1,0.6-2.1,0.6-3.3c0-1.2,1.5-1.1,2.4-0.2c0.9,0.9,1,0.3,0.7-0.4
            		c-0.4-0.7-1.5-5.4-2.1-7.1c-0.7-1.7,1-3.4-0.2-4.3c-1.2-0.9-0.7-2.7-1.3-2.4c-0.6,0.4,0,2-1,2.1c-1,0.1-0.1,1-0.4,2.2
            		C667.6,149.1,668.1,150.8,668.6,151.9z"/>
            	<path :class="asiacolor" d="M743.3,90.6c1.2,0.6,1.9-0.7,2.9-0.1c1,0.7,3.3-0.3,4.2-0.5c1-0.2,0.5-1.4-2.2-1.7C745.5,88,742,90,743.3,90.6
            		z"/>
            	<path :class="asiacolor" d="M676.9,73.9c1.4,0.1,2.1,1.5,4.9,1.7c2.7,0.2,4.8-0.4,4.8-1c0.1-0.6-2.5-1.5-3.3-1c-0.8,0.5-1.4-0.8-2.3-0.5
            		c-1,0.3-2.3,0.2-2.7-0.6C677.8,71.7,676.2,73.9,676.9,73.9z"/>
            	<path :class="asiacolor" d="M658.9,74c0.8,0.4,1.1,0.8,1.6,1.3c0.5,0.5,2.3-0.2,3-0.5c0.7-0.2,0.8,1.1,1.9,0.4c1.1-0.7,2.3-0.4,3.6-0.4
            		s-0.2-1.6-0.2-2.3c0.1-0.7,1.5-0.2,1.1,0.4c-0.5,0.6,0.7,2,2.6,1.8c1.9-0.1,0.5-1.3,1.4-1.7c1-0.4,0.8-0.9-0.5-1.7
            		c-1.4-0.8-3.1-0.3-4.3-0.8c-1.3-0.6-2.8-0.6-2.9,0.7c-0.1,1.3-2.6-1.4-3.9-1.8C661,68.9,656.9,73,658.9,74z"/>
            	<path :class="asiacolor" d="M607.9,76.6c-0.2,0.5-1.5,0.5-1.2,1c0.6,0.8,3.6,0.4,3.6-0.6C610.4,76,608.1,76.1,607.9,76.6z"/>
            	<path :class="asiacolor" d="M766.1,107.4c-0.6-0.2-2.5-1.4-3-2.1s-2.8-1-2.8-0.7c0,0.4-0.7-0.1-0.9-0.5c-0.2-0.4-2.6-0.4-2.6,0
            		c0,0.4,0.6,0.4,0.9,0.9c0.4,0.5-0.5,0.7-0.3,1.3c0.2,0.5-0.9-0.3-1.3-0.6c-0.4-0.3-0.2-1.5-0.1-2.1c0.1-0.6-0.7-0.5-0.8-1.1
            		c-0.1-0.6-2.8-1.8-3.9-2.3c-1.1-0.5-2.6-0.9-3-1.5c-0.4-0.6-2.4-0.7-3.2-1.5c-0.8-0.8-4.2-2.2-5.6-2.3c-1.5-0.1-1.1-0.9-1.8-0.8
            		c-0.7,0.1-3.8,0-4.7-0.2c-1-0.1-1.2,0.6-1.8,0.3c-0.7-0.3-4.2-1.5-4.6-1.1c-0.4,0.5-0.2,1.2-0.7,1.3c-0.4,0.1,0,0.7,1,1.6
            		c1,0.9,0.4,1.6-0.5,2c-0.9,0.4-2.3-0.4-2.8-1c-0.4-0.7-1.7-0.4-2-1.3c-0.3-0.9,0.4-1,1-0.5c0.6,0.5,1.4,0.1,1.5-0.6
            		c0.1-0.7-1.6-1.1-2.5-1c-0.9,0.1-1.4,1.3-2.4,1.9c-1,0.5-4.6-0.2-5-0.6c-0.4-0.4-5.5,0-6.1,0.4c-0.5,0.4-0.1,1.8-0.4,1.9
            		c-0.3,0.1-0.5-1.6-0.5-1.9c0-0.3-0.6-0.6-1.5-0.6c-0.9,0-1.5,0-1.1-0.4c0.4-0.4-0.4-0.8,0.4-1.3c0.7-0.5-1.3-2.1-3.3-2.9
            		c-2-0.8-6.4-0.3-7.6,0c-1.2,0.4-3.6,0.2-4.7,0.3c-1.1,0-0.2-0.4-0.5-1c-0.3-0.5-2.3-1.2-2.5-0.7c-0.3,0.4-0.8,0-0.8-0.3
            		c0-0.3-1.8-1-2.5-0.8c-0.7,0.1-1-0.7-0.2-0.7c0.8,0,2.3,0.2,1.6-0.8c-0.6-1-6-1.2-6.5-1.1c-0.5,0.2,0,0.9-1.2,2
            		c-1.2,1.1-2.3,0.4-2.1-0.2c0.1-0.5,1.6-0.4,1.6-1c0-0.5-2-0.2-2.3-0.7c-0.3-0.4,0.4-0.7,1.1-0.5c0.7,0.2,2,0.3,2,0
            		c0.1-0.3-0.7-0.4-1.9-0.6c-1.2-0.2-4.1-0.9-5.6-0.7c-1.5,0.1-2.2-0.6-3-0.6c-0.8-0.1-0.8,0.4-0.4,0.9c0.4,0.5-0.8,0.8-1.8,0.6
            		c-1-0.1-2.4,0.5-1.8,1.2c0.6,0.7,1.1-0.2,1.6,0c0.5,0.2-1.2,0.7-0.5,1.1c0.7,0.3,0.6,1,0.6,1.4c0,0.4-1.2,0.5-1.6,0
            		c-0.4-0.5-1.1-0.1-1.9-0.1c-0.8-0.1-1.5,0.3-0.6,0.4c0.9,0.1,1.2,0.7,0,0.8c-1.2,0.1-0.9-1.1-1.6-1c-0.7,0.1-2.3-0.6-3.1-0.5
            		c-0.8,0-1.2,0.7-2.3,0.8c-1.1,0.1-2.6-0.6-3-1.2c-0.4-0.6-0.6-0.8-1.1-0.1c-0.5,0.7-0.6,2-1.1,2.1c-0.5,0.1-0.7,1.2-1.3,1.5
            		c-0.5,0.3-0.6-0.4-1.2-0.4c-0.7,0.1-2.9-2.1-3-2.7c-0.1-0.6-1.5-1.8-1.8-2.1c-0.4-0.2,0.4-0.4,0.8,0c0.4,0.4,1,0.4,1.5,0.1
            		c0.4-0.2,0.4-1.3-0.4-1.4c-0.8,0-0.5-0.7-0.1-0.8c0.4-0.1-0.9-1.3-0.4-1.4c0.5-0.1,0.3-0.6-0.4-0.7c-0.7-0.1-1.3-0.6-1.4-1
            		c0-0.3-2.8-0.4-2.7,0c0.1,0.5-0.9,0.3-0.7-0.1c0.1-0.4-1-0.3-2.3-0.6c-1.3-0.4-1.3-1.3-1.8-1.3c-0.4-0.1-0.3,1.3-0.9,1
            		c-0.7-0.3-1.4,0.3-1,1c0.4,0.8,0,0.9,0,1.5c-0.1,0.6-0.4,0.4-1.2,0.1c-0.8-0.3-0.8,0.7-2.4,0.2c-1.7-0.4-2.4,0-2.7-0.5
            		c-0.3-0.5-0.8-0.6-0.9-0.1c-0.1,0.4-2.6,0.3-2.8-0.4c-0.2-0.7,0.4-0.8,0.8-0.9c0.4-0.2-0.4-0.4-1.8-0.4c-1.4,0.1-2.3-0.7-3.7-0.6
            		c-1.4,0.1-4.6,0.4-5.1,0.7c-0.4,0.2,0.4,1-0.3,1.1c-0.7,0.1-0.2-1.3-0.3-2c-0.1-0.7-1.2-0.7-1-0.2c0.1,0.5-1.6,0.7-2.1,0.3
            		c-0.4-0.4-1.2-0.9-2.3-1.2c-1.2-0.3-2.6,1-2.3,1.3c0.3,0.4,1.2,0.1,1.2,0.3c0.1,0.5-2.5,0.3-2.5,0.7c0,0.4-2.3,1-3.1,1.1
            		c-2,0.2-2.4,0.2-3.3,1c-0.9,0.8-0.2-0.7,0.5-1.4c0.7-0.7,1.5-0.4,1.9-1c0.4-0.6,1.1-0.5,2.2-0.7c1.1-0.2,1.1-0.8,1.6-1.1
            		c0.5-0.2,1.8-0.7,2.1-1.3c0.2-0.5,2.9-1.4,3.4-1.4c0.5,0,0.7-1.1,0.9-1.1c0.3,0,1.8-0.7,2.4-1c0.6-0.3,0.9-0.8,0.9-1.2
            		c0-0.4-0.8-0.3-0.9-0.7c-0.1-0.4,0.4-0.1,1-0.4c0.6-0.2,0-1.1-0.5-0.9c-0.5,0.1,0.3-0.4-0.2-1c-0.4-0.5-1.3,0.3-1.3-0.2
            		c0-0.6-0.1-1.3-0.4-1.6c-0.4-0.4-1,0.6-1.8,0.1c-0.7-0.6-2.9-0.9-2.9-0.7s-1-0.4-1.2-0.1c-0.2,0.2-1.9,0.2-2.5,0.1
            		c-0.5-0.1-1.3,0.2-1.3,0.7c0,0.5-2.1,0.3-2.5,0.2c-0.4-0.1,1.5-1.6,1.5-1.9c0-0.3-3.1,0.1-3.3-0.2c-0.2-0.3-1.7-0.4-2.4-0.4
            		c-0.7,0,0-0.2,0.6-0.2c0.6-0.1,1.2-0.6,1.9-0.7c0.7-0.1,0.5-0.4,0.2-0.8c-0.3-0.4-1-0.2-1.7-0.4c-0.7-0.2-1.5-0.5-2.1-0.5
            		c-0.6,0-1.2,0.3-2.6,0.6c-1.4,0.3-1.5,1.3-2.2,1.7c-0.7,0.3-2,1.6-1.4,1.8c0.6,0.1,0.3,0.5,0.4,0.9c0.1,0.4-0.4,0.5-1.1,0.4
            		c-0.7-0.1-3.1-0.1-3.1,0.4c0,0.5,1.4,0.7,1.3,1c-0.1,0.3-1,0-1.5-0.3c-0.5-0.3-1.5-0.1-1.8,0.4c-0.2,0.5-1,0.4-1.3,0.3
            		c-0.3-0.1-0.9,0.2-1.3,0.1c-0.4-0.1,0.9-0.8,0.6-1.1c-0.3-0.3-1.8,0-1.9,0.5c-0.1,0.5-1-0.4-1.3-0.2c-0.3,0.2-0.7,0.4-1.3,0.7
            		c-0.6,0.3-1.7-0.3-1.9,0.2c-0.2,0.5,1.2,0.6,1.2,0.8c0,0.2-3.2,0.2-3.3,0.5c-0.1,0.3-1.2,0.3-2.5,0.4c-1.3,0-0.8,0.7-1.9,0.9
            		c-1.1,0.2-1.5,0.2-1.9,0.8c-0.4,0.5-1.2,0.6-1.5,0.2c-0.4-0.4-1.3,0.2-0.8,0.3c0.5,0.1,0,0.6-0.3,0.5c-0.3-0.1-1.3,0.6-0.7,0.5
            		c0.5-0.1,0.8,0.4,0.5,0.7c-0.3,0.3-1.4-0.4-1.7-0.1c-0.3,0.3,0.6,0.7,1.1,0.6c0.5-0.1,0.8,0.3,0.9,0.7c0.1,0.4-1.2-0.2-1.5,0.1
            		c-0.3,0.3,0.2,0.5,0.8,0.5c0.6,0.1,0.1,0.3,0.7,0.7c0,0,0.1,0.1,0.1,0.1c0.3,0.4-0.3,0.5-0.2,0.9c0.1,0.4-0.6,0.5-0.6,0.2
            		c0-0.4-1.6-0.5-1.9-0.1c-0.3,0.4-0.6,0.7-1,0.3c-0.4-0.4-1.5,0.1-3,0.1c-1.5,0.1-4.5,0.2-5.2,0.4c-0.7,0.3-1.4,1.3-0.7,2
            		c0.7,0.7,0,1,0,1.4c0,0.4,1.8,1.4,2.7,1.5c1,0.1,1.7,1.2,1.2,2c-0.6,0.7-2.3-0.2-3.4-1.1c-1.1-0.9-3.7-1.2-4.6-1.2
            		c-0.9,0.1-0.4-0.8-1.5-0.7c-1.1,0-2,1-1.3,1c0.7,0,1.2-0.1,0.8,0.3c-0.4,0.4,0.2,0.3,1.1,0.7c0.9,0.4-0.7,0.9-1.7,0.3
            		c-0.9-0.6-1.9,0-2.2,0.4c-0.3,0.5,1.1,1.8,2.7,2.1c1.6,0.2,1.1,0.7,1.8,1c0.7,0.3,0.2,0.6-0.3,0.6c-0.5,0-1.5-0.8-2.3-1.2
            		c-0.8-0.4-3,0.2-3.4-0.3c-0.4-0.5,0.3-1-0.1-1.3c-0.4-0.4-0.1-1.2,0.5-2c0.5-0.8-0.1-2.3-0.9-2.5c-0.8-0.2-0.7,0.6-0.5,0.8
            		c0.2,0.2,0.1,1.4-0.5,2.1c-0.6,0.7-2.4,0.7-2.4,1.2c0,0.5-1.2,0.9-0.8,1.2c0.4,0.4,2.1,2.2,2.3,2.8c0.2,0.6-1.5,2.3-1.2,3.4
            		c0.3,1-0.2,1.5,0.1,2c0.3,0.5,1.1,0.1,1.5,0.3c0.5,0.2,1.2-0.3,2.3-0.5c1.1-0.2,3.1,1,3.9,1.3c0.7,0.4,0.4,1,0.7,1.5
            		c0.4,0.6-0.9,0.6-0.9,1.5c0,0.9,2.2,1.3,2.3,1.5c0.1,0.3-1.6,0.1-2.3-0.2c-0.7-0.2-0.7-1.1-0.9-1.3c-0.2-0.2,0.4-0.9,0.5-1.5
            		c0.1-0.6-0.7-0.9-1-1.3c-0.3-0.4-0.7-1-1.3-0.8c-0.5,0.1-2.5,0.3-3.1,0.8c-0.6,0.5,0.1,2.1,0.4,2.8c0.3,0.7-1.7,1.8-1.7,2.4
            		c0.1,0.6-0.6,0.9-1.5,1.3c-0.9,0.5-1.9,0.8-1.8,1.6c0,0.8-1.2,0.4-1.8,0c-0.6-0.4-1.4,0.2-2.3,0.2c-0.9,0-0.9-0.8-1.6-0.5
            		c-0.7,0.3-1.2-0.3-1-0.9c0.2-0.5,1-0.1,1.1,0.3c0.1,0.4,0.6,0.2,1.2-0.1c0.6-0.3,0.3,0.4,1.2,0.5c0.9,0.1,0.4-0.4,1-0.4
            		c0.7,0.1,0.7-0.1,0.5-0.5c-0.2-0.3,0.5-0.6,0.9-0.7c0.5-0.2,0.2-0.9,0.6-1.1c0.4-0.2,0.1-0.6,0.5-0.7c0.4,0,0.6-0.7,1-0.7
            		c0.4,0,0.6-0.6,0.4-1c-0.2-0.3,0.1-1.2,0.5-1.3c0.4-0.1,0.4-0.5,0.1-0.7c-0.4-0.3-1.7-1.2-1.6-1.7c0.1-0.6-0.2-1.6-0.1-2.1
            		c0.2-0.4,0-1.4,0-1.9c-0.1-0.5,0.3-1.1,0.5-2.1c0.2-1-1-2-1.6-2.3c-0.6-0.3-0.2-0.8,0.7-1.5c0.9-0.7,0.9-2.9,0.9-3.4
            		c-0.1-0.5-1.8-0.9-2.5-0.8c-0.7,0.1-3.2-0.1-3.8-0.2c-0.6-0.1-0.8,0.5-1.1,1.1c-0.3,0.6-1,1.5-1.3,2.9c-0.4,1.3-2,1.8-2.7,2.1
            		c-0.7,0.3-1.2,1.3-0.8,1.8c0.3,0.5,0.8,0.1,1.1,0.3c0.3,0.2,0,1.4-0.2,1.6c-0.2,0.2,0.4,0.6-0.1,1c-0.5,0.3-1,1-0.7,1.4
            		c0.3,0.4,1.8,0.5,2.4,0.9c0.6,0.4,0.4,1,0.9,1.6c0.5,0.6,1,0.2,1.2,0.6c0.2,0.4-0.9,1.9-1.2,2c-0.4,0.1-2-1.6-2.4-1.9
            		c-0.5-0.4-2.3-0.9-3.2-1.4c-0.9-0.5-1.7-0.4-2.7-1.1c-1-0.7-1.9-1-3.7-1c-1.8,0-3-0.4-3.7-0.3c-0.6,0.1-0.4-0.6-1.5-1.1
            		c-1.1-0.5-1.8-1.1-2.4-0.8c-0.6,0.3-0.4,1.5,0.6,1.7c0.9,0.1,0.5,0.7,1.5,0.6c1-0.1,1.3,0.3,1.3,0.9c0,0.6,0.5,1.2,0.9,1.7
            		c0.4,0.4,0.2,1.1-0.6,1.1c-0.8,0-1.7,0.1-1.4,0.7c0.4,0.6-0.2,0.7-1,0.4c-0.8-0.3-0.3-0.9,0-1.2c0.3-0.3-0.7-0.7-1.2-0.9
            		c-0.5-0.2-2.5,1.3-3.1,1.5c-0.6,0.2-2-0.4-3.4,0c-1.4,0.4-1.3,1.5-1.9,1.4c-0.5-0.1-2.1,0.1-2.7-0.2c-0.6-0.4-0.1-0.6,0.5-0.5
            		c0.6,0,0.8-0.2,0.5-0.6c-0.4-0.4,0.5-1.1,0.4-1.4c0-0.3-2.3,0.5-2.7,0.8c-0.4,0.3,0,0.9-0.4,1.1c-0.4,0.2-0.4-0.4-0.9-0.5
            		c-0.5-0.2-4,0.6-4.6,1.4c-0.7,0.7-1.9,0.8-1.9,1.2c0,0.4-1.6,0.4-2.2,0.7c-0.6,0.3-0.2,1.5-0.4,2.1c-0.2,0.6-2.7,0.4-3.4,0.4
            		c-0.7,0-1-1.4-1.6-1.5c-0.6-0.1-0.2-0.9-0.1-1.3c0.2-0.4,0.7-0.1,1.4-0.5c0.7-0.5,1.5,0.2,1.8-0.1c0.3-0.3-1.1-1.2-1.2-1.9
            		c-0.1-0.7-1.7-1-2.6-0.7c-0.9,0.2-1.9,0-2.5-0.3c-0.6-0.3-0.6,0.5,0.4,0.8c0.9,0.2,0.4,1,0.6,1.4c0.1,0.4-0.2,1.5-0.8,2.2
            		c-0.5,0.8-0.1,0.7,0.7,0.7c0.8,0,0.7,1,0.7,1.8c0,0.8-0.6,1-0.6,1.5c0,0.6-0.7-0.3-0.9,0c-0.2,0.2-0.5-0.1-0.6-0.6
            		c-0.1-0.5-1.3-0.2-2-0.4c-0.7-0.2-1,0.1-1.3,0.6c-0.3,0.5-1.3,0.7-1.8,0.8c-0.6,0.1-1.4,1-1.9,1.4c-0.5,0.4-0.6,1,0.1,1.8
            		c0.7,0.7,0.9,1.2,0.8,1.6c-0.1,0.4-2.1,0.3-2.4-0.1c-0.3-0.4-1.6-0.6-2.1-0.5c-0.4,0.1-1.7-1.3-2.3-1.4c-0.6,0-1,0.7-1.2,1.1
            		c-0.2,0.4,0.3,0.5,0.6,1.2c0.3,0.7,1.4,0.7,2,0.7c0.6,0,0.4,0.9,0.2,1.2c-0.2,0.4-1.2,0.6-1.5,0.2c-0.3-0.4-1.5,0-1.6-0.5
            		c0-0.5-0.9-1.2-1.8-1c-0.9,0.1-1.1-0.4-1-1.2c0.1-0.7-0.5-1.1-0.5-1.5c0-0.3-0.4-1,0.1-1.3c0.5-0.3,0.1-0.8,0.1-1.3
            		c0-0.4-0.8-1.1-1.5-1.1c-0.7,0-0.6-0.7-1.2-0.9c-0.6-0.1-1.9-1.2-2-1.6c-0.1-0.4-1-0.5-0.7-0.7c0.3-0.1,1.1,0.1,1.6,0.6
            		c0.5,0.5,1.4,1,2.8,1.2c1.4,0.2,2,0.8,3.5,1.1c1.5,0.3,2.5,0.4,4.7,0.8c2.2,0.3,5.2-1.9,5.8-2.6c0.6-0.6-0.2-1.9-0.2-2.4
            		c0-0.5-1-0.5-1.2-0.9c-0.2-0.4-0.8-1-1.7-1s-1-1-1.7-0.9c-0.7,0.1-1.2-0.3-2.9-1.5c-1.8-1.2-4.2-2-4.7-1.8c-0.4,0.2-0.7,0.3-1-0.1
            		c-0.3-0.4-1-0.4-1.5,0c-0.5,0.4-1-0.3-1.8-0.1c-0.9,0.2-1.4-0.4-1.1-0.7c0.2-0.3,1.5,0,1.5-0.5c-0.1-0.4-0.5,0-1.3-0.5
            		c-0.8-0.5-1.2-0.2-1.4,0.3c-0.2,0.4-0.8,0.3-1.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0.7-0.6,0.8-1,0.7c-0.5-0.1-1.1,0.8-1.7,0.8
            		c-0.6,0-1.2,0.7-1.4,1.2c-0.1,0.5-0.8,0.2-0.8,0.6c0,0.4,0,0.7-0.3,1.1c-0.3,0.4,0,0.5,0.3,1.1c0.3,0.6,1,0.5,1.2,0.7
            		c0.3,0.2,1.4,1.1,1.4,1.5c0,0.4-0.9,1.2-1.2,1.5c-0.4,0.2-0.8,0.8-0.6,1.2c0.2,0.4,1.2,1.8,1.9,3c0.7,1.2-0.2,0.7-0.7,1.2
            		c-0.4,0.5,0.2,1.2,0.3,1.5c0.1,0.4-0.5,0.3-0.5,0.6c0.1,0.3,1,0.4,1,0.7c0.1,0.2-0.6,0.5-0.3,1c0.3,0.5,1,0.5,1.1,1.1
            		c0.1,0.6-1.3,0.7-1.2,1.1c0.1,0.5,1.8,1.1,2.6,2c0.8,0.9,0.7,1.1,0.5,1.6c-0.4,1-2.5,1.8-3,2.9c-0.5,1.1-2.3,1.7-3.1,2.5
            		c-0.3,0.3-0.7,0.8-1,1.2c0.4-0.1,0.6-0.2,0.8-0.3c0.5-0.2,0.8,0.9,1.3,1.3c0.5,0.4,1.4,0,1.8,0.4c0.3,0.4-0.3,0.4-0.9,0.3
            		c-0.7-0.1-0.9,0.3-1.9,0.7c-0.8,0.3-0.7,0.6-1,0.8c0.5,0.7-0.2,0.8-0.4,1.3c-0.3,0.6-0.6,0.7-0.7,1c0,0.4,0.1,0.6,0.1,1.2
            		c0,0.5,0.1,0.6,0.4,1.1c0.3,0.4-0.3,0.4-0.5,0.7c-0.3,0.2-0.1,0.7,0.3,1.1c0.4,0.4,0.4,0.8,0.2,1.1c-0.1,0.3,0.3,0.8,0.6,1.2
            		c0.3,0.3,0.3,1.2,0.5,1.4c0.3,0.2,0.6,0.4,1.1,0.3c0.5-0.1,0.8,0.1,1,0.5c0.2,0.4,0.6,0.2,1.1,0c0.5-0.2,1.6,0.4,1.9,0.5
            		c0.3,0.1,0.1,0.9,0.2,1.4c0,0.5-0.3,1.1,0.2,1.4c0.5,0.3,0.4,1,0.9,1.2c0.6,0.2,0.8,1,1.2,1.1c0.4,0.1,1,0.6,1.1,1
            		c0.1,0.4-0.6,0.8-1,0.9c-0.4,0.2-1.2-0.5-1.4-0.2c-0.3,0.3,0.1,0.9,0.3,1.9c0.2,1,0.4,1.1,0.8,1.1c0.4,0,0.6-0.3,0.9-0.5
            		c0.3-0.2,1,0.1,1.3,0c0.3-0.1,1.4-0.2,1.6,0.2c0.2,0.4,0.4,1.2,0.3,1.5c-0.2,0.3,0.2,0.7,0.4,1.1c0.2,0.4,1.2,0.2,1.5,0.4
            		c0.3,0.1,1,1.1,0.9,1.6c-0.1,0.4,0.4,0.5,0.8,0.4c0.3-0.1,0.6,0.2,1,0.4c0.4,0.2,0.7,0.1,1-0.1c0.4-0.2,0.9-0.4,1.1-0.1
            		c0.2,0.3,0.9,0.9,0.9,1.1c0,0.2,1.1,0,1.6,0.3c0.5,0.3,1.1,0.1,1.7,0.4c0.6,0.3,1.1,0.4,1.2,0.7c0,0.4-0.6,0.7-0.6,0.8
            		c0,0.2,0.5,0.3,0.5,0.6c0,0.3-0.6,0.3-0.7,0.5c-0.1,0.2,0.4,0.5,0.4,0.8c0.1,0.2-0.4,1-0.4,1.2c0,0.2-1.3,0-1.6,0s-1.2,1-1.6,1.1
            		c-0.3,0-0.1,0.5,0,1c1-0.2,1.9-0.3,2.1,0c0.2,0.4-2.1,1.3-2.5,1.5c-0.4,0.1,0.8,0.8,0.8,1.1c0,0.4-1.3,0.4-1.2,1.2
            		c0.1,0.7-0.4,0.9-1.1,0.9c-0.7,0-0.9,0.4-0.5,0.5c0.3,0.1,0.3,1,1.6,1.3c1.3,0.3,2.6,1.5,3.7,2.7c0.2,0.2,0.3,0.3,0.5,0.4
            		c1.2,0.7,2.9,0.5,3.1,1.7c0.3,1.4,1.1,0.9,0.8,2.1c-0.1,0.2-0.2,0.5-0.5,0.7c-1,0.9-3.1,1.5-3.7,1.2c-0.8-0.3-2.3,0.1-3.5,0.1
            		c-1.2,0-1.5-0.8-2.4-0.8c-0.9,0.1-0.9-0.8-1.8-0.8c-0.9,0-0.2-0.4-3-0.8c-2.8-0.4-5.2,1.1-6.1,1.9c-0.9,0.8-4.1,0-4.4,0.2
            		c-0.3,0.2,0.1,1.1-1,1.5c-1.1,0.4-3.4,0-4.2,0.4c-0.8,0.4-1.1,1.5-0.4,1.5c0.7-0.1,1,0.3,0.8,0.6c-0.2,0.3,0.6,1.1,0.2,1.5
            		c-0.4,0.4-0.4,1,0.1,1.4c0.5,0.4,0.6,1,0,1.1c-0.6,0.1,1,0.7,1,1c0,0.3,1.3,0.4,1.3,0.8c0,0.4,0.4,0.5,1,0.3
            		c0.6-0.2,0.9,0.7,1.2,1.3c0.4,0.6,2.6,0.2,2.6-0.5c0-0.7,0.7-0.8,1.6-0.7c1,0.1,2.3,1.4,2.7,1.6c0.5,0.2,1.5-0.4,2.1-0.4
            		c0.6,0,1.5-1,1.9-1.2c0.4-0.2,1,0.7,1.5,0.4c0.6-0.3,1.2-0.6,1.5-0.4c0.4,0.2-0.9,1.1-0.5,1.6c0.1,0.2,0.1,0.4,0,0.7
            		c-0.1,0.4-0.2,0.7,0,1c0.3,0.4-0.1,0.9-0.2,1.5c0,0.1,0,0.2,0,0.3c0,0.6-1,1.9-1.5,3l0.9,0.4l0.1,0l0,0c0,0.2,0,0.4-0.1,0.6
            		c0-0.2,0-0.4,0.1-0.6l-1-0.4c-0.2,0.3-0.3,0.7-0.3,0.9c-0.2,1.1-1.1,2.8-1.6,3.6l1.4,3.5l-0.1,0.2l-0.2,0.6l-0.1,0.5
            		c0.1,0.4-0.1,2.2,0.3,2.3c0.4,0.1,0.7,1,1.6,2c1,1,2.9,3.8,2.9,4.5c0,0.7,0.4,1.4,1.5,2.2c1.2,0.8,1.2,1.8,1.8,2.3
            		c0.6,0.5,0.5,1,0.5,2.2c0,1.2,0.6,2.7,1.6,3.4c1.1,0.7,1.8,1.4,2.3,2.6c0.5,1.3,1.2,2.4,1.8,3.1c0.7,0.7,0.3,1,0.7,1.5
            		c0.2,0.3,0.5,0.7,0.7,1.2c0.2,0.4,0.3,0.9,0.2,1.2c-0.2,0.7,0,2.3,0.6,3.2c0.6,0.9,0.4,2.6,0.7,2.9c0.3,0.3,2.6,0.3,3.7-0.4
            		c1-0.7,1.8-0.8,2.6-0.8c0.8,0,1.9-0.7,2.2-1.1c0.3-0.4,1.5-0.4,2-0.3c0.5,0.1,1-0.5,1.6-1.1c0.6-0.6,2.3-0.8,4.3-1.4
            		c1.9-0.6,1.5-1.5,1.9-2c0.4-0.4,0.9-0.3,1.7-0.6c0.2-0.1,0.3-0.1,0.5-0.2c1.1-0.7,2.4-0.3,3.3-0.6c0.9-0.2,0-1,0.8-1.6
            		c0.7-0.6,1.8-0.1,2.2-0.4c0.4-0.2,0.7-1.6,1.3-1.8c0.7-0.3,1.4-0.1,1.3-0.7c-0.1-0.6,0.1-2.6,0.7-2.7c0.6-0.1,1.6-0.8,1.6-1.1
            		c0-0.4,1.3-1.7,1.7-2.3c0.4-0.7,0.4-1-0.1-1.1c-0.6-0.1-1.5-1.7-1.7-2.1c-0.2-0.4-2-0.1-3.4-1.2c-0.6-0.4-0.9-1.3-1.1-2.1
            		c-0.3-1.2-0.3-2.5-0.6-2.5c-0.4,0-1.8,1.5-2.4,2.7c-0.6,1.2-2.3,2-3.8,1.8c-1.5-0.3-2,0.4-2.4,0.6c-0.1,0.1-0.3,0-0.4-0.1
            		c-0.3-0.3-0.7-0.8-0.8-1.3c0-0.2,0-0.4,0-0.6c0.4-0.7,0.6-2.3-0.2-2.6c-0.9-0.2-1.3,1.5-1.3,2c0,0.1-0.1,0.1-0.1,0
            		c-0.4-0.3-1.4-1.8-1.1-2.2c0.4-0.5-0.2-1.6-0.9-2.2c-0.7-0.6-2-1.7-2.1-2.4c0-0.2-0.1-0.5-0.3-0.9c-0.4-0.9-1-2.2-1-2.5
            		c0-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.5-0.4,0.9-0.5c0.2-0.1,0.3-0.1,0.5-0.1c0.5,0,0.5-0.6,0.9-0.2c0.4,0.4,1,0,1.4-0.1
            		c0.4,0,1.5,2.1,2,3.2c0.5,1,0.5,1.4,1.2,1.8c0.7,0.4,1.5,0.2,2.4,1.2c1,1,3.5,1.7,4.6,1.8c1.1,0.1,1.9-1.2,3.2-0.9
            		c1.4,0.3,1,2,1.5,2.6c0.5,0.6,6.1,1,7,1.3c0.3,0.1,1.2,0.2,2.1,0.2c0-0.2,0-0.4-0.1-0.5c0,0.1,0,0.3,0.1,0.5c1.6,0,3.5,0,3.8-0.3
            		c0.3-0.4,1.1-0.5,1.5-0.2c0.4,0.3,2.4,0.4,3.2,0c1-0.5,1.6,0,1.6,0.5c0,0.5,0.5,0.7,0.9,1.1c0.4,0.4,0.1,0.9,0.4,1.4
            		c0.3,0.5,0.9,0.4,1.8,0.8c0.1,0.1,0.2,0.1,0.3,0.2c0.6,0.5,0.9,1.3,1.8,1.7c1.2,0.4,2.2-0.7,2.2,0.1c0,0.9-2.7,0.7-2.8,1
            		c-0.1,0.3,2.8,3.7,4.3,3.5c1.5-0.3,2.5-1.8,2.1-2.1c-0.4-0.2,0.2-1.2,0.6-1c0.4,0.2,0.1,1.7,0.5,2.1c0.4,0.4,0.3,1,0,1.8
            		c-0.3,0.8,0.3,2.5,0.4,3.4c0.1,0.9,0.7,2.3,0.9,3.6c0.2,1.4,1.1,3.9,1.9,5.4c0.8,1.5,1.4,4.5,2,5.2c0.5,0.7,1.9,2.9,1.9,4.2
            		c0,1.3,1.5,3.2,2,3.6c0.5,0.4,1.6-0.4,1.9-1.2c0.3-0.8,1.8-1.2,1.9-1.9c0.1-0.7,0.2-1.3,0.8-1.4c0.5-0.1,0.5-0.7,0.5-1.4
            		c0-0.8,0.1-1.9,0.6-2.6c0.5-0.7,0.6-2.5,0.3-3.3c-0.3-0.7-0.1-1.8,0.1-2.5c0.3-0.7,0.2-1.6,0.7-1.7c0.6-0.1,1.4-0.6,2-1.4
            		c0.6-0.8,2.4-1.9,3.1-2.4c0.7-0.5,2.6-2.3,3.2-3.3c0.6-1,2.3-1.3,3.1-2.1c0.8-0.8,0.5-1.2,0.6-2.1c0.1-0.9,2.6-1.2,3.6-1.2
            		c0.2,0,0.4,0,0.5,0c0.6-0.1,0.8-0.4,1.3-0.4c0.7,0,1.5-0.1,1.6-0.8c0.1-0.7,0.8-1.2,1.2-1c0.4,0.2,1,1.9,1.1,2.7
            		c0,0.3,0.1,0.6,0.3,1c0.4,0.8,1.1,1.7,1.9,2c1.2,0.5,1.9,2.8,2.3,4.1c0.4,1.2,0.1,2.5-0.3,3.2c-0.4,0.7,0.1,0.7,0.8,1.1
            		c0.7,0.4,1.5,0.5,2-0.2c0.5-0.7,1.5-1.2,1.7-2c0.1-0.8,0.6,0.4,1,0.8c0.4,0.4,0.7,1.2,0.8,2.2c0.1,1,0.2,3.5,1,4.3
            		c0.9,0.8,1,2.6,0.8,3.2c-0.2,0.6,0.4,1.7,0.3,2.4c0,0.1-0.1,0.3-0.1,0.5c-0.2,0.9-0.4,2.2-0.6,3c-0.2,0.9-0.4,1.7,0.1,1.8
            		c0.5,0.1,1.6,1.3,2.6,2.6c0.3,0.3,0.4,0.7,0.6,1c0.5,1.1,0.5,2.2,0.6,3.1c0,1.2,1.8,3.1,2.1,3.9c0.3,0.8,0.8,1,1.9,1.8
            		c1.2,0.8,3.4,2.2,3.9,2c0.4-0.2-0.7-2.2-1.3-2.9c-0.7-0.7-0.1-2.5,0-3.9c0-1.3-1.2-1.2-1.5-2.1c-0.3-0.9-0.8-0.4-1-1
            		c0-0.1-0.1-0.2-0.2-0.3c-0.4-0.6-1.5-1.3-2.3-1.3c-1-0.1-1.5-1.6-1.4-2.3c0.1-0.7-0.7-1.3-0.7-1.9c0-0.6-0.6-0.6-1.3-1
            		c-0.7-0.3,0.1-2.3,0.2-3.2c0.1-0.9,1.5-3.3,1.1-4.1c-0.4-0.8,1.5-1.4,1.7-0.5c0.2,0.9,0.3,1.3,1.5,1.2c1.2-0.1,1.2,0.9,2.1,1.1
            		c0.1,0,0.1,0,0.1,0.1c0.8,0.3,0.5,1.3,0.5,1.8c0.1,0.6,0.8,0.2,1.2,0.2c0.4,0-0.3,0.7,0.1,1.1c0.4,0.4,1.4-0.4,1.6,0.1
            		c0,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.5,0.4,0.9,0.7c0.5,0.3-0.6,0.8-0.6,1.8c0,1,0.7,1.2,1,0.8c0.3-0.4,1.4-1.2,1.8-1.4
            		c0.4-0.1,0-0.8,0.5-0.8c0.5,0,0.3-0.7,0.5-1.1c0.2-0.4,0.5-0.2,1.2-0.2c0.7,0,1.7-0.7,2.7-1.3c1-0.7,1.2-0.8,1.6-2.7
            		c0.4-1.9-0.4-4.1-0.5-4.7c0-0.6-1.4-3.2-1.9-3.2c-0.5,0-2.8-2.3-3.2-2.7c-0.4-0.4-0.3-1-0.9-1.5c-0.6-0.5-0.7-1.8-0.5-2.7
            		c0.1-0.9,1.5-1.1,1.4-1.8c0-0.6,0.1-1.2,0.8-1.2c0.7,0,1.3-0.6,1.6-1c0-0.1,0.1-0.1,0.2-0.1c0.4-0.2,1.1,0.1,1.2-0.3
            		c0.2-0.5,0.8-0.4,1.1,0.1c0.3,0.4,0.6,0.5,1.2,0c0.6-0.5,0.9,0.5,0.5,0.8c-0.4,0.3-0.1,1.2,0.4,1.8c0.6,0.5,0.7-0.3,0.5-0.9
            		c-0.1-0.5,1-1.1,2.8-1.7c1.9-0.6,3.9-2,3.9-2.3c0-0.3,1.3,0.5,2,0.1c0.6-0.5,2.9-0.5,3.5-0.6c0.6,0,0.6-0.5,1.2-1
            		c0.6-0.5,0.5-0.7,1.2-1c0.7-0.2,1.2-0.9,1.2-1.2c0-0.4,1.3-0.5,1.4-0.9c0-0.4,1-0.5,1-1c0-0.5,0.6-0.4,0.9-0.7
            		c0.3-0.4-0.2-1.4,0.3-1.5c0.5-0.1-0.2-0.7-0.3-1c-0.1-0.3,0.9-0.3,1.4-0.7c0.5-0.3,0.8-0.8,0.9-1.4c0.1-0.6,0.9-1.1,1.3-1.2
            		c0.4-0.1,0.5-1,0.4-1.6c-0.1-0.7,0.7-0.5,0.3-0.8c-0.4-0.3,0.1-1,0.9-1.2c0.7-0.2-0.4-0.5-0.8-0.2c-0.4,0.3-0.8-0.9-1.2-0.4
            		c-0.4,0.4-1.4-0.3-0.7-0.4c0.7-0.1,1.7-0.9,2.1-1c0.4-0.1-0.7-1.1-1.2-1.1c-0.6,0-1.2-1.3-1.8-1.3s0.4-0.4,1.1-0.1
            		c0.7,0.4,1.5,0.7,1.8,0.4c0.3-0.3-1.2-1.5-1.7-1.9c-0.5-0.4,0-1-0.4-1.2c-0.3-0.2-1-1.8-1.3-2.4c-0.2-0.7-1.5-0.8-1.8-1.2
            		c-0.3-0.4,0-1.5,1-1.9c0.9-0.4,0.5-1.2,1-1.1c0.4,0.1,0.8-0.1,1.1-0.8c0.3-0.7,0.9-0.3,1.2-0.6c0.3-0.4,1.8-0.3,2.3-0.7
            		c0.5-0.4-0.5-1.5-1.2-1.3c-0.7,0.2-1.5-0.1-2-0.8c-0.5-0.7-1.7,1.3-2.7,1.8c-1,0.4-1.6-0.8-1.2-1.6c0.3-0.8-0.7-0.8-2.1-0.7
            		c-1.3,0.1-0.8-2.4,0-2.5c0.8-0.1,2,0.7,2.5-0.7c0.5-1.5,1.2-0.3,2.3-1.6c1.1-1.3,2.4-2.1,3.4-1.3c1,0.8-1,2-1.4,2.7
            		c-0.3,0.7,0.3,1-0.3,1.5c-0.6,0.6,0.2,0.7,1.4,0c1.2-0.7,2.6-1.6,3.7-2.1c0.3-0.1,0.6-0.1,1-0.1c0.9,0.1,1.9,0.6,2.3,1.1
            		c0.6,0.7-0.7,1.5-1.2,2.2c-0.5,0.7,0.3,0.7,0.4,1.2c0,0.5,0.7,1,1,0.5c0.3-0.5,0.7-0.3,1.5-0.3c0.2,0,0.4,0.1,0.5,0.3
            		c0.2,0.4,0.2,1,0.6,1.1c0.6,0.1,0.6,1-0.1,0.9c-0.7-0.1-0.7,0.9-0.1,1.8c0.6,0.9-1.1,2-0.7,2.4c0.5,0.5,0.1,1,0.3,1.3
            		c0.2,0.3,2.7-0.1,3.8-1.2c1.1-1.1,1.4-1,2-0.8c0.6,0.1,1.1-0.4,0.8-0.9c-0.3-0.4-0.6-1.5-0.5-2.9c0.1-0.8-1-2.2-2.1-3.4
            		c-0.8-0.8-1.5-1.5-1.8-1.7c-0.7-0.4-0.3-1.6,0.6-1.8c0.8-0.2,2.9-1.8,3.5-2.1c0.6-0.4,0.2-1.5,0.7-2.4c0.3-0.5,1.2-1.4,2.2-2.2
            		c0.2-0.1,0.3-0.3,0.5-0.4c0,0,0,0,0,0c0.7-0.5,1.4-0.9,1.7-0.9c0.7,0,1.5,1.3,2.3,1.3c0.8,0,4.6-2.5,4.6-2.9c0-0.4,2.2-2.5,3.3-3.6
            		c1-1.1,2.4-2.7,2.9-4c0.3-0.8,1.8-2.3,2.1-2.9c0.4-0.6,0.6-0.7,1.2-2.1c0.7-1.4,0.7-5.1,0.9-5.3c0.2-0.2,0.1-0.9,0.4-1.2
            		c0.3-0.4,0.1-0.9,0.7-1.4c0.6-0.5,0.5-1,0.3-1.3c-0.1-0.4,0-1.2-0.1-1.5c-0.1-0.3-0.1-0.4,0.2-0.6c0.4-0.2-0.2-0.7-0.7-1
            		c-0.5-0.2-1.2-0.4-1.2-1c-0.1-0.6-0.9-1.2-1.6-1.2c-0.7,0.1-1.9-0.4-1.7,0.1c0.1,0.4-0.1,0.8-0.4,0.7c-0.3-0.1-0.5,0.2-0.9,0.8
            		c-0.4,0.6-1.8,0.7-1.1,0.1c0.6-0.6-0.2-0.6-0.1-1.2c0.1-0.6,0.6-1.2-0.1-0.8c-0.7,0.4-0.7,1.3-1.2,1.4c-0.6,0.1-0.4-1.6-0.2-2.1
            		c0.2-0.4-1,0-2.3-0.1c-1.2-0.1-0.9-1,0.1-1.5c1-0.5,0.8-1,1.3-1.2c0.5-0.2,1.8-1.1,2.6-1.5c0.8-0.4,0.9-0.9,1.1-1.5
            		c0.2-0.5,1.6-1.1,2.9-2.2c1.3-1,1.9-1.6,2.2-2.3c0.3-0.6,2.2-1.4,2.2-1.9c0-0.5,2.3-1.7,3.8-2c1.5-0.3,3.9,0.1,4.4,0.5
            		c0.4,0.4,0.8,0.3,1,0c0.3-0.3,0.9-0.1,1.9,0c1,0.1,1.2-0.5,1.9-0.3c0.7,0.2,1.2,0.3,1.7-0.5c0.4-0.8,2.4-0.7,2.8-0.3
            		c0.4,0.3,0.8,0.8,1.5,0.3c0.7-0.5,0.7,0.6,1.4,0.7c0.7,0.1,0.2,0.7-0.4,0.6c-0.6-0.1-1.3,0.3-0.7,0.7c0.7,0.4,1.5-0.4,2-0.3
            		c0.5,0.1,1.4,0.1,2.1-0.4c0.7-0.5,1,0.4,1.4,0c0.5-0.4,1.5-0.5,2.1-0.5c0.5,0,0.1-0.6-0.8-0.6c-0.9,0-0.9-0.5-0.1-1.7
            		c0.7-1.2,2.1-1.9,2.9-2.6c0.9-0.7,1.5-0.4,1.5-0.9c0-0.5,0.4-1.8,1.1-1.8c0.7,0,2.3,0.3,3.3-0.3c1-0.6,0.9,0.4,1.3,0.6
            		c0.4,0.1,1-0.9,1.5-0.6c0.5,0.3-0.7,1-1,1.9c-0.3,0.9,0.6,0.5,1.1,0.8c0.5,0.2-0.7,0.6-0.5,0.8c0.1,0.2,1.1,0.2,2.4-1.1
            		c1.3-1.3,2.5-1.7,3.3-1.6c0.8,0.1,0.4-0.7,0.4-2c0.1-1.2,2.6-1.6,3.4-1.2c0.8,0.4,0.8,0.7,0,0.5c-0.8-0.2-1.5,0.5-1.5,1.5
            		c0,1-0.7,1.3-0.3,1.7c0.4,0.4-0.6,0.5-0.6,0.9c0,0.4,0,0.7-0.6,0.9c-0.6,0.2-2.6,0.4-2.6,1.2c-0.1,0.8-1.1,0.9-1.6,1.8
            		c-0.5,1-2.4,1.5-3.7,3.4c-1.3,1.9-3.3,1.9-3.3,2.3c0,0.4-1.4,0.3-1.9,0.4c-0.5,0.1,0.4,1.2-0.7,2.4c-1.1,1.2-1.9,2.9-1.8,4.6
            		c0,1.7,0.6,6.6,1.1,7.4c0.5,0.9,0.2,3,0.6,3.4s0.3,1.2,0.5,1.5c0.3,0.3,1.4-1.2,2.1-1.8c0.7-0.6,0.5-0.8,1-1.2
            		c0.5-0.3,0.3-1.7,0.4-2.1c0.1-0.4,1.1-0.5,1.4-0.9c0.3-0.4,1.1-0.1,1.5-0.3c0.4-0.1,0-1.1-0.2-1.7c-0.1-0.6,1.2-1.3,1.9-1.8
            		c0.7-0.5,1.6,0.3,2.4-0.4c0.8-0.7-0.1-1.6-0.4-2c-0.3-0.4,0.8-2.3,1.4-2.4c0.6-0.2,1,0.4,1.5-0.1c0.6-0.5-0.5-1.4-1-1.3
            		c-0.4,0.1-0.6-1.5,0.4-2.3c1-0.7,0.6-0.9-0.1-1.1c-0.7-0.2-0.8,0.1-1.3,0.1c-0.5,0-1-1-0.2-2.1c0.8-1,1.5-0.9,1.7-1.8
            		c0.2-0.9,1.2-2.1,1.5-2.5c0.3-0.4,1.3,0,1.8-0.1c0.4-0.1,0.3,0.8,0.8,0.4c0.5-0.4,1.3-1.9,2-1.9c0.7,0,0.2,1.1,0.4,1.8
            		c0.2,0.7,0.6-0.2,1.9-1.1c1.3-0.9,4.2-0.8,5-0.4c0.9,0.4,1,1.4,1.5,1.3c0.7-0.2,0.1-1,0.8-1.2c0.7-0.2,2-1,2.9-1.6
            		c1-0.7,0.7-0.2,1.2-0.8c0.5-0.6,1-0.4,1.2-0.8c0.1-0.4,1.5-1.1,3.4-1.5c1.9-0.4,4.3-1.7,4.2-2c-0.1-0.4,0.7-0.6,0.8-0.2
            		c0.1,0.4,0.8,0.2,1.8,0.4c1,0.2,1.4,0.6,2.2-0.2c0.8-0.8-0.1-1-0.1-1.6c0-0.6-1.3-1.2-1.2-1.8c0.2-0.6-1-2.3-1.4-2
            		c-0.4,0.3-1.2-0.3-1.2-0.7c0-0.5-0.1-1-0.6-0.7c-0.6,0.4-1.5,0.2-1.7-0.4c-0.2-0.5,1.1-0.9,2-0.6c0.9,0.4,0.6,0.7,1.1,1
            		c0.4,0.3,1.7,0.1,2.3-0.1c0.6-0.3,2.4-0.9,2.7-1.4c0.3-0.5,0-0.7,0.6-1c0.6-0.3,0.1-0.9-0.4-1.2c-0.5-0.3-0.4-1,0.1-1
            		c0.5,0,0.4-0.6,0.7-0.7c0.4,0,0.4,0.3,1.1-0.1c0.7-0.3,0,0.5-0.3,1.1c-0.3,0.6,0.6,1,0.7,1.4c0.1,0.3,1.4,0.4,2.1,0.1
            		c0.7-0.4,3,0.5,3.2,1c0.1,0.6,0.5,1.2,1.4,1.7c1,0.5,1.8,0.3,2,0.7c0.2,0.4,0.5,0.7,1.1,0.6c0.5-0.1,0.8,0.5,1.2,0.1
            		c0.4-0.4,0.6,0.2,1.1-0.2c0.5-0.4-1.1-0.7-0.9-1.1c0.2-0.4,0.5,0.3,1,0.2c0.4,0-0.1-0.9,0.2-1c0.3-0.1,0.1-1.9-0.3-2
            		c-0.4-0.1-0.1-0.7,0.6-0.2c0.7,0.5,1.8,0.5,2.3,0.5c0.5,0,0-0.5-0.5-0.6c-0.5-0.1,0.1-0.5,0.4-0.2c0.4,0.3,1,0.5,1.1,0.1
            		c0.1-0.4,0.4-1.1,1-1.1C766.5,108,766.7,107.7,766.1,107.4z M616.8,155.3c0.1,0,0.2,0.1,0.3,0.1C617,155.4,616.9,155.3,616.8,155.3
            		z M617.2,155.6c0-0.1-0.1-0.1-0.2-0.2C617.1,155.5,617.2,155.6,617.2,155.6z M616.8,155.3c-0.2-0.1-0.5-0.2-0.8-0.2
            		C616.3,155.1,616.5,155.2,616.8,155.3z M616,155.1c-0.1,0-0.2,0-0.3,0C615.8,155.1,615.9,155.1,616,155.1z M615.6,155.1
            		c-0.1,0-0.2,0-0.3,0.1C615.4,155.1,615.5,155.1,615.6,155.1z M597.9,235.6C597.9,235.7,597.9,235.7,597.9,235.6
            		C597.9,235.7,597.9,235.7,597.9,235.6z M597.9,236.1C597.9,236.1,597.9,236.1,597.9,236.1C597.9,236.1,597.9,236.1,597.9,236.1
            		C597.9,236.1,597.9,236.1,597.9,236.1z M597.9,236.7C597.9,236.7,597.9,236.7,597.9,236.7C597.9,236.7,597.9,236.7,597.9,236.7z
            		 M576.8,206.7C576.8,206.7,576.9,206.7,576.8,206.7C576.9,206.7,576.9,206.7,576.8,206.7z M507.2,204.4c-0.1-0.1-0.1-0.1-0.2-0.2
            		C507.1,204.3,507.1,204.4,507.2,204.4z M494.9,176.1c0.1,0.1,0.2,0.2,0.3,0.3C495.1,176.3,495,176.2,494.9,176.1z M495.7,176.6
            		c0.1,0,0.1,0,0.2,0C495.8,176.6,495.8,176.6,495.7,176.6z M501.8,165.9c0.7-1,1.5-0.2,1.8,0.1c0.3,0.2,0.1-1,0.8-1
            		c0.7,0.1-0.1,1.2,0.5,1.2c0.4,0,1.4,1.5,1.1,2.6c-0.1,0.3-0.3,0.6-0.6,0.9c-1.5,1.1-2.9,0.9-2.4-0.4c0.5-1.3,0.5-2.9-0.2-2.9
            		c-0.8,0-0.2,2.7-0.8,2.9c-0.5,0.2-0.7-2-0.3-3.1C501.7,166.1,501.8,166,501.8,165.9z M543.4,163.4c0.4,0.1-0.4,0.9-2.1,0.9
            		c-1.7,0-5.4-0.8-6.8,0.8c-1.5,1.6-0.4,2.4-1.1,2.6c-0.7,0.1-1.1-2.4-0.2-3.3c0.8-0.8,3.5-1.2,5.2-0.8
            		C540.1,164.1,543,163.3,543.4,163.4z M546.1,203.5C546,203.5,546,203.5,546.1,203.5C546,203.5,546,203.5,546.1,203.5z M546.2,203.4
            		C546.2,203.4,546.2,203.4,546.2,203.4C546.2,203.4,546.2,203.4,546.2,203.4z M543,193.7C543,193.7,543,193.7,543,193.7
            		C543,193.7,543,193.7,543,193.7z M543,193.4C543,193.4,543,193.4,543,193.4C543,193.4,543,193.4,543,193.4z M542.9,193.1
            		C542.9,193.1,542.9,193.1,542.9,193.1C542.9,193.1,542.9,193.1,542.9,193.1z M542.8,194.5c0,0.1,0,0.2,0,0.3
            		C542.8,194.7,542.8,194.6,542.8,194.5z M532.8,181.3L532.8,181.3c0,0-0.1,0-0.4,0C532.6,181.3,532.8,181.3,532.8,181.3z
            		 M526.4,179.6c0,0-0.1,0-0.1,0C526.3,179.6,526.4,179.6,526.4,179.6z M525.5,179.6C525.5,179.6,525.6,179.6,525.5,179.6
            		C525.6,179.6,525.5,179.6,525.5,179.6z M526.7,179.6c0,0,0.1,0,0.1,0C526.8,179.6,526.7,179.6,526.7,179.6z M527.2,179.5
            		C527.3,179.5,527.3,179.5,527.2,179.5C527.3,179.5,527.3,179.5,527.2,179.5z M527.6,179.4c0,0,0.1,0,0.1,0
            		C527.7,179.4,527.6,179.4,527.6,179.4z M527.9,179.3C527.9,179.3,528,179.3,527.9,179.3C528,179.3,527.9,179.3,527.9,179.3z
            		 M521.3,187.2c-0.1,0-0.1,0-0.2-0.1C521.2,187.2,521.2,187.2,521.3,187.2z M521,187.1c-0.1,0-0.1-0.1-0.2-0.1
            		C520.9,187,521,187.1,521,187.1z M521.7,187.1c0,0.1-0.1,0.1-0.2,0.2C521.6,187.2,521.7,187.2,521.7,187.1z M521.5,187.3
            		c0,0-0.1,0-0.1,0C521.4,187.3,521.5,187.3,521.5,187.3z M528.7,188C528.6,188,528.6,188,528.7,188C528.6,188,528.6,188,528.7,188z
            		 M529.2,188C529.1,188,529.1,188,529.2,188C529.1,188,529.1,188,529.2,188z M528.9,188C528.9,188,528.8,188,528.9,188
            		C528.8,188,528.9,188,528.9,188z M529.5,187.9c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0C529.4,187.9,529.5,187.9,529.5,187.9z
            		 M532.5,186.1c-0.3,0-0.9,0.5-1.4,0.9C531.6,186.6,532.1,186.1,532.5,186.1z M533.7,186.7c0,0,0.1,0,0.1,0
            		C533.7,186.7,533.7,186.7,533.7,186.7z M534,186.7c0.1,0,0.1,0,0.2,0C534.1,186.8,534,186.7,534,186.7z M545.7,174.9
            		c0,0,0-0.1,0-0.1C545.7,174.8,545.7,174.8,545.7,174.9z M545.7,174.6c0-0.1,0-0.2,0-0.4C545.7,174.3,545.7,174.5,545.7,174.6z
            		 M546.6,202.9C546.6,202.9,546.6,202.9,546.6,202.9c0,0,0-0.1,0-0.1C546.7,202.8,546.7,202.9,546.6,202.9z M525.8,176
            		C525.8,176,525.8,176,525.8,176C525.8,176,525.8,176,525.8,176C525.8,176,525.8,176,525.8,176z M525.6,176
            		C525.6,176,525.6,176,525.6,176C525.6,176,525.6,176,525.6,176z M525.4,175.9c0,0-0.1,0-0.1,0C525.3,176,525.3,176,525.4,175.9z
            		 M524.7,176.1c0.1-0.1,0.3-0.1,0.4-0.1C524.9,176,524.8,176.1,524.7,176.1z M507.3,190.7C507.2,190.7,507.2,190.7,507.3,190.7
            		C507.2,190.7,507.2,190.7,507.3,190.7z M507.1,190.9C507.1,190.9,507.1,190.9,507.1,190.9C507.1,190.9,507.1,190.9,507.1,190.9z
            		 M507,191.1C507,191.2,507,191.2,507,191.1C507,191.2,507,191.2,507,191.1z M507.6,190.5C507.7,190.5,507.7,190.5,507.6,190.5
            		C507.7,190.5,507.7,190.5,507.6,190.5z M518.2,186.1c-0.5,0.1-0.8-0.1-1.2-0.3C517.4,186,517.7,186.2,518.2,186.1z M507.6,164.6
            		c-0.2,0.3-2.6-0.3-2.8-0.8C504.6,163.3,508.1,163.9,507.6,164.6z M520.2,186.6c-0.1,0-0.1,0-0.2,0
            		C520.1,186.6,520.1,186.6,520.2,186.6z M520.4,186.8c-0.1,0-0.1-0.1-0.2-0.1C520.3,186.7,520.4,186.7,520.4,186.8z M520.7,186.9
            		c-0.1,0-0.1-0.1-0.2-0.1C520.6,186.8,520.6,186.9,520.7,186.9z M534.4,186.9c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3-0.1,0.4-0.1
            		c-0.1,0-0.2,0-0.4,0.1C534.7,187,534.6,187,534.4,186.9z M535.3,187c0,0-0.1,0-0.1,0C535.3,187,535.3,187,535.3,187z M541.2,191
            		C541.2,191,541.2,191,541.2,191C541.2,191,541.2,190.9,541.2,191C541.2,190.9,541.2,190.9,541.2,191z M541.4,191.2
            		C541.4,191.2,541.4,191.2,541.4,191.2C541.4,191.2,541.4,191.2,541.4,191.2z M545.9,203.7C545.9,203.7,545.9,203.7,545.9,203.7
            		C545.9,203.7,545.9,203.7,545.9,203.7z M545.8,203.8C545.8,203.9,545.8,203.9,545.8,203.8C545.8,203.9,545.8,203.9,545.8,203.8z
            		 M546.4,203.2C546.4,203.2,546.4,203.2,546.4,203.2C546.4,203.2,546.4,203.2,546.4,203.2z M546.6,203
            		C546.6,203,546.5,203,546.6,203C546.5,203,546.6,203,546.6,203z M558.9,156.6C558.9,156.6,558.9,156.6,558.9,156.6
            		C558.9,156.6,558.9,156.6,558.9,156.6C558.9,156.6,558.9,156.6,558.9,156.6z M561,207.6c0.1,0,0.1-0.1,0.2-0.2
            		C561.1,207.5,561.1,207.5,561,207.6z M561.8,208.4c0,0.1,0,0.3,0,0.4C561.8,208.7,561.8,208.6,561.8,208.4z M561.9,209.3
            		c-0.1-0.1-0.1-0.3-0.1-0.4C561.8,209,561.9,209.2,561.9,209.3z M567.8,208.1C567.8,208.1,567.8,208.1,567.8,208.1
            		C567.8,208.1,567.8,208.1,567.8,208.1C567.8,208.1,567.8,208.1,567.8,208.1z M567.5,208.2C567.5,208.2,567.6,208.2,567.5,208.2
            		C567.6,208.2,567.5,208.2,567.5,208.2z M567.9,208.1C568,208,568,208,567.9,208.1C568,208,568,208,567.9,208.1z M568.1,208
            		C568.2,208,568.2,208,568.1,208C568.2,208,568.2,208,568.1,208z M568.3,207.9C568.3,207.9,568.4,207.9,568.3,207.9
            		C568.4,207.9,568.3,207.9,568.3,207.9z M560.4,157.1C560.4,157.1,560.4,157,560.4,157.1C560.4,157,560.4,157.1,560.4,157.1z
            		 M560.5,207.9C560.5,207.9,560.5,207.9,560.5,207.9C560.5,207.9,560.5,207.9,560.5,207.9C560.5,207.9,560.5,207.9,560.5,207.9z
            		 M560.5,208C560.4,208.1,560.4,208.1,560.5,208C560.4,208.1,560.4,208.1,560.5,208z M561,207.6C560.9,207.6,560.9,207.7,561,207.6
            		C560.9,207.7,560.9,207.6,561,207.6z M561.9,209.3c0,0.1,0.1,0.1,0.1,0.2C562,209.5,562,209.4,561.9,209.3z M562.1,209.5
            		c0,0.1,0.1,0.1,0.1,0.1C562.1,209.6,562.1,209.6,562.1,209.5z M562.2,209.7c0,0,0.1,0.1,0.1,0.1
            		C562.3,209.7,562.2,209.7,562.2,209.7z M562.3,209.8c0,0,0.1,0,0.1,0C562.4,209.8,562.4,209.8,562.3,209.8z M576.3,206.4
            		C576.3,206.4,576.3,206.4,576.3,206.4C576.3,206.4,576.3,206.4,576.3,206.4z M594.3,237c-0.2-0.1-0.4-0.1-0.6-0.3
            		C593.9,236.9,594.1,237,594.3,237z M585.8,222.2C585.7,222.1,585.7,222.1,585.8,222.2C585.7,222.1,585.7,222.1,585.8,222.2z
            		 M583.2,223.5c-0.1,0-0.1,0.1-0.2,0.1C583.1,223.6,583.1,223.6,583.2,223.5z M583.5,223.4c-0.1,0-0.2,0.1-0.3,0.1
            		C583.3,223.5,583.4,223.4,583.5,223.4z M583.6,223.3C583.7,223.3,583.7,223.3,583.6,223.3C583.7,223.3,583.7,223.3,583.6,223.3z
            		 M583.8,223.1C583.8,223.2,583.8,223.2,583.8,223.1C583.8,223.2,583.8,223.2,583.8,223.1z M586.4,219.6c0.1,0.1,0.1,0.1,0.2,0.1
            		C586.5,219.7,586.4,219.7,586.4,219.6z M586.6,219.7c0.1,0,0.2,0,0.3-0.1C586.8,219.7,586.7,219.7,586.6,219.7z M587.3,219.5
            		c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1C587.1,219.6,587.2,219.5,587.3,219.5z M587.7,219.1c-0.1,0.1-0.2,0.2-0.3,0.3
            		C587.5,219.3,587.6,219.3,587.7,219.1z M593.6,236.8c-0.1-0.1-0.2-0.1-0.3-0.2C593.4,236.6,593.5,236.7,593.6,236.8z M594.4,237.1
            		C594.4,237.1,594.4,237.2,594.4,237.1C594.4,237.2,594.4,237.1,594.4,237.1z M594.6,148.5c0.8-1.8,3.8-2.7,5.4-5.1
            		c1.1-1.7,2-4.3,2.6-4.1c0.6,0.2-0.5,5.6-2.2,7.1c-1.7,1.5-5.1,1.9-5.1,3.1c0,1.2-4.1,1.9-4.5,1.3
            		C590.5,150.4,593.8,150.3,594.6,148.5z M559.8,157c-0.1,0-0.3,0-0.4,0C559.5,156.9,559.7,157,559.8,157z M559.2,156.8
            		C559.2,156.8,559.2,156.8,559.2,156.8C559.2,156.8,559.2,156.8,559.2,156.8z M558.6,156.3C558.6,156.3,558.6,156.2,558.6,156.3
            		C558.6,156.2,558.6,156.3,558.6,156.3z M496.3,176.5C496.3,176.5,496.3,176.5,496.3,176.5C496.3,176.5,496.3,176.5,496.3,176.5z
            		 M496.2,176.5c0,0-0.1,0-0.1,0C496.1,176.6,496.2,176.5,496.2,176.5z M496.1,176.6c0,0-0.1,0-0.1,0
            		C496,176.6,496,176.6,496.1,176.6z M495.7,176.6c-0.1,0-0.1,0-0.2,0C495.5,176.6,495.6,176.6,495.7,176.6z M495.4,176.6
            		c-0.1,0-0.1-0.1-0.2-0.1C495.3,176.5,495.4,176.5,495.4,176.6z M463,198C463,198,463,198,463,198C463,198,463,198,463,198z
            		 M462.9,198C462.8,198,462.8,198,462.9,198C462.8,198,462.8,198,462.9,198z M462.8,198c-0.2,0-0.4,0-0.7,0
            		C462.4,198,462.6,198,462.8,198z M467.8,186.3l0.8,0.2l0,0L467.8,186.3z M474.3,181.6c-0.1-0.1-0.2-0.1-0.3-0.2
            		C474.1,181.5,474.1,181.5,474.3,181.6z M473.9,181.3c0-0.1-0.1-0.1-0.1-0.2C473.8,181.2,473.9,181.3,473.9,181.3z M473.8,181
            		c0-0.1,0-0.1-0.1-0.2C473.7,180.9,473.7,181,473.8,181z M476.1,182.4c0.2,0,0.3,0,0.5,0C476.4,182.4,476.2,182.4,476.1,182.4z
            		 M474.9,177.8c0,0.1,0,0.2,0,0.2C474.9,178,474.9,177.9,474.9,177.8z M474.8,177.5c0,0.1,0.1,0.1,0.1,0.2
            		C474.8,177.7,474.8,177.6,474.8,177.5z M474.2,176.6C474.2,176.6,474.3,176.7,474.2,176.6C474.3,176.7,474.2,176.6,474.2,176.6z
            		 M473.6,180.5c0-0.1,0-0.1-0.1-0.2C473.6,180.4,473.6,180.5,473.6,180.5z M473.5,180.3c0,0-0.1-0.1-0.1-0.1
            		C473.4,180.2,473.5,180.3,473.5,180.3z M473.1,180.1c0.1,0,0.2,0,0.2,0.1C473.3,180.1,473.2,180.1,473.1,180.1c-0.1,0-0.1,0-0.2,0
            		c0,0,0,0,0,0C472.9,180.1,473,180.1,473.1,180.1z M472.5,181.1C472.5,181.1,472.5,181.1,472.5,181.1
            		C472.5,181.1,472.5,181.1,472.5,181.1z M472.6,180.9C472.6,180.9,472.6,180.9,472.6,180.9C472.6,180.9,472.6,180.9,472.6,180.9z
            		 M470.9,178.4C470.9,178.4,470.9,178.4,470.9,178.4C470.9,178.4,470.9,178.4,470.9,178.4z M475.2,204.7
            		C475.1,204.7,475.1,204.7,475.2,204.7C475.1,204.7,475.1,204.7,475.2,204.7z M474.9,204.7C474.9,204.7,474.9,204.7,474.9,204.7
            		C474.9,204.7,474.9,204.7,474.9,204.7z M475.7,204.8C475.7,204.8,475.7,204.8,475.7,204.8C475.7,204.8,475.7,204.8,475.7,204.8z
            		 M475.4,204.8C475.4,204.7,475.4,204.7,475.4,204.8C475.4,204.7,475.4,204.7,475.4,204.8z M476.7,182.3c0.2,0,0.3-0.1,0.4-0.1
            		C477,182.2,476.9,182.3,476.7,182.3z M477.3,182.1c0.5-0.2,0.9-0.5,1.3-0.8C478.2,181.6,477.8,181.9,477.3,182.1z M477.2,175.5
            		C477.2,175.5,477.2,175.5,477.2,175.5C477.2,175.5,477.2,175.5,477.2,175.5z M478.9,181.1c0.1-0.1,0.2-0.1,0.3-0.2
            		C479.1,181,479,181.1,478.9,181.1z M476.1,174.9c-0.1,0-0.1-0.1-0.2-0.1C476,174.8,476,174.8,476.1,174.9z M475.9,174.7
            		c-0.1,0-0.1,0-0.2,0C475.7,174.7,475.8,174.7,475.9,174.7z M465.7,192.8c0,0-0.1,0-0.1,0.1C465.6,192.8,465.7,192.8,465.7,192.8z
            		 M459.4,196C459.4,196,459.4,196.1,459.4,196C459.4,196.1,459.4,196,459.4,196z M459.2,196.1c0,0-0.1,0-0.1,0.1
            		C459.1,196.2,459.1,196.2,459.2,196.1z M458.9,196.2c-0.1,0-0.1,0-0.1,0C458.8,196.3,458.9,196.3,458.9,196.2z M454.9,204.3
            		c0,0-0.1,0-0.2-0.1C454.8,204.2,454.9,204.3,454.9,204.3z M454.7,197c0,0,0,0.1,0,0.1C454.7,197.1,454.7,197,454.7,197z M454.4,200
            		C454.4,200,454.4,200,454.4,200C454.4,200,454.4,200,454.4,200z M454.3,200.4C454.3,200.4,454.3,200.4,454.3,200.4
            		C454.3,200.4,454.3,200.4,454.3,200.4z M454.5,204.1c-0.1,0-0.1-0.1-0.2-0.1C454.3,204,454.4,204,454.5,204.1z M454.4,199.6
            		c0-0.1,0-0.1,0-0.2C454.4,199.5,454.4,199.6,454.4,199.6z M454.5,199.1c0-0.1,0-0.1,0-0.2C454.5,199,454.5,199,454.5,199.1z
            		 M454.5,198.5c0-0.1,0-0.2,0-0.3C454.5,198.3,454.5,198.4,454.5,198.5z M454.6,198c0-0.1,0-0.2,0-0.3
            		C454.6,197.8,454.6,197.9,454.6,198z M454.6,197.6c0-0.1,0-0.2,0-0.2C454.6,197.4,454.6,197.5,454.6,197.6z M454.7,196.9
            		c0-0.1,0-0.3,0.1-0.5C454.7,196.6,454.7,196.8,454.7,196.9z M453.6,203.7c0.1,0.1,0.2,0.1,0.3,0.1
            		C453.8,203.8,453.7,203.7,453.6,203.7z M486.6,226.9c0,0,0.1,0,0.1,0C486.7,226.8,486.6,226.9,486.6,226.9z M486.1,227
            		c0,0,0.1,0,0.1,0C486.2,227,486.2,227,486.1,227z M494.2,218.8c0.1,0,0.1,0,0.1,0C494.3,218.8,494.2,218.8,494.2,218.8z
            		 M493.9,218.8c0.1,0,0.1,0,0.2,0c-0.2,0-0.6,0-1-0.1C493.4,218.8,493.7,218.8,493.9,218.8z M493,218.7c-0.1,0-0.2,0-0.3,0
            		C492.9,218.7,493,218.7,493,218.7z M492.7,218.7c-0.1,0-0.1,0-0.2,0C492.5,218.7,492.6,218.7,492.7,218.7z M492.2,218.7
            		c-0.1,0-0.1,0-0.2,0C492.1,218.6,492.2,218.6,492.2,218.7z M491.8,218.6c0,0-0.1,0-0.1,0C491.7,218.6,491.7,218.6,491.8,218.6z
            		 M490.5,218.4C490.4,218.4,490.4,218.4,490.5,218.4C490.4,218.4,490.4,218.4,490.5,218.4z M490.1,218.4
            		C490.1,218.4,490.1,218.4,490.1,218.4C490.1,218.4,490.1,218.4,490.1,218.4z M489.8,218.3C489.8,218.3,489.8,218.3,489.8,218.3
            		C489.8,218.3,489.8,218.3,489.8,218.3z M489.6,218.3C489.6,218.3,489.5,218.3,489.6,218.3C489.5,218.3,489.6,218.3,489.6,218.3z
            		 M489.4,218.3C489.4,218.3,489.4,218.3,489.4,218.3C489.4,218.3,489.4,218.3,489.4,218.3z M490.8,225.5c-1,0.3-2.1,0.7-3,1
            		c-0.1,0-0.1,0-0.2,0.1C488.6,226.3,489.7,225.9,490.8,225.5z M494,224.4c0,0-0.1,0-0.1,0.1C493.9,224.5,493.9,224.4,494,224.4z
            		 M493.8,224.5c-0.1,0-0.1,0-0.2,0.1C493.7,224.6,493.7,224.5,493.8,224.5z M493.5,224.6c-0.1,0-0.2,0.1-0.3,0.1
            		C493.3,224.7,493.4,224.6,493.5,224.6z M493.2,224.7c-0.1,0-0.2,0.1-0.4,0.1C492.9,224.8,493,224.8,493.2,224.7z M492.7,224.9
            		c-0.2,0.1-0.3,0.1-0.5,0.2C492.4,225,492.5,225,492.7,224.9z M492.2,225.1c-0.2,0.1-0.4,0.1-0.6,0.2
            		C491.8,225.2,492,225.2,492.2,225.1z M491.5,225.3c-0.2,0.1-0.3,0.1-0.5,0.2C491.1,225.4,491.3,225.4,491.5,225.3z M487.1,226.7
            		c0.1,0,0.2,0,0.2-0.1C487.3,226.7,487.2,226.7,487.1,226.7z M492,187.1c-0.3,0.5-2.6,0.9-4.3,1c-1.7,0.1-1.8-2.2-3.7-2.2
            		c-1.3,0-2.4-1.2-2.4-2.4c0-0.4,0.1-0.9,0.4-1.2c1-1.2,1.8-3.2,1.2-4.3c-0.2-0.4-1-1.3-1.8-2.3c-1.1-1.4-2.3-2.9-2.3-3.5
            		c0.1-1,0.5-1.7-0.8-2.7c-1.2-1.1,0.1-1.8,1-2.9c0.8-1,2.5-1.5,3.6-2.2h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
            		c0.1-0.1,0.3-0.2,0.4-0.3c1-0.9,2.9-1.7,4.2-1.4c1.4,0.4,2.5-0.2,3.1,0.6c0.6,0.8,0,2.4,0.2,3c0.2,0.6-1.1,0.7-2,0.5
            		c-0.9-0.1-2.7,0.4-2.5,0.9c0.2,0.5,0.9,1.8-0.3,1.1c-1.2-0.7-1.7,0.7-1,0.7c0.7,0,1.5,0.8,1.5,1.3c0,0.5,0.1,1.7,0.7,1.5
            		c0.6-0.2,0.6,0.9,1.4,0.8c0.8-0.1,1.6,0.2,1,1c-0.7,0.8,0.4,2.9,0.7,2.4c0.2-0.3,0-0.9,0.3-1.4c0.1-0.2,0.4-0.4,0.9-0.4
            		c1.4,0.1,0.8,1.8,1.5,1.8c0.8,0.1,1.7,1.2,1,1.2c-0.7-0.1-1.3,0.7-2,0.4c-0.7-0.2-1.6-0.8-1.8,0c-0.2,0.8-0.5,1.7,0.4,1.7
            		c1-0.1,0.8,0.6,0.1,1.2c-0.7,0.7,1-0.1,1.1,0.5c0.1,0.6,0.7,0.7,0.4,1.6c-0.2,0.6,0,1.8,0,2.7C492.1,186.6,492.1,187,492,187.1z
            		 M506.5,203.7c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.3C506.8,204,506.6,203.9,506.5,203.7z M506.2,203.4
            		C506.2,203.4,506.2,203.4,506.2,203.4C506.2,203.4,506.2,203.4,506.2,203.4z M506.3,203.5C506.3,203.6,506.4,203.6,506.3,203.5
            		C506.4,203.6,506.3,203.6,506.3,203.5z M507.3,204.6c0,0,0.1,0.1,0.1,0.1C507.4,204.6,507.3,204.6,507.3,204.6z M539.2,192
            		C539.3,192,539.3,191.9,539.2,192C539.3,191.9,539.3,192,539.2,192z M539.5,191.6c0-0.1,0.1-0.2,0.1-0.2
            		C539.6,191.5,539.5,191.6,539.5,191.6z M539.4,191.8C539.4,191.8,539.4,191.8,539.4,191.8C539.4,191.8,539.4,191.8,539.4,191.8z
            		 M539.7,191.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.2S539.8,190.9,539.7,191.1z M539.6,191.3c0-0.1,0-0.1,0.1-0.2
            		C539.7,191.2,539.6,191.3,539.6,191.3z M541,190.8C541,190.8,541,190.8,541,190.8C541,190.8,541,190.8,541,190.8z M540.9,190.7
            		C540.9,190.7,540.9,190.7,540.9,190.7C540.9,190.7,540.9,190.7,540.9,190.7z M540.8,190.6C540.8,190.6,540.8,190.6,540.8,190.6
            		C540.8,190.6,540.8,190.6,540.8,190.6z M569,220.8C569,220.8,569,220.8,569,220.8C569,220.8,569,220.8,569,220.8z M568.6,221.6
            		C568.6,221.6,568.6,221.6,568.6,221.6C568.6,221.6,568.6,221.6,568.6,221.6z M568.5,221.8C568.5,221.8,568.5,221.8,568.5,221.8
            		C568.5,221.8,568.5,221.8,568.5,221.8z M569.2,220.5c0.1,0,0.1-0.1,0.2-0.1C569.3,220.4,569.3,220.4,569.2,220.5z M576.4,206.5
            		C576.5,206.5,576.5,206.5,576.4,206.5C576.5,206.5,576.5,206.5,576.4,206.5z M576.6,206.6C576.6,206.6,576.7,206.6,576.6,206.6
            		C576.7,206.6,576.6,206.6,576.6,206.6z M577.3,206.8C577.3,206.8,577.3,206.8,577.3,206.8c0.1,0,0.1,0,0.1,0
            		S577.3,206.8,577.3,206.8z M577,206.8C577,206.8,577.1,206.8,577,206.8C577.1,206.8,577,206.8,577,206.8z M577.2,206.8
            		C577.2,206.8,577.2,206.8,577.2,206.8C577.2,206.8,577.2,206.8,577.2,206.8z M583,223.7c-0.1,0-0.1,0.1-0.1,0.2
            		C582.9,223.8,582.9,223.7,583,223.7z M598,237.3C598,237.3,598,237.3,598,237.3C598,237.3,598,237.3,598,237.3z M598.2,237.9
            		c0,0,0,0.1,0.1,0.1C598.3,238,598.2,237.9,598.2,237.9z"/>
            	<path :class="asiacolor" d="M671.3,81.6c1.1-0.2-0.8-2.2-2.6-2.5c-1.8-0.3-3.6,1.2-3.3,1.5C665.9,81.4,670.3,81.9,671.3,81.6z"/>
            	<path :class="asiacolor" d="M569.8,51.7c1.1,0.5,0.7,0.8-0.5,0.8c-1.2,0-2.2,0.4-1.4,0.7c0.6,0.2,0.5,0.9,1.8,1.2c1.2,0.3,2.5-0.7,2.4-1.4
            		c-0.1-0.7,4.3-1.4,5.5-1.7c1.2-0.3-0.8-1.5,0.5-1.7c1.3-0.1,0.8-0.7-0.8-1c-1.6-0.2-1-1.6-1.5-2.1c-0.5-0.5-0.8,0.6-2.9,1
            		c-2.1,0.4-3.1,1-2.3,1.4c0.8,0.4,0,1.4-0.9,1.4C568.9,50.3,568.7,51.2,569.8,51.7z"/>
            	<path :class="asiacolor" d="M568.3,47.5c0.2-0.9-3.7-0.5-2.7,0.4C566,48.1,568.1,48.4,568.3,47.5z"/>
            	<path :class="asiacolor" d="M543.3,83c1,0.5,1.7,0.3,1.5-0.7C544.7,81.4,542.5,82.6,543.3,83z"/>
            	<path :class="asiacolor" d="M573,53.4c0,0.7-1.9,1.3-1.4,1.8c0.5,0.4,0.9,0.3,1.5,0.4c0.6,0.1,0.9,1.6,2,1.4c1.1-0.2,4,1.2,5.7,1.2
            		s1.9-1.4,1.4-1.5c-0.5-0.1-0.1-0.9,0.9-2.3c1-1.4-3-2.4-3-1.3s-1.1-0.2-1.5-0.9C578.2,51.5,573,52.7,573,53.4z"/>
            	<path :class="asiacolor" d="M582.2,61.8c0.8,0.5,3.5-0.6,5.3-0.5c1.8,0.1,6.8-1.4,7-2.3c0.2-1-1.8-0.9-2.6-1.7c-0.8-0.8-2.5-0.1-2.9,0.6
            		c-0.4,0.7-0.9,0.1,0.2-1.1c1.1-1.1-1.1-2-1.2-1.3c-0.1,0.7-2.1,0.1-2.1,0.8c0,0.7-0.8,1-0.7,1.6c0.1,0.7-1.4-0.4-1.5,1.1
            		C583.6,60.4,581.3,61.3,582.2,61.8z"/>
            	<path :class="asiacolor" d="M666.6,77.7c0-0.9-3.2,0.3-1.9,0.8C665.4,78.7,666.6,78.6,666.6,77.7z"/>
            	<path :class="asiacolor" d="M594.8,274.7c-0.1-0.8,0.3-1.7,0.3-2.1c0-0.4,0.3-0.6,0.8-0.5c0.5,0.1,0.5-1.1,0.1-1.2
            		c-0.4-0.1-0.7-1.6-1.7-1.6c-1,0-0.8,0.8-0.2,0.9c0.6,0.1,1,0.5,0.8,1c-0.2,0.4-0.4-0.4-1.2-0.4c-0.8,0.1-0.3-1-1.2-1.1
            		c-1-0.1-0.8-1.8-1.8-1.8c-1,0-1.1-0.8-0.4-1.4c0.7-0.6-1-1.1-1-1.8c0-0.7-1.1-0.3-1.1-0.9c0-0.6-0.5-0.3-1-1
            		c-0.5-0.7-0.8-0.1-1.2-0.6c-0.5-0.5-1.1-0.8-1.5-0.7c-0.4,0.2-1.4-0.8-1.7-1.7c-0.3-0.8-3-2-3.4-3c-0.4-1-1.3-1.4-2.9-1.4
            		c-1.6,0.1-2.2-1.6-2.6-0.8c-0.5,1,1.7,3.3,2.7,3.9c1,0.6,1.5,2.7,2.7,2.9c1.2,0.1,1.3,3.9,2.2,4c0.9,0.2,2.8,3.1,3.1,4.6
            		c0.3,1.5,2.3,2.3,2.9,3.4c0.7,1.1,3.3,2.7,3.6,3.2c0.4,0.5,0.7,1.5,1,1c0.3-0.5,1.5,0,2.1,0.1C594.6,277.7,594.8,275.5,594.8,274.7
            		z"/>
            	<path :class="asiacolor" d="M630.6,282.7c-1.6-0.2-2.7,1-4.3,0.4c-1.6-0.6-4,0-3.7,0.5c0.2,0.5,1.7,0.5,3.3,0.6c1.6,0.1,3-0.8,4.1-0.9
            		C631,283.2,632.2,282.9,630.6,282.7z"/>
            	<path :class="asiacolor" d="M577,263c-0.1,0.6,0.5,2.3,1.2,1.6C579,263.9,577.2,262.4,577,263z"/>
            	<path :class="asiacolor" d="M621,285.5c0.4,0.5,1.4,0.5,1.8,0.9c0.4,0.4,1.9,1.1,1.9,0.1C624.7,285.5,620.6,284.9,621,285.5z"/>
            	<path :class="asiacolor" d="M640.2,265.2c1.3,0.1,0.7-0.7,0.7-1.5c0-0.8-1.2-0.1-1.2-0.5c0-0.4-0.4-2.1-1.4-0.8c-1,1.3,0.3,4.5,1.1,4.4
            		C640.1,266.7,638.9,265.1,640.2,265.2z"/>
            	<path :class="asiacolor" d="M643.3,271.8c-1.5-0.5-4.2-0.1-3.9,0.7c0.2,0.7,1.5,0.4,2.7,0.4c1.2,0,1.9,0.9,2.6,0.9
            		C645.3,273.8,644.9,272.3,643.3,271.8z"/>
            	<path :class="asiacolor" d="M657,270.7c-0.8,0-1.8,1.6-3.3,2.1c-1.4,0.5-1.5-4.1-2.1-5c-0.7-0.9-4.2-1.6-4.6-0.6c-0.4,1-1.6,0.5-1.7,1.3
            		c-0.1,0.8,0.3,0.5,1.1,0.5s0.8,0.6,1.1,1.4c0.4,0.8,2.5,0.2,3.2,0.2c0.7-0.1,0.8,1-0.2,0.6c-1-0.4-1,0.5-1.7,0.3
            		c-0.7-0.2-1.7-0.1-1.2,0.3c0.5,0.4,1.2,0.8,1.2,1.8c0,1,1.4,0.8,1.4,0c0-0.8,0.7,0.2,2.1,0.6c1.4,0.4,0.4,0.9,1.8,1
            		c1.4,0.1,4.5,1.1,5.6,2c1.2,0.9,0.4,1.5,1.1,2.3c0.8,0.8,1,1.8-0.1,1.8c-1.1,0-2.2,1.1-1.9,1.6c0.3,0.5,3.8-0.2,4.6-0.2
            		c0.5,0,1.1,1,2.1,1.7v-12.7c-2.6-0.9-6.2-2.5-6.9-2.4C657.9,269.1,657.8,270.7,657,270.7z"/>
            	<path :class="asiacolor" d="M618,282.6c-0.2,0.5-1.2,0.7-1.8,0.2c-0.6-0.4-1.3,0.6-1,1.1c0.2,0.2,1.4,1.2,2.9,0.5c1.5-0.7,2.5-0.1,2.9-0.8
            		C621.3,282.7,618.2,282,618,282.6z"/>
            	<path :class="asiacolor" d="M612,282c-0.1-0.7-2.4-0.1-3.5-0.5c-1.1-0.5,1.2-0.9,2-1.4c0.8-0.5-0.5-0.7-2.4-0.1c-1.9,0.6-4.1-1.3-4.2-0.5
            		c-0.1,0.8-3.4-0.4-4.3-1c-0.8-0.6-4-1.4-4.7-0.2c-0.7,1.1-1.2,0.1-1,1.2c0.2,0.7,1,0.3,1.5,0.3c0.5,0,0.3,1,1.1,1.1
            		c0.8,0.1,3.3,0.8,3.6,0.3c0.2-0.5,2.6,0,3.8,1c1.2,1,3.9,1,5,0.8c1.1-0.2,2.3,0.7,2.7,0.1c0.4-0.6,1.2,1,2.4,0.1
            		C615.2,282.4,612.1,282.7,612,282z"/>
            	<path :class="asiacolor" d="M599.4,271.7c0-0.5-1.5-0.3-1.1,0.5C598.8,273,599.4,272.3,599.4,271.7z"/>
            	<path :class="asiacolor" d="M579.9,268.1c-0.1,0.3,0.5,1.5,1,1.1C581.3,268.9,580.3,267.2,579.9,268.1z"/>
            	<path :class="asiacolor" d="M635.3,272.6c0.2,1,2,1.5,2.4,0.7C638.2,272.4,635,271.5,635.3,272.6z"/>
            	<path :class="asiacolor" d="M620.7,271.1c-1.1,1.7,0.4,2,1.2,2c0.9,0.1,0.8,1.4,0.6,2.1c-0.2,0.8-0.5,2.6,0.2,2.3c1-0.4,1.5-1.5,1-2
            		c-0.5-0.4-0.5-3-0.2-3.7c0.2-0.7,1.8-0.2,1.3,0.8c-0.5,1,1,0.9,1,2c0,1.1,0.9,0.5,1.8,0.2c1-0.3,0.8-0.8,0.1-1.5
            		c-0.7-0.7,0.5-1.7-0.4-2.3c-0.9-0.6-1.5-1.5-0.7-1.7c0.8-0.2,3.3-1.6,3-2.2c-0.3-0.6-3.8,0.1-4.1,1.2c-0.3,1.1-2.8-0.1-2.5-2
            		c0.3-1.8,5.2-2.1,6.7-1.5c1.4,0.7,3-1.1,3.5-2.1c0.5-1-1.1,0.2-2.1,0.8c-1,0.7-3.7-0.1-5.1-0.5c-1.4-0.5-1.3,0.8-2.4,1
            		c-1.1,0.2-0.9,2.7-1.4,3C621.7,267.6,621.7,269.4,620.7,271.1z"/>
            	<path :class="asiacolor" d="M616.5,268.8c1.1-0.7,1.3-1.9,1.2-3.1c-0.1-1.2,2.6-1.2,3-1.7c0.4-0.5-0.6-1.2-1.4-1.6
            		c-0.8-0.4-0.1-1.1-0.7-1.6c-0.5-0.5-1.4-1.7-0.7-1.8c0.8-0.1-0.5-1.1,0.1-1.5c0.1,0,0.2-0.1,0.3-0.2c0.7-0.3,1.7-0.8,1.2-1.2
            		c-0.5-0.5,1.6-0.1,1.8-0.8c0.2-0.7-0.8-1.3-1.4-1.2c-0.5,0.1-1.8,0.1-1.8-0.5c0-0.5-1.1-1.5-1.8-1.5c-0.7,0-1.5,1.4-1.6,2
            		c-0.1,0.6-1.2,0.5-1.1,1.4c0,0.3-0.2,0.5-0.4,0.6c-0.4,0.1-1,0.1-1,0.5c0,0.5-0.9-0.2-1.4-0.2c0,0,0,0,0,0c-0.5,0-1.5,1.7-1.8,2.8
            		c-0.2,1.1-1.3,0.8-2.9,0.9c-1.6,0.1-1,1.8-1.3,2.6c-0.3,0.7-2.1-0.6-3-0.8c-0.1,0-0.2,0-0.2,0c-0.8,0.1-2.1,2.1-1.3,2.8
            		c0.9,0.8,0.7,2.1,1.1,2.8c0.4,0.7,1.7,0.8,1.7,2c0,1.2,1.1,3.2,1.9,2.7c0.8-0.5,1.4,0.2,1.6,0.8c0.2,0.5,2.1-0.4,2.7-0.3
            		c0.6,0.1,2.4,0.5,2.6,1.2c0.1,0.7,1.8-0.1,2.9-0.5C616.1,272.9,615.4,269.5,616.5,268.8z"/>
            	<path :class="asiacolor" d="M634.9,282.9c-0.8,0-1.1,0.6-1.7,1.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.2-0.7,0.3c-0.1,0-0.2,0-0.3,0
            		c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.5-1.8,1.9-1,2.3c0.8,0.4,1.9-0.7,2.5-1.4c0.1-0.1,0.2-0.2,0.3-0.3
            		c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1c1.2-0.7,4.1-1.5,4.2-1.9C638.1,282.4,636.1,283,634.9,282.9z"/>
            	<path :class="asiacolor" d="M764.9,115.9c-0.4,0.3-3,0.2-2.5,0.8c0.5,0.5,2.7,0.2,3.7,0.8c1,0.6,2.4,0.1,2.4-0.4
            		C768.6,116.6,765.3,115.6,764.9,115.9z"/>
            </g>
            <g id="Oceania" @mouseover="hover('ocean')" @mouseout="hover('ocean')" @click="select('ocean')">
            	<path :class="oceancolor" d="M718.5,312.2c0.7-0.5-5.9-4.8-5.8-4C712.8,308.8,717.8,312.7,718.5,312.2z"/>
            	<path :class="oceancolor" d="M670.3,283.5c0.5,0,0.7-0.8,1.2-1.5c0.5-0.7,2.9-0.1,4.2,1c1.4,1.1,3,4.2,4.4,3.9c1.4-0.3,2.9,0.8,4.2,0.8
            		c1.3-0.1,0.1-1.1-0.3-1.2c-0.4-0.2-1.5-0.9-1.4-1.4c0.1-0.5-1.2-0.4-2-1c-0.8-0.5-0.5-2.3-1.4-2.7s-1.5-1.7-0.5-1.7
            		c1.1,0.1,1.2-0.5,0.4-1.3c-0.8-0.8-3.9-1.1-4-2.2c-0.1-1.1-5.5-3.8-7.9-4.3c-0.4-0.1-0.9-0.2-1.5-0.4v12.7c0.6,0.4,1.2,0.7,2.1,0.7
            		C670.4,285,669.8,283.5,670.3,283.5z"/>
            	<path :class="oceancolor" d="M683.9,278.9c1.6,0,4.2-1.6,4.2-2.1c0-0.5,1-1.3,0.6-1.8c-0.4-0.5-1.3,0.2-1.3,0.8c0,0.5-1,0.8-2.4,1.4
            		c-1.5,0.6-4.2-0.7-4.3,0.2C680.6,278,682.3,278.9,683.9,278.9z"/>
            	<path :class="oceancolor" d="M688.8,273.7c1.2,1.1,0.5,2.1,1.2,1.9c1.1-0.3,0.3-1.4-0.7-2C688.4,273,687.6,272.6,688.8,273.7z"/>
            	<path :class="oceancolor" d="M704,283.3c0.5-0.3-2.8-2.1-2.8-1.5C701.2,282.3,703.6,283.6,704,283.3z"/>
            	<path :class="oceancolor" d="M693.6,277.3c-0.1,0.5,1.8,3.4,2.5,2.9C697.4,279.3,693.7,276.7,693.6,277.3z"/>
            	<path :class="oceancolor" d="M697.4,279.8c0,0.2,1.4,1.6,1.7,1.4C699.4,280.9,697.4,279.4,697.4,279.8z"/>
            	<path :class="oceancolor" d="M703.7,285.2c0.2,0.9,1.5,1.4,2.1,1.1C706.3,286,703.5,284.3,703.7,285.2z"/>
            	<path :class="oceancolor" d="M707.3,287.2c0.1,0.2,1.2,1.3,1.8,0.9C709.8,287.7,707.2,286.8,707.3,287.2z"/>
            	<path :class="oceancolor" d="M705.8,283.2c-0.4,1,1.3,2.7,1.6,2.7C707.7,286,706.1,282.5,705.8,283.2z"/>
            	<path :class="oceancolor" d="M656.3,342.2c0.5,0.4,2.1,0.3,2.4-0.3C658.9,341.3,655.8,341.8,656.3,342.2z"/>
            	<path :class="oceancolor" d="M646.2,289.4c-0.1-0.5-3.3,0.1-2.6,0.6C644.1,290.4,646.3,290,646.2,289.4z"/>
            	<path :class="oceancolor" d="M676.4,354.6c-1.2,0.1-3-1.1-3.4-1c-0.5,0.1,1.6,3.2,0.9,4c-0.6,0.8,1.8,3,2.7,3.1c0.8,0.1,0.5-0.8,1.1-0.8
            		c0.6,0,0.4-0.6,0.4-1c0.1-0.4,0.7,0.5,1.2,0.2c0.5-0.3-0.1-2.2,0.6-2.2c0.7,0,0.1-2.3-0.1-3.1C679.6,353.1,677.6,354.5,676.4,354.6
            		z"/>
            	<path :class="oceancolor" d="M690.9,324.3c0.4-0.3-0.2-1.7-0.5-1.5c-0.3,0.2-0.3-2.2-0.1-3.3c0.2-1.1,0.1-1-0.5-0.4
            		c-0.6,0.6-1.2-1.1-1.3-1.6c-0.1-0.4-0.8-1.7-1.6-1.5c-0.8,0.2-0.3-1.1-1-0.9c-0.7,0.1-0.8-1-0.7-2.1c0.1-1.1-0.5-0.2-0.8-0.7
            		c-0.3-0.4-0.7-0.1-1.2,0.1c-0.5,0.2-0.6-0.9-0.5-1.4c0.1-0.4-0.1-1-0.8-1.5c-0.7-0.5-0.7-0.9-0.7-1.5c0-0.6-3.8-2.5-4.6-2.7
            		c-0.8-0.2-0.1-1.1-0.5-1.4c-0.4-0.3-0.5-1.5-0.5-2.5c0-1-1.2-1.3-1.2-2.1c0-0.8-0.4-1.6-0.3-2.5c0.1-0.9-1.3-1.1-1.3-1.6
            		c0-0.5-0.8,0-1.6,0c-0.8,0.1-0.7-1-0.6-2c0.1-1-0.3-2.7-0.9-3.2c-0.6-0.5-0.7-2.2-1-2.5c-0.3-0.3-1.3,0.9-1.3,1.5
            		c0,0.6-0.1,1.2-0.5,1.6c-0.4,0.4,0.3,1.7-0.3,2.2c-0.5,0.5,0,2.5-0.2,3.6c-0.1,1.1-0.1,2.3-0.6,3.1c-0.5,0.8-0.6,2-1.7,2.4
            		c-1.1,0.4-2.5-0.4-2.5-1c0-0.6-1.2-0.9-1.8-0.8c-0.6,0.1-1-0.8-2-1.6c-1-0.8-1.7,0.2-2.1-0.7c-0.3-0.9-0.6-1-1.3-1.5
            		c-0.7-0.5-0.5-0.5,0.2-1s0.5-1.1,0.2-1.7c-0.4-0.6,0.4-0.4,1.3-0.9c0.9-0.5,0-1.1,0.7-1.3c0.7-0.3,0.3-0.8-0.4-1
            		c-0.7-0.1-0.5,0.7-0.8,0.8c-0.3,0.1-0.5-1.1-0.8-1.1c-0.2,0-1.1,1.2-1.5,0.6c-0.4-0.6-2.9-1.1-3.9-1.2c-1-0.1-1.3-1.4-1.9-1.2
            		c-0.6,0.2,0.1,0.6,0.8,1.3c0.7,0.7-2.2,1.2-3.1,0.9c-0.8-0.3,0.1,0.6-0.6,0.6c-0.7,0-0.8,1.2-1.4,1.8c-0.6,0.6-0.2,1.1-0.8,1.5
            		c-0.6,0.4-0.7,0.8,0.1,1.7c0.8,0.8-0.4,0.9-1,0.2c-0.7-0.7-1.3-0.7-1.3,0.1c0,0.8-0.8,0.4-0.7-0.4c0.1-0.8-0.9-0.4-1.1-0.9
            		c-0.2-0.5-1.1-1.3-1.7-1.2c-0.6,0-0.6,0.7-1.2,0.7c-0.6,0-0.7,0.3-0.6,0.9c0.1,0.6-0.9,0-1.6,0c-0.7,0.1,0,0.8-0.4,0.9
            		c-0.4,0-0.4,0.7-0.9,0.7c-0.6,0-0.5,1.3-0.4,2c0.1,0.7-0.9-0.3-1.5-0.1c-0.6,0.2,0.1,0.8,0.3,1.5c0.2,0.8-0.3,0.7-0.6,1
            		c-0.3,0.3-0.8-0.8-0.8-1.5c0-0.7-0.8,0-1.6,0.8c-0.8,0.8,0.3,2.1-0.1,2.4c-0.4,0.2-2,1.7-2.9,2.9c-0.9,1.1-2.9,0.4-3.6,1
            		c-0.6,0.5-1.3,0.1-1.9,0.6c-0.6,0.5-1.8,1.4-1.9,0.8c-0.1-0.6-1.7-0.1-2.4,0.6c-0.7,0.7-1.7,1.3-2.5,1.4c-0.8,0-0.8,1.2-1.1,1.5
            		c-0.3,0.3-0.2-0.9-0.2-1.2c0-0.3-0.7,0.5-1,1.5c-0.3,0.9,0.8,1.3,0.1,2.3c-0.7,1-1.3,2-0.4,3.2c0.9,1.2,1.5,2.1,1.2,2.7
            		c-0.3,0.6-1-1.2-1.2-1c-0.2,0.2,0.8,1,0.6,1.4c-0.3,0.4-1.2-1.5-1.4-1.1c-0.2,0.4,2,3.4,2.1,4.5c0,1.1,1.8,2.2,1.7,3.8
            		c-0.1,1.6,1.5,4.2,1.8,4.6c0.3,0.4-0.6,1.5-0.4,2.7c0.2,1.2-0.4,1.6-1.2,1.6c-0.8,0-0.3,1.2,0.5,1.3c0.8,0.1,0.5,0.7,1.5,1.3
            		c0.9,0.6,3.2,0.4,3.9,0.4c0.8,0,1-1.2,2-1.2c1,0,0.9-0.4,1.4-1c0.5-0.6,2.8-0.4,5.2-0.3c2.4,0.1,3.4-0.6,3.7-1.6
            		c0.3-0.8,2-0.9,2.7-1.5c0.7-0.6,1.2-0.7,3-0.6c1.7,0.1,3.3-0.8,4.5-1.3c1.1-0.4,3.2-0.3,4.2-0.5c1.1-0.3,1.2,1.3,2.5,1.2
            		c1.2-0.1,1.7,0.4,2.4,0.3c0.6,0,0.5,0.4,1.2,0.6c0.6,0.1,0.5,0.7,0.1,1.2c-0.4,0.6,0.9,0.5,1.8,1.7c0.8,1.2,0,1.3,0.5,2.2
            		c0.4,0.9,0.8,0.7,1.6-0.6c0.9-1.2,1.9-0.4,2-1.5c0.1-1.1,1.1-2.3,1.7-1.8c0.6,0.5-0.4,0.8-0.8,2.9c-0.4,2.1-1.1,1-1.2,1.6
            		c-0.1,0.6,1.9,0.9,1.9-0.2c0-1.1,0.4-1.6,1.1-0.6c0.8,1-0.4,1.8-0.3,2.2c0.2,0.4,1-0.1,1.5-0.1c0.8,0.1,1.9,1.8,1.7,2.8
            		c-0.2,0.9-0.4,1.8,1.1,2.6c1.5,0.8,0.9,0.9,2.1,0.9c1.1,0,3.4,0.9,4.1,1.4c0.6,0.5,2.2-0.4,2.5-1.5c0.3-1.1,1.5,0,0.8,0.3
            		c-0.6,0.4,0.3,1.2,0.4,0.6c0-0.6,0.3-1.1,0.5-0.4c0.2,0.7,1,0.7,1.3,1.2c0.3,0.5,0.7,0.9,0.7,0.5c0-0.4,0.2-0.8,0.9-1.1
            		c0.7-0.3,1.2-0.8,1.9-1.4c0.8-0.6,3.9-0.8,4.2-0.8c0.3,0,0.5-2.7,0.7-4.1c0.2-1.4,1.3-1.6,1.5-3.1c0.2-1.5,1.7-4.2,2.1-4.3
            		c0.4-0.1,1.2-0.5,1.2-1.4c0-0.8,1.1-1.8,1.1-2.8c0-1,0.6-2.2,0.5-2.5c-0.1-0.3-0.4-1.3,0.2-2C691.5,325.5,690.5,324.6,690.9,324.3z
            		"/>
            	<path :class="oceancolor" d="M739.7,347c-0.4,0.7-1.2-0.2-2-0.2c-0.8,0-1.2-0.5-1.2-1.2c0-0.7-0.2-1.6-0.9-1.6c-0.7,0,0.2,0.8,0,1.1
            		c-0.3,0.4-0.4-0.4-0.8-0.4c-0.4,0-0.8-0.5-0.8-1.4s-0.8-0.8-0.8-1.8c0-1-1.6-1.8-2.5-1.8s0.2,1.6,0.6,2.1c0.4,0.5,1.4,1,1.4,1.4
            		c0,0.4,0.7,1.4,1.2,1.6c0.5,0.2-0.3,1.7-0.3,3c0,1.2-1,1-1.6,1.8c-0.7,0.8,1.7,1.6,2.7,2.4c1,0.8-0.8,2.8-0.6,3.2
            		c0.1,0.2,0.8,0.5,1.8,0.3c1-0.3,1.5-2.5,2.2-3.1c0.7-0.6,0.3-1.6,0.6-2.1c0.4-0.5,0.8-0.4,1.3-0.4c0.5,0,0.2-1.2,0.8-1.2
            		c0.6,0,0.3-0.8,0.7-1.8C742,346,740.1,346.4,739.7,347z"/>
            	<path :class="oceancolor" d="M732.4,354.2c-0.6-0.4-0.9,0.2-1.6,0.5c-0.7,0.3-1.1-1.5-2-0.8c-0.8,0.7-0.1,0.9-1,1.7s-0.5,1.1-1.7,2.8
            		c-1.1,1.7-4.9,2.7-5.5,3.7c-0.6,0.9-2.5,1.7-2.5,2.4c0,0.7-1.2,1.2-0.5,2.2c0.5,0.8,1.2,0.2,1.8,0.8c0.6,0.6,1.2,0.1,1.4,0.6
            		c0.3,0.5,2.2,0.5,3.7-0.8c1.5-1.3,0.8-2,1.3-2.3c0.6-0.4,0.7-1.4,1-2.1c0.4-0.7,0.6-1.4,2.2-1.4c1.6,0,0.8-0.3,0.7-1.1
            		c-0.1-0.8,1.3-0.9,1.3-1.9c0-1,2-1.9,1.6-2.6C732.3,355.1,733.1,354.7,732.4,354.2z"/>
            	<path :class="oceancolor" d="M719.6,369.6c0.3,0.1,1.3-0.2,0.8-0.9C719.7,368,718.8,369.4,719.6,369.6z"/>
            	<path :class="oceancolor" d="M742.3,300.9c0.5,0.2,2.1-0.4,2.1-1.1C744.4,299.1,741.6,300.7,742.3,300.9z"/>
            	<path :class="oceancolor" d="M739.6,303.3c0.8,0.7,2.3,0.7,2.3-0.6C741.9,301.4,738.9,302.7,739.6,303.3z"/>
            </g>
            <g id="Africa">
            	<path class="st2" d="M484.7,241.3c-0.3,0.7-2.9,1.2-4.5,1.2c-1.6,0-2.6,0.2-3.2,0.7c-0.6,0.6-2.1-0.1-2.6,0.5
            		c-0.5,0.6-2.6,1.2-3.3-0.3c-0.3-0.6-0.7-0.9-1-1.1c0,0,0,0-0.1,0.1c0,0,0-0.1,0.1-0.1c-0.5-0.3-1-0.3-1.1-0.5
            		c-0.2-0.2,0.6-0.8,1.2-0.9c0.6-0.1,0.3-0.8-0.2-1.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5-0.5-1.2-1.2-2.1-2.4c-1.1-1.4-2.5-2.2-3.2-2.2
            		c-0.7,0-0.8-1.2-1.3-1.3c-0.4-0.1-1.5-1.8-1.5-2.9c0-0.6-0.5-1.5-1.1-2.2c-0.5-0.6-0.9-1-1.2-1.2c-0.7-0.3-1.2-1.6-1.2-3.1
            		c0-1.6-0.4-2.8-0.4-3.3c-0.1-0.5-0.8-1.6-1.8-2.1c-0.7-0.4-1-0.9-1.1-1.5c0-0.3,0-0.5,0-0.8c0.1-1-0.5-0.9-0.9-2.2
            		c-0.4-1.3-2.1-3.3-2.1-4.1c-0.1-0.8-1-1.2-1-2.1c0-0.8-0.4-0.9-1.4-2.2c-0.9-1.3-1.2-3.6-0.9-3.7c0.3-0.1,1,1.9,1.3,2.6
            		c0.3,0.7,1.3,2.6,2,2.6c0.7,0,1-3,1.2-2.9c0,0,0,0,0,0.1l0.1-0.5l0.3-0.8l-1.4-3.5c-0.1,0.2-0.2,0.3-0.2,0.3
            		c-0.2,0.2-1.2-0.4-2.1-0.1c-0.9,0.2-1.6-0.1-2.5-0.5c-0.9-0.4-3.8,0.1-4.2,0.7c-0.4,0.6-1.4,0.6-2.3,0.2c-0.9-0.4-2-0.9-3.7-1
            		c-1.5-0.1-2.8-0.4-3-0.7c0,0,0-0.1-0.1-0.1c-0.1-0.4-1-0.4-2.7-0.7c-1.8-0.3-1.1-1.5-3.1-1.6c-2-0.1-4.5,1.5-4.5,2.3
            		c0,0.8,0.9,1.8,0.3,2.6c-0.7,0.9-2.1,1-3.1,0c-1-0.9-2.5-1.3-3.7-1.3c-1.2,0-2.1-0.8-2-1.4c0.1-0.7-0.3-1-1.3-1.2
            		c-1-0.2-1.9-1-3.7-0.7c-1,0.2-1.8-0.1-2.3-0.5c-0.5-0.3-0.8-0.7-0.8-0.9c0-0.4-0.5-1-0.8-0.7c-0.3,0.4-1.4,0.1-1.6-0.7
            		c-0.1-0.7,0.7-0.7,1.6-1.4c0.8-0.7,1-2,0.1-2.7c-0.9-0.7-0.3-1.1,0.4-1.8c0.6-0.7,0.1-1-0.3-0.7c-0.3,0.3-0.9,0.4-1-0.3
            		c-0.1-0.7-2-0.8-2.8-0.1c-0.3,0.2-0.7,0.3-1.1,0.3c-0.8,0-1.7-0.3-1.9-0.4c-0.4-0.1-1.4,0.2-2.1,0.2c-0.7,0-2.3,1.1-2.8,0.6
            		c-0.5-0.5-6,0.4-7.6,0.4c-1.5,0.1-2.2,1.7-3.1,1.7c-0.9,0-2.4,0.8-3.2,1.6c-0.2,0.2-0.5,0.3-0.8,0.3c-0.7,0-1.6-0.5-2.3-0.5
            		c-1,0-2.6,0.5-3.4-0.4c-0.8-0.9-1.3-1.1-1.9-0.9c-0.6,0.3-1.6,3.2-2.2,4.1c-0.6,0.9-2.3,1.5-3.1,1.5c-0.8,0-0.9,1.2-1.5,1.7
            		c-0.6,0.5-0.4,1.3-1.1,1.9c-0.7,0.6-0.4,2.4,0,3.1c0.4,0.7-1.4,2.7-2.4,3.3c-1,0.6-1.3,1.7-2.9,2c-0.6,0.1-1.1,0.4-1.5,0.7
            		c-0.6,0.5-0.9,1.3-1.1,1.8c-0.4,1-2.6,2.3-2.7,3.7c-0.1,1.3-2.2,3.4-3.5,6.3c-0.4,0.8-0.5,1.4-0.6,1.9c-0.1,1.3,0.7,1.8,1.1,2.3
            		c0.6,0.7-0.5,2,0.4,3.2c0.9,1.3-0.2,3.2-0.7,4c-0.2,0.4-0.3,1.1-0.3,1.7c-0.1,0.6-0.2,1.2-0.5,1.6c-0.7,1,0.3,2.4,0.7,2.9
            		c0.1,0.1,0.1,0.2,0.1,0.4c0,0.3-0.2,0.8-0.3,1.2c0,0.3,0,0.7,0.2,0.9c0.1,0.1,0.1,0.2,0.2,0.2c0.5,0.6,0.6,1,1.6,1.2
            		c1.2,0.3-0.1,0.9,0.9,1.5c0.1,0.1,0.2,0.1,0.2,0.2c0.7,0.6,0.6,1.2,1.4,1.8c0.7,0.5,1.7,1.3,2.3,2.5c0.1,0.2,0.2,0.5,0.3,0.7
            		c0.5,1.6,0.9,2,1.6,2.5c0.3,0.3,0.9,0.6,1.6,1.1c0.8,0.5,1.8,1.1,2.6,1.7c1.5,1.1,3.7,2.6,4.7,2.9c0.1,0.1,0.3,0.1,0.5,0.1
            		c1.2,0,3.4-0.9,5.9-1.3c1.6-0.3,2.9,0,3.9,0.2c0.8,0.2,1.3,0.5,1.5,0.5c0.4,0.1,2.9-1.1,3.9-1.6c0.8-0.4,1.7-0.7,2.3-1
            		c0.2-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.7-0.4,1.1-0.4c0.5-0.1,1-0.1,1.3-0.1c0.6,0,1-0.8,1.4-0.4c0,0,0,0,0,0c0.4,0.5,2.1,0.6,3,0.8
            		c1,0.2,1.9,1.5,1.9,2.1c0,0.5,0.6,1.5,1.1,1.7c0.4,0.2,1.5-0.4,2.1-0.4c0.5,0,2.3,0,2.7-0.3c0.1-0.1,0.2-0.1,0.3,0
            		c0.3,0.2,0.5,0.7,0.8,1.1c0.4,0.6,1.2,0.1,1.5,0.3c0.3,0.2,0.7,1.6,0.6,2c-0.1,0.4-0.6,1.3-0.4,1.8c0,0,0,0,0,0.1
            		c0.3,0.4,0.1,1.1-0.5,1.3c-0.6,0.2-0.6,0.8-0.1,0.8c0.3,0,0.2,0.3,0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.3,0.3,0.6,0.6,0.8,1
            		c0.1,0.4-1.1,0.3-1.1,0.8c0,0.6-0.4,1-0.8,1.3c-0.4,0.3,1,1.4,1,1.8c0,0.4,0.3,0.7,1.5,1.8c1.1,1,0.6,1.3,2,2.5
            		c0.1,0.1,0.1,0.1,0.2,0.2c0.8,0.6,1.4,1.4,1.7,2.1c0.4,0.8,0.6,1.5,0.7,2.2c0,0.1,0,0.1,0.1,0.2c0.4,1.4,1.5,3.7,1.8,4.2
            		c0.3,0.6-0.3,1.1-0.3,2.1c0,1,1.1,2.9,1.2,4.2c0.1,1.3-0.7,2.1-1.4,2.9c-0.7,0.8-1.5,2.7-1.5,3.9c0,1.2-1.1,2.3-1,2.8
            		c0,0.5-0.1,2.1-0.3,2.7c0,0,0,0.1,0,0.1c-0.1,0.6,0.5,1.7,1.1,2.6c0.7,0.9,1.5,2.6,2,4.1c0.4,1.5,1.7,3.1,2.1,4.2
            		c0.3,1.1-0.2,2.7,0.1,3.4c0.3,0.7,0.5,1.9,0.7,3.2c0.2,1.2,0.3,3.8,1.4,4.9c0.4,0.4,0.8,1,1.2,1.6c0.7,1,1.2,2.2,1.5,3.2
            		c0.5,1.6,1.6,3.1,1.9,4c0.3,0.9,0.6,1.8,0,1.9c-0.6,0.1-0.6,0.6,0,1.6c0.7,1,0.1,1.6,0.7,1.7c0.6,0.1,0.4,0.5,0.8,0.5
            		c0.4,0,0.8,0.5,1.1,0.9c0.3,0.4,1,0.4,1.3-0.1c0.3-0.5,1.3-0.6,2.4-0.6c1,0,0.8-0.4,1.8-0.7c1-0.3,2.2-0.3,3.1,0
            		c0.9,0.3,1.5,0,1.9-0.2c0.4-0.3,1.2,0.4,1.3-0.3c0.1-0.7,0.9-0.6,1.7-0.6c0.8,0,2.6-1.2,4.1-2.6c1.5-1.4,4-4.5,4.8-5.9
            		c0.8-1.4,2.2-2.6,2.9-3.2c0.5-0.5,0.8-2.1,0.9-3.3c0-0.4,0.1-0.8,0.1-1c0.1-1-0.6-0.2-0.8-0.8c-0.2-0.6,1.5-1.7,3.1-2.3
            		c1.7-0.6,2.3-1,2.5-1.6c0.2-0.6,0-2.3,0.2-2.9c0.2-0.7-0.1-1-0.4-2.5c-0.2-1.5-0.7-1.7-1-2.6c-0.2-0.8-0.2-1.5,0.3-1.5
            		c0.5,0,0.5-0.3,0.9-1.1c0.4-0.8,0.9-0.5,1.7-1.1c0.8-0.6,0.8-1.3,2.1-2.3c1.3-1,2.7-1,3.9-1.7c1.2-0.7,3.4-3.7,3.2-4.2
            		c-0.2-0.5-0.6-2.9-0.5-3.9c0.1-1-0.2-3.6-0.2-4.8c0,0,0-0.1,0-0.1c-0.1-1.1-1.4-0.6-1.4-1.8c-0.1-1.2-0.8-1.2-0.7-2.1
            		c0-0.8,0.1-1.6-0.2-1.7c-0.3-0.1-0.1-0.8,0.4-1.5c0.5-0.7-0.9-0.8-1.1-1.7c-0.1-0.4,0.3-1.7,0.9-3.1c0.7-1.9,1.7-4,2-4.4
            		c0.4-0.7,0.9-0.6,1.3-1c0.4-0.5,0.5,0.2,1.1-0.7c0-0.1,0.1-0.2,0.2-0.2c0.8-1.1,2.9-3.1,4.5-4.7c1.7-1.8,2.6-2.2,3.5-2.7
            		c1-0.5,3.4-2.8,5-4.9c1-1.3,2.2-2.8,2.3-3.7c0.1-1,1.1-2.2,1.8-3.1c0.7-0.9,1.9-3.3,1.9-4c0-0.7,0.6-2.2,0.7-3.5
            		C486.1,240.7,485.1,240.6,484.7,241.3z M468,240.2C468,240.2,468,240.2,468,240.2C468,240.2,468,240.2,468,240.2z M467.8,240.6
            		C467.8,240.6,467.8,240.6,467.8,240.6C467.8,240.6,467.8,240.6,467.8,240.6z M467.5,241C467.5,241,467.5,241,467.5,241
            		C467.5,241,467.5,241,467.5,241z M453.5,294.2c-0.5,0.8-2-1.6-2.2-3.1c-0.2-1.5,0.8-2.2,0.2-3.6c-0.5-1.2-0.8-2.3-0.5-2.6
            		c0.1-0.1,0.2-0.1,0.3-0.1c0.7,0,1.5,2.6,1.4,4.1c0,0.3,0,0.5-0.1,0.7c-0.4,1,0.8,3.3,0.9,4.3C453.5,294,453.5,294.1,453.5,294.2z
            		 M404.4,201.9c0,0,0.1-0.1,0.1-0.1C404.5,201.9,404.5,201.9,404.4,201.9z M403.6,202.5C403.6,202.5,403.6,202.5,403.6,202.5
            		C403.6,202.5,403.6,202.5,403.6,202.5z M403.5,202.6c0,0,0,0.1-0.1,0.1C403.5,202.7,403.5,202.6,403.5,202.6z M403.7,202.4
            		C403.7,202.4,403.8,202.4,403.7,202.4C403.8,202.4,403.7,202.4,403.7,202.4z M454.6,255.8c0,0-0.1,0-0.1-0.1
            		C454.5,255.8,454.6,255.8,454.6,255.8z M455.1,256c-0.1,0-0.2-0.1-0.3-0.1C454.9,256,455,256,455.1,256z M447.1,318.9
            		c0,0,0,0.1,0,0.2C447,319,447,319,447.1,318.9z M445.6,311.5c0,0-0.1-0.1-0.1-0.1C445.5,311.5,445.5,311.5,445.6,311.5
            		C445.6,311.5,445.6,311.5,445.6,311.5z M443.9,281.7c-0.5-0.8-0.9-1.8-1.5-2.1c-1-0.5-1.4-7.3-0.9-7.4c0.1,0,0.1,0,0.2,0.2
            		c0.2,0.4,0.3,1.2,0.5,2.1c0.3,1.5,0.7,3.3,1.2,3.8c1,0.9,0.8,2,1.1,2.6c0.2,0.6,1.1,1.3,0.5,1.6
            		C444.6,282.7,444.2,282.3,443.9,281.7z M415.3,250c0-0.1-0.1-0.2-0.1-0.2C415.2,249.8,415.2,249.9,415.3,250z M430.6,244.6
            		C430.6,244.7,430.6,244.7,430.6,244.6C430.6,244.7,430.6,244.7,430.6,244.6z M430.4,244.4C430.4,244.4,430.4,244.4,430.4,244.4
            		C430.4,244.4,430.4,244.4,430.4,244.4z M430.2,244.1C430.2,244.1,430.2,244.1,430.2,244.1C430.2,244.1,430.2,244.1,430.2,244.1z
            		 M444.4,270.2C444.4,270.2,444.4,270.2,444.4,270.2C444.4,270.2,444.4,270.2,444.4,270.2z M444.6,270.1
            		C444.7,270.1,444.7,270.1,444.6,270.1C444.7,270.1,444.7,270.1,444.6,270.1z M444.1,270.2C444.1,270.2,444.1,270.2,444.1,270.2
            		C444.1,270.2,444.1,270.2,444.1,270.2C444.1,270.2,444.1,270.2,444.1,270.2z M443.4,267.9c0,0,0.1,0,0.1,0
            		C443.5,267.9,443.4,267.9,443.4,267.9z M444.8,270c0.1,0,0.1-0.1,0.1-0.2C444.9,269.9,444.9,269.9,444.8,270z M443.6,267.8
            		c0.1,0,0.1,0,0.2-0.1C443.8,267.8,443.7,267.8,443.6,267.8z M444,267.7c0.1,0,0.1,0,0.2-0.1C444.1,267.7,444,267.7,444,267.7z
            		 M416.5,260.3C416.5,260.3,416.5,260.3,416.5,260.3C416.5,260.3,416.5,260.3,416.5,260.3z M412.7,238.7
            		C412.7,238.7,412.7,238.7,412.7,238.7c0,0,0,0,0.1,0C412.8,238.7,412.8,238.7,412.7,238.7z M429.3,301.7
            		C429.3,301.7,429.3,301.7,429.3,301.7C429.3,301.7,429.3,301.7,429.3,301.7z M430.1,301.6C430.1,301.6,430.1,301.6,430.1,301.6
            		C430.1,301.6,430.1,301.6,430.1,301.6z M429.9,301.6C429.9,301.6,429.9,301.6,429.9,301.6C429.9,301.6,429.9,301.6,429.9,301.6z
            		 M431.3,301.4c0,0-0.1,0-0.1,0C431.2,301.4,431.3,301.4,431.3,301.4z M431.7,301.4c-0.1,0-0.1,0-0.2,0
            		C431.6,301.4,431.6,301.4,431.7,301.4z M432,301.3c-0.1,0-0.1,0-0.2,0C431.9,301.3,432,301.3,432,301.3z M432.4,301.3
            		c-0.1,0-0.1,0-0.2,0C432.2,301.3,432.3,301.3,432.4,301.3z M433.3,301.5C433.3,301.5,433.3,301.5,433.3,301.5
            		C433.3,301.5,433.3,301.5,433.3,301.5z M433.2,301.4C433.2,301.4,433.2,301.4,433.2,301.4C433.2,301.4,433.2,301.4,433.2,301.4z
            		 M433.1,301.4c0,0-0.1,0-0.1,0C433,301.3,433.1,301.4,433.1,301.4z M432.9,301.3c0,0-0.1,0-0.2,0
            		C432.8,301.3,432.9,301.3,432.9,301.3z M432.7,301.3c-0.1,0-0.2,0-0.3,0C432.5,301.3,432.6,301.3,432.7,301.3z M441.3,310.7
            		c0-0.3-0.1-0.8-1-0.8C441.2,309.9,441.3,310.4,441.3,310.7C441.3,310.7,441.4,310.7,441.3,310.7
            		C441.4,310.7,441.4,310.7,441.3,310.7C441.4,310.7,441.3,310.7,441.3,310.7z M443.6,295.9c0.1,0,0.2-0.1,0.3-0.1
            		C443.8,295.9,443.7,295.9,443.6,295.9z M449.8,285.1C449.8,285.1,449.8,285.2,449.8,285.1C449.8,285.2,449.8,285.1,449.8,285.1z
            		 M449.9,285.4C449.9,285.4,449.9,285.4,449.9,285.4C449.9,285.4,449.9,285.4,449.9,285.4z M450,285.6c0,0,0,0.1,0.1,0.1
            		C450.1,285.7,450.1,285.7,450,285.6z M450.3,286.2c0,0.1,0,0.1,0.1,0.2C450.3,286.3,450.3,286.3,450.3,286.2z M450.2,285.9
            		c0,0,0,0.1,0.1,0.1C450.2,286,450.2,286,450.2,285.9z M450.4,286.6c0,0,0-0.1,0-0.2C450.4,286.5,450.4,286.6,450.4,286.6z
            		 M445.2,295.4C445.2,295.4,445.1,295.4,445.2,295.4C445.1,295.4,445.2,295.4,445.2,295.4z M444.3,295.7c0,0-0.1,0-0.1,0
            		C444.3,295.7,444.3,295.7,444.3,295.7z M444.3,267.6c0.5-0.1,0.9-0.2,0.9-0.2S444.8,267.5,444.3,267.6z M445.7,257.9
            		C445.7,257.9,445.7,257.9,445.7,257.9C445.7,257.9,445.7,257.9,445.7,257.9z M445.9,257.8C446,257.8,446,257.8,445.9,257.8
            		C446,257.8,446,257.8,445.9,257.8z M446.2,257.7c0,0,0.1,0,0.1,0C446.2,257.7,446.2,257.7,446.2,257.7z M446.4,257.7
            		c0.1,0,0.1,0,0.2,0C446.5,257.6,446.4,257.7,446.4,257.7z M411.5,237.5C411.5,237.5,411.5,237.5,411.5,237.5
            		C411.5,237.5,411.5,237.5,411.5,237.5C411.5,237.5,411.5,237.5,411.5,237.5z M410.3,217.1c0.1,0,0.2,0,0.2,0.1
            		C410.5,217.2,410.4,217.2,410.3,217.1z M410.8,217.2c0.1,0,0.2,0.1,0.3,0.1C411,217.3,410.9,217.3,410.8,217.2z M409.8,217.1
            		c0.1,0,0.1,0,0.2,0C410,217.1,409.9,217.1,409.8,217.1z M409.4,217c0.1,0,0.1,0,0.2,0C409.5,217,409.5,217,409.4,217z M444.1,311.3
            		C444.1,311.3,444.1,311.3,444.1,311.3C444.1,311.3,444.1,311.3,444.1,311.3z M445.4,311.4C445.4,311.4,445.3,311.4,445.4,311.4
            		C445.3,311.4,445.4,311.4,445.4,311.4z M445.1,311.4C445,311.4,445,311.4,445.1,311.4C445,311.4,445,311.4,445.1,311.4z
            		 M450.5,286.7c0,0.1,0,0.1,0,0.2C450.5,286.9,450.5,286.8,450.5,286.7z M450,269.2c-0.6,0.3,0.8,1.1,0.1,1.2
            		c-0.7,0.1-2.2-0.6-3.3-0.2c-0.7,0.3-0.6-1.5,0-3.1c0.4-1,0.9-2,1.5-2.2c0.8-0.2,1.8-0.1,2.7,0.1c0.8,0.2,1.4,0.5,1.3,0.7
            		c-0.1,0.5-1.4,0.3-1.4,1.3c0,0.1,0,0.1,0,0.2C451,268.3,450.6,269,450,269.2z M456.4,234C456.4,234,456.4,234,456.4,234
            		C456.4,234,456.4,234,456.4,234z M456.3,234.3c0,0.1,0,0.1,0,0.2C456.3,234.5,456.3,234.4,456.3,234.3z M456.5,235.7
            		c0-0.2-0.1-0.4-0.1-0.7C456.4,235.3,456.4,235.5,456.5,235.7z M456.4,235c0-0.1,0-0.2,0-0.3C456.3,234.8,456.4,234.9,456.4,235z
            		 M411.2,217.4c0.1,0.1,0.3,0.1,0.4,0.2C411.5,217.5,411.4,217.4,411.2,217.4z M408.7,217C408.6,217,408.6,217,408.7,217
            		C408.6,217,408.6,217,408.7,217C408.6,217,408.7,217,408.7,217z M409.2,217c0,0-0.1,0-0.1,0C409.1,217,409.1,217,409.2,217z
            		 M408.8,217C408.8,217,408.8,217,408.8,217C408.8,217,408.8,217,408.8,217z M404.6,201.7c0.1-0.1,0.1-0.1,0.1-0.2
            		C404.7,201.6,404.7,201.7,404.6,201.7z M404.3,202c0,0-0.1,0.1-0.1,0.1C404.2,202.1,404.3,202,404.3,202z M385.9,243.1
            		C385.9,243.1,385.9,243.1,385.9,243.1c0.1,0,0.2,0,0.3,0C386.2,243.1,386.1,243.1,385.9,243.1z M390.3,240.2c-0.1,0-0.1,0-0.2,0
            		C390.2,240.2,390.2,240.2,390.3,240.2z M385.2,221C385.3,221,385.3,221.1,385.2,221C385.3,221.1,385.3,221,385.2,221z M385.6,221.3
            		c0.1,0.1,0.2,0.1,0.2,0.2C385.7,221.4,385.7,221.3,385.6,221.3z M386,221.6c0.1,0.1,0.1,0.1,0.2,0.2
            		C386.1,221.7,386.1,221.6,386,221.6z M368.6,244.7C368.6,244.7,368.6,244.7,368.6,244.7C368.6,244.7,368.6,244.7,368.6,244.7z
            		 M368.4,244.8C368.4,244.8,368.4,244.8,368.4,244.8C368.4,244.8,368.4,244.8,368.4,244.8z M367.4,250.6
            		C367.4,250.6,367.4,250.6,367.4,250.6C367.4,250.6,367.4,250.6,367.4,250.6z M367.5,250.4C367.5,250.4,367.5,250.4,367.5,250.4
            		C367.5,250.4,367.5,250.4,367.5,250.4C367.5,250.4,367.5,250.4,367.5,250.4z M361.3,240.2c0-0.2,0-0.4,0-0.6
            		C361.3,239.8,361.3,240.1,361.3,240.2z M359.4,235.5C359.4,235.5,359.4,235.5,359.4,235.5C359.4,235.5,359.4,235.5,359.4,235.5
            		C359.4,235.5,359.4,235.5,359.4,235.5z M361.3,239.2c0-0.1,0-0.2,0-0.2C361.3,239.1,361.3,239.2,361.3,239.2z M361.3,239.5
            		c0-0.1,0-0.1,0-0.2C361.3,239.4,361.3,239.5,361.3,239.5z M361.3,240.4c0,0.1,0,0.2,0,0.2S361.3,240.5,361.3,240.4z M359.2,235.2
            		C359.2,235.2,359.2,235.2,359.2,235.2C359.2,235.2,359.2,235.2,359.2,235.2z M359.1,235C359,235,359,234.9,359.1,235
            		C359,234.9,359,235,359.1,235z M358.8,234.7C358.8,234.7,358.8,234.7,358.8,234.7C358.8,234.7,358.8,234.7,358.8,234.7z
            		 M363.3,248.6C363.3,248.6,363.3,248.6,363.3,248.6c0,0,0-0.1-0.1-0.1C363.3,248.5,363.3,248.5,363.3,248.6z M368,249.8
            		c0,0.1-0.1,0.1-0.1,0.1C367.9,249.9,368,249.8,368,249.8z M367.3,209.2C367.3,209.2,367.4,209.2,367.3,209.2
            		C367.4,209.2,367.3,209.2,367.3,209.2z M367.5,209.3C367.6,209.3,367.6,209.3,367.5,209.3C367.6,209.3,367.6,209.3,367.5,209.3z
            		 M367.9,209.5C367.9,209.5,367.9,209.5,367.9,209.5C367.9,209.5,367.9,209.5,367.9,209.5z M368.8,210.1
            		C368.8,210.1,368.8,210.1,368.8,210.1C368.8,210.1,368.8,210.1,368.8,210.1z M369.3,210.4C369.3,210.4,369.3,210.4,369.3,210.4
            		C369.3,210.4,369.3,210.4,369.3,210.4z M369.8,210.8c0,0,0.1,0.1,0.1,0.1C369.9,210.8,369.9,210.8,369.8,210.8z M373.2,244.3
            		C373.2,244.3,373.2,244.3,373.2,244.3C373.2,244.3,373.2,244.3,373.2,244.3C373.2,244.3,373.2,244.3,373.2,244.3z M374,244.8
            		C374,244.7,374,244.7,374,244.8C374,244.7,374,244.7,374,244.8z M378.5,245.6c-0.1,0-0.1-0.1-0.2-0.2
            		C378.4,245.5,378.5,245.6,378.5,245.6z M378.6,245.6C378.6,245.6,378.7,245.6,378.6,245.6C378.7,245.6,378.7,245.6,378.6,245.6
            		C378.6,245.6,378.6,245.6,378.6,245.6z M378.8,245.6C378.8,245.5,378.8,245.5,378.8,245.6C378.8,245.5,378.8,245.5,378.8,245.6z
            		 M384.4,235c-0.1,0-0.1,0-0.2,0C384.3,235,384.4,235,384.4,235z M384.1,235c-0.1,0-0.2,0-0.3-0.1C383.9,234.9,384,235,384.1,235z
            		 M383.8,234.9c-0.1,0-0.1-0.1-0.2-0.1C383.6,234.9,383.7,234.9,383.8,234.9z M385.1,243c-0.1,0-0.1,0-0.2,0
            		C385,242.9,385,242.9,385.1,243z M385.8,243.1c-0.1,0-0.2,0-0.3,0C385.6,243.1,385.7,243.1,385.8,243.1z M389.6,240.8
            		C389.6,240.8,389.6,240.8,389.6,240.8C389.6,240.8,389.6,240.8,389.6,240.8z M389.7,240.4C389.7,240.4,389.7,240.4,389.7,240.4
            		C389.7,240.4,389.7,240.4,389.7,240.4z M389.6,240.6C389.6,240.6,389.6,240.6,389.6,240.6C389.6,240.6,389.6,240.6,389.6,240.6z
            		 M389.9,240.3C389.8,240.3,389.8,240.3,389.9,240.3C389.8,240.3,389.8,240.3,389.9,240.3z M390,240.2
            		C390,240.2,390,240.2,390,240.2C390,240.2,390,240.2,390,240.2z M391.3,241.3C391.3,241.3,391.3,241.3,391.3,241.3
            		C391.3,241.3,391.3,241.3,391.3,241.3z M391.6,241.2C391.6,241.2,391.6,241.2,391.6,241.2C391.6,241.2,391.6,241.2,391.6,241.2
            		C391.6,241.2,391.6,241.2,391.6,241.2z M391.7,241.1c0-0.1,0.1-0.1,0.1-0.2C391.8,240.9,391.8,241,391.7,241.1z M392.9,226.4
            		c0,0-0.1,0-0.1,0c0.1,0,0.3-0.1,0.4-0.1C393.1,226.3,393,226.4,392.9,226.4z M386.6,222c0.1,0,0.1,0.1,0.1,0.1
            		C386.7,222.1,386.6,222,386.6,222z M386.3,221.8c0.1,0,0.1,0.1,0.2,0.1C386.5,221.9,386.4,221.8,386.3,221.8z M356.3,240.6
            		c0.1,0,0.1-0.1,0.2-0.1C356.4,240.5,356.3,240.5,356.3,240.6z M356.7,239.8c0,0.1,0,0.1,0,0.2C356.7,239.9,356.7,239.9,356.7,239.8
            		z M356.7,240c0,0.1,0,0.1-0.1,0.2C356.6,240.1,356.7,240.1,356.7,240z M356.6,240.3c0,0-0.1,0.1-0.1,0.1
            		C356.5,240.4,356.6,240.3,356.6,240.3z M356.7,239.8c0-0.1,0-0.2,0-0.2S356.7,239.7,356.7,239.8z M363.3,249c0,0.1-0.1,0.2-0.1,0.3
            		C363.2,249.2,363.2,249.1,363.3,249z M363.3,248.9c0-0.1,0-0.1,0-0.2C363.3,248.8,363.3,248.9,363.3,248.9z M372.9,244.3
            		C372.9,244.3,372.9,244.3,372.9,244.3C372.9,244.3,372.9,244.3,372.9,244.3z M383.6,243.4C383.7,243.4,383.7,243.4,383.6,243.4
            		C383.7,243.4,383.7,243.4,383.6,243.4z M383.7,243.4C383.7,243.4,383.7,243.4,383.7,243.4C383.7,243.4,383.7,243.4,383.7,243.4z
            		 M383.8,243.4c0.1-0.1,0.2-0.6,0.7-0.6c-0.1,0-0.2,0-0.2,0C383.9,243,383.8,243.3,383.8,243.4z M443.1,310.9
            		C443.1,311,443.1,311,443.1,310.9C443.1,311,443.1,311,443.1,310.9z M443.8,311.2C443.7,311.2,443.7,311.2,443.8,311.2
            		C443.7,311.2,443.7,311.2,443.8,311.2z M443.5,311.2C443.5,311.2,443.4,311.2,443.5,311.2C443.4,311.2,443.5,311.2,443.5,311.2z"/>
            	<path class="st2" d="M482,290c-0.3-1-0.5,0.4-0.7,0.6c-0.2,0.2-0.4,1-0.3,1.4c0.1,0.5-0.7,1-1.2,0.9c-0.5-0.1-1.2,0.2-1,0.9
            		c0.2,0.7-0.4,0.5-0.1,1.1c0.3,0.5-0.7,1.2-1.5,1.2c-0.8,0.1-1.9,1.5-2.8,1.4c-0.9-0.1-1.8,0.6-2.6,0.5c-0.8-0.1-0.2,1.6-0.8,2.7
            		c-0.6,1.1-0.3,2.3,0.2,3.6c0.5,1.3,1,2.4-0.5,4.1c-1.4,1.7-1.3,3.6-0.8,4.8c0.5,1.2,0.5,3,1.3,3.8c0.8,0.8,2.9,1.5,3.6,0.9
            		c0.7-0.7,1.3,0.1,2.1-0.8c0.7-0.8,1.9-5.4,2.9-8.4c1-3,2.3-7,2.2-7.8c-0.1-0.8,0.7-1.4,0.4-2.3c-0.4-0.9,0.1-2,0.5-1.2
            		c0.4,0.7,1,0.7,1.2-0.2c0.2-1-0.7-2.4-0.8-3.8C483.4,292,482.2,290.7,482,290z"/>
            </g>
            <g id="Americas" @mouseover="hover('am')" @mouseout="hover('am')" @click="select('am')">
            	<path :class="amcolor" d="M235.7,228.5c1.2-0.4,2.1,0,2.8,0.4c0.1,0,0.1,0.1,0.2,0.1c0.7,0.4,0.9-0.4,2.5-0.9c1.6-0.5,4.2,0.4,4.2-0.8
            		c0-1.2-4.3-2.6-5.4-2.2c-0.3,0.1-0.7,0-1.1-0.1c-1.1-0.3-2.5-1-3.2-0.6c-0.9,0.6,1.3,2,1.3,2.8c0,0.8-3.6-0.2-3.9,0.7
            		C232.9,228.3,234.4,228.9,235.7,228.5z"/>
            	<path :class="amcolor" d="M311.3,276c-0.9,0-2.6-0.2-4.8-2.6c-2.2-2.4-4.4-2.2-5.9-1.8c-1.5,0.4-3-0.7-4-0.9c-1-0.2-1.9,1-2.6,1.3
            		c-0.7,0.3,0.4-1.1,0.4-1.9c0-0.8-2.7-2.1-4.7-2.9c-2.1-0.8-2.9-0.4-2.9,0.9s-0.8-0.4-1.4,1c-0.6,1.4-1.8,0.5-1,0
            		c0.8-0.4,1.5-1.5,1.7-2.3c0.3-0.8-2.9-1.5-4.1-1.1c-1.1,0.4-0.4,2.1-1.3,1.6c-0.9-0.5-0.2-1.7,0.5-1.7c0.7,0,1.5-1.4,2-2.5
            		c0.5-1.1-0.8-1.2-1.6-2.1c-0.8-0.9-0.6-3.5-1.4-3.9c0,0,0,0,0,0c-0.8-0.4-2.1-2.4-3.4-2.7c-0.6-0.1-1.1-0.4-1.7-0.6
            		c-0.7-0.3-1.4-0.5-2-0.5c-1.1,0.1-2.2,0.6-2.6,0c-0.4-0.6-1-0.5-1.5,0c-0.1,0-0.1,0.1-0.2,0.1c-0.6-0.1-1.4-1.7-1.9-1.7
            		c-0.5,0-1.2-0.4-1-1.2c0.1-0.8-1.2-0.8-2-1.7c-0.2-0.2-0.4-0.4-0.7-0.5c-1-0.4-2.3-0.3-2.9-0.3c-0.8,0,0.5-0.8,0.5-1.6
            		c0-0.8-2.1-0.9-3-1.1c-0.8-0.2-0.6-1.1,0.3-1.1c0.9,0-0.5-0.7-1.7-0.5c-1.2,0.2-2.9,0.8-3.9,1.4c-1,0.5-2.7-1.6-3.8-1.1
            		c-1.1,0.4-2.7,0.4-2.6-0.4c0-0.9-0.4-1.6-1.6-1.6c-1.1,0.1-0.8-1.1-1.7-1.1c-0.8,0,0,1-0.6,1.3c-0.6,0.3-2.6,0.8-2.7,1.6
            		c-0.1,0.8,1.1,2.1,0.7,2.9c-0.4,0.8-1.7,0.1-2.1-0.7c-0.4-0.8,1.2-2.1,0.9-2.6c-0.2-0.3-0.4-1-0.3-1.4c0.1-0.2,0.2-0.3,0.5-0.4
            		c0.9-0.2,1.3-0.8,0.5-1.2c-0.8-0.5-1.3,0.9-2.3,1.1c-0.9,0.1-1.1,0.7-1.7,1c-0.6,0.3-1.8,0-1.8,0.6c0,0.6-0.4,0-0.9-0.1
            		c-0.5-0.1-1.9,1.1-1.9,1.7c0,0.7,0.3,1.3-0.7,1.9c-1,0.6-1.6,1.8-2,2.3c-0.1,0.2-0.3,0.2-0.4,0.1c-0.4-0.2-0.8-0.8-1.3-1.6
            		c-0.7-1.1-2-1-2.8-1.3c-0.8-0.4-1.8,0.1-2.9,1.1c-1,0.9-2.3,0.4-3.2-0.1c-0.3-0.1-0.6-0.5-1-0.9c-0.7-0.8-1.6-1.8-2-2.5
            		c-0.1-0.2-0.2-0.3-0.3-0.4c-0.3-0.6,0.2-1.7,0.5-2.6c0.3-0.8-0.2-2.9,0.3-3.4c0.5-0.5,0.2-0.8,0.4-2.1c0-0.1,0-0.1,0-0.2
            		c0-1-1.3-0.7-2.2-1.6c-1-1-3.1-0.5-4.3-0.1c-1.2,0.4-2.5-0.5-3.7,0.2c0,0,0,0,0,0c-0.7,0.4-0.9,0-0.8-0.5c0.1-0.3,0.3-0.8,0.6-1
            		c0.8-0.6-0.4-3.1,0.5-3.3c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0,0c0.6-0.5,0.8-1.7,0.9-2.7c0.1-1.2-0.3-1.6,0.4-2.3
            		c0.6-0.7,1.3-0.7,1.1-1.7c-0.2-0.9-1,0-1.7-0.4c-0.7-0.4-1.3-0.1-3.2,0.1c-1.8,0.3-2.7,1-2.6,2.3c0.1,1.3-0.6,0.7-0.6,1.8
            		c0,1.1-1.4,1.2-1.3,1.8c0.1,0.7-1.2,0-1.5-0.2c-0.4-0.2-4.2,0.9-4.7,1c-0.5,0.1-1.5-1.1-2.3-1.1c-0.8,0-1.1-1.1-1.2-2.1
            		c-0.1-0.9-1.5-1.8-2.1-2.6c-0.6-0.8-0.7-2.7-0.8-3.9c0-1.2-0.2-3.3,0.7-5.6c0.1-0.3,0.2-0.6,0.2-0.8c-0.3-0.2-0.6-0.3-0.8-0.3
            		c0.2,0,0.5,0.1,0.8,0.3c0.1-1.6-1.5-1.9-0.6-2.6c1.1-0.8,0-1.6,0.5-1.9c0.5-0.4,1.5-0.7,1.5-1.2c0-0.5,0.5-0.5,1.2-0.4
            		c0.7,0.1,2.1-1.2,2-1.7c-0.1-0.5,0.1-0.6,1-0.4c0.8,0.2,0.7-1,1.2-0.8c0.5,0.2,1,0.5,1,0.1c0-0.4,0.6-0.3,1,0.2
            		c0.4,0.5,1.6,0.7,1.6,0c0.1-0.6,0.8,0,1.3,0.6c0.6,0.6,0.8,0.4,1.7,0.5c0.8,0,0.8-0.2,0.9-0.7c0-0.5,0.9,1.2,1.8,1.2
            		c0.8,0.1,0.2-0.5-0.4-1c-0.6-0.5,0.1-0.7-0.4-1.1c-0.5-0.4,0.7-0.9,1.7-0.9c1.1,0,1,0.3,1.4-0.3c0.4-0.6,0.8,0,0.8,0.5
            		c0,0.5,1.7-0.3,2.7-0.3c0.9,0,1.8,0.8,1.9,1.3c0.1,0.5,0.7,0.8,1.4,0.2c0.7-0.6,1.2-1.2,1.9-0.6c0.6,0.6,1.2,1.5,2,2.2
            		c0.8,0.8-0.4,1.7,0,2.3c0.4,0.6,0,1.4,0.8,2c0.9,0.6,0.1,1.9,0.8,2.1c0.7,0.2,1.3,1.3,1.4,1.7c0.1,0.4,1.4,1,1.5,0.3
            		c0.1-0.8,0.8-1.7,0.9-2.4c0.1-0.7-0.5-3.3-1.1-4.1c-0.6-0.8,0.1-1-0.6-1.9c-0.7-0.9-1.2-2.6-1.1-3.9c0-1.3,1.6-3.5,2.4-4.2
            		c0.8-0.7,1.6-0.3,1.8-1c0.2-0.7,1.1-1.6,1.6-1.6c0.5,0,1.1,0.1,1.2-0.4c0.1-0.6,1-1.3,2.1-1.5c1.2-0.2,0.5-0.8,0.2-1.3
            		c-0.3-0.5,0.3-1.1,0.5-0.7c0.2,0.4,0.9,0.4,1.4,0.1c0.4-0.3,1.5-1.2,0.5-1.2c-1,0-1.2-0.4-0.6-0.7c0.6-0.2,0.1-1.3-0.7-1.4
            		c-0.8-0.1-0.5-0.5,0-1c0.5-0.5-0.7-1.3-1.3-1.7c-0.6-0.4,0.4-0.6,0.8-0.7c0.4-0.1,0.1-2.1,0.3-2.6c0.2-0.5,1-0.5,0.7,0
            		c-0.3,0.5-0.8,1.3-0.2,2c0.6,0.7,0.8,1.4,0.5,2.4c-0.2,1,0.2,0.8,0.9-0.6c0.7-1.3,0.8-2.5,0.3-2.6c-0.4-0.1-0.4-1.5,0.1-0.8
            		c0.5,0.6,0.7,0.7,1.4-0.1c0.8-0.8,1.6-2.2,1.1-2.5c-0.5-0.3,0.2-0.6,1.1-0.6c0.9,0,2.8-0.5,2.9-0.8c0.4-0.9-3.1,0.4-3.1-0.1
            		c0-0.5,2.2-1,3.3-1c1,0,0.5-1.4,1-0.8c0.4,0.6,1.1,0.5,1.7,0.1c0.6-0.4,0.1-1.4-0.5-1.6c-0.7-0.2,0.5-0.6,0.3-1
            		c-0.2-0.4,0.4-2,1.3-2.2c0.8-0.2,0.4-0.7,1.2-0.7c0.7,0,0.8-0.8,1.5-1.5c0.6-0.6,1.5,1,2.5,0.1c0.4-0.4,0.9-0.8,1.5-1.1
            		c0.8-0.5,1.6-0.9,2.1-0.9c0.8,0,2-1,2.4-1.7c0.4-0.7,0.8,0.6,0.1,0.8c-0.7,0.2,0.6,0.8,1.8,0.7c1.2,0-0.6,0.4-1.5,0.4
            		c-0.9,0-1.3,0.1-2.6,1.3c-1.2,1.2-0.9,1.6-0.2,2.2c0.7,0.6,1.6,0.4,2.6-0.5c1-0.8,0.9-1.7,1.9-1.6c1,0,3.5-0.9,4.6-1.2
            		c1.1-0.4,0.4-0.5,0.4-1c0-0.5,2-0.5,2.7-1c0.7-0.5-0.1-0.9-0.8-0.8c-0.6,0.1-0.1-0.9,0-1.4c0.1-0.5-1.3,0.2-1.4,0.8
            		c0,0.6-1,0.8-1,1.2c0,1-0.1,0.5-0.8,0.8c-0.7,0.4-3.5,0-3.6-0.6c-0.1-0.6-2.1-0.9-2.2-1.8c-0.1-0.8-1.1-1.1-0.3-2
            		c0.8-0.9-0.1-1.4-0.9-0.7c-0.8,0.7-1.2-0.4-0.2-0.8c1-0.4,2.9-0.8,2-2.6c-1-1.8-7.1,0.5-8.7,1.4c-1.6,0.9-3.5,3.7-4.4,3.7
            		c-0.8,0,1.5-1.5,1.8-2.1c0.4-0.6-0.1-1.2,0.4-0.9c0.5,0.2,1.9-1.9,2.8-2.6c0.9-0.7,2,0,2.1-0.6c0.1-0.6,0.6-1,1.5-1.8
            		c0.9-0.8,11.5-0.7,12.6-0.6c1,0,3-1.3,3.6-2.2c0.6-0.9,1.3-0.9,2.8-0.8c1.4,0.1,2.1-1.2,2.8-1.7c0.7-0.5,0.4-0.7-0.2-0.8
            		c-0.6-0.1-1.2-0.5-0.6-0.6c0.6,0,0.9-0.4,1.2-1.2c0.3-0.8-0.2-0.4-1.1-1.6c-0.8-1.1-1.3,0.4-2.1,0.3c-0.8-0.1,0.6-1.2-0.1-1.6
            		c-0.7-0.4-2.1-0.1-3.1,0.8c-1.1,0.9-1,0-1.9,0.8c-0.9,0.9-0.9-0.4-0.5-0.8c0.4-0.4,0.8,0,1.2-0.5c0.4-0.5,0.8,0,1.8-0.7
            		c1-0.7,1.9-0.4,2.2-0.8c0.3-0.5-0.5-1.2-1.5-0.9c-1,0.2-1.6-1.3-2.3-0.9c-0.6,0.4-0.5-0.7-1.2-0.2c-0.7,0.4-1.1-0.1-1.4-1
            		c-0.3-0.8-2.2-2.2-3.4-2.6c-1.2-0.4-0.4-1,0.2-0.5c0.6,0.4,1.3-0.4,1.6-0.9c0.3-0.4-0.4-0.8-0.8-0.9c-0.4,0-0.4-0.6-0.3-1.1
            		c0.1-0.4-1.2-1-1.3-1.5c0-0.4-0.8-0.8-0.8-1.2c0-0.4-0.8-1-0.8-1.5c0-0.5-1.3-1.6-1.4-2.2c0-0.6-0.8-1.1-1-1.8
            		c-0.3-0.8-0.6-0.4-0.9,0.7c-0.3,1.1-1.2,1-0.9,1.4c0.5,0.7,0.1,0.8-0.3,1.2c-0.4,0.4,0.3,1.1-0.4,1.1c-0.7,0-0.4,1.4-0.7,0.8
            		c-0.4-0.6-1.2-0.4-1.2,0.2c0,0.6-1.4,1.2-2,1.2c-0.6,0-0.6-1.2-1.1-0.9c-0.5,0.4-0.4-1-1.2-1c-0.8,0-1.3,0-1.1-0.7
            		c0.2-0.7-1.3-1-0.8-1.4c0.5-0.4-0.1-1.1-0.2-2.3c-0.1-1.2,0.7-1.2,0.7-1.7c0-0.4-0.5-0.4-1.1-0.1c-0.5,0.4-0.5-0.5-1.1-0.4
            		c-0.6,0-2.3,0-2.4-0.8c-0.1-0.8-0.7-0.4-0.9-1.2c-0.3-0.8-1-0.2-1-0.7c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.7-0.6-1.4-0.7
            		c-0.7-0.1-1.6,0.5-2,0.7c-0.4,0.2-1.4-0.6-1.8-0.3c-0.4,0.3-0.5-0.2-1.6-0.5c-1.1-0.3-2.6-0.4-2.7,0c-0.1,0.4-1,0.5-0.8,1.5
            		c0.1,0.9,1.1,0.8,1.3,1.4c0.2,0.6-0.4,0.4-0.5,1c0,0.6-0.6,0.5-0.6,0.9c0,0.4,0.4,0.3,0.6,1.1c0.2,0.8,0.7,0.8,0.8,1.8
            		c0.1,1.1-0.4,0.8-0.8,1c-0.4,0.1,0,0.7-0.6,1.4c-0.6,0.7-1.2,1.5-0.7,1.9c0.5,0.4,2.3,1.2,3,2.4c0.7,1.2,0.7,3.1,0.4,4.4
            		c-0.2,1.3-1.7,1.6-2.9,2.8c-1.1,1.2-2.3,1-2.8,1c-0.5,0-0.2,1.1,0.5,2c0.8,0.8,0.1,1.1,0.5,2c0.4,0.9,0,1.6,0.6,2.4
            		c0.6,0.8,0.2,1.1-0.4,1.7c-0.7,0.6,0.3,0.3,0.3,1c0,0.8-0.3,0.3-0.9-0.1c-0.6-0.4-1.1,0.8-1.1,1.2c0,0.5-1.4-0.7-1.7-0.6
            		c-0.3,0,0.1-0.8-0.7-1.4c-0.8-0.6-1.4-0.8-1.4-1.4c0-0.6-1.4-1.1-1.4-1.8c0-0.7,0.2-2.5-0.1-3.1c-0.3-0.5,0-1.1,0.2-1.6
            		c0.3-0.5-0.3-1.2-1.1-1.2c-0.8,0-1.9-0.3-3-0.2c-1.1,0.1-1.7-0.1-2.1-0.4c-0.4-0.3-1.4-1-2.8-1.3c-1.4-0.4-2-1.1-2.1-1.5
            		c-0.1-0.4-1.5-0.7-1.8-1.1c-0.3-0.4-1.6-0.6-2.2-0.5c-0.5,0.1-1.9-1.1-2.7-1.1c-0.8,0-2.7,0.8-3,0.9c-0.3,0,0-0.7,0.3-1
            		c0.2-0.3-0.7-1.7-0.9-2.5c-0.2-0.9-0.5-1.9-1.5-1.7c-0.9,0.1-2-0.4-2.2-0.5c-0.3-0.2-0.3-2.7-0.1-3.8c0.2-1.1,1.4-2.7,1.4-3.3
            		c0-0.7,0.8-1,1.4-1.2c0.7-0.2,0.1-1.5,0.8-1.6c0.7-0.1,0.7-0.4,0.8-0.9c0.1-0.5,0.9-0.2,0.5-0.6c-0.4-0.4-0.1-0.6,0.9-0.6
            		c1,0,2.5-0.4,2.3-1.4c-0.2-1-2.9-0.3-2.9-1.1c0-0.8-3.4-0.9-3.2-1.4c0.2-0.5,3.4,0.4,4.5,0.8c1.2,0.4,1.5,0.4,2.4,0.4
            		c0.9,0,0.3-1.2,0.5-1.7c0.2-0.5,2,0.8,3,0.5c1-0.3,2-2.4,2.9-2.9c0.8-0.6,0.1-1.1-1.6-1c-1.6,0.1-2.6-0.5-3.5-1.2
            		c-0.9-0.7-0.8-1.2,0.1-1.1c0.9,0.1,3.2,1.9,4,2c0.8,0,2.3-1.6,3-2.2c0.7-0.5-0.7-0.8-1.2-1.2c-0.5-0.4,0.8-0.7,1.6-0.7
            		c0.7,0,1,0.6,1.3,0.9c0.3,0.3,1.5-0.2,2.1-0.2c0.6,0-0.1-1-1.1-1.4c-1-0.4,0-1.2,0.4-0.5c0.3,0.6,1,0.5,1.4,1.2
            		c0.5,1,0.8,0.4,1.7,0c0.9-0.3,1.3-0.8,1.8-1.4c0.5-0.5,0.6,0.1,1.2-0.8c0.6-0.8-0.3-1.7-1-2.2c-0.7-0.5,0-1.1-0.7-1.4
            		c-0.7-0.4-0.8-1,0-0.8c0.9,0.2,1.6-0.1,2-0.7c0.4-0.6-1.3-0.8-0.5-1.2c0.8-0.4,0.1-0.9-0.7-0.9c-0.8,0-1.5-0.4-1.5-1.1
            		c0-0.7-1.4-0.2-2.3-0.6c-0.8-0.4-2.7-0.3-3.2-0.3c-0.4,0-0.5,1.9,0.2,1.9c0.7,0,1.2,0.8,0.6,1c-0.6,0.2,0.1,0.9-0.4,0.9
            		c-0.4,0-1.2-0.3-1.3,0.9c-0.1,1.2-0.2,1.6-1,2c-0.8,0.4-0.2-1.3-0.9-1.2c-0.7,0-0.8,1.4-0.3,1.6c0.5,0.1,0.6,0.6,0.8,1.2
            		c0.1,0.7-1,0.2-1.4,1c-0.4,0.8-0.5-0.4-1.1-0.9c-0.6-0.5-1.4-1.4-1.3-2.1c0-0.6,0.4-0.8,0.8-0.9c0.5-0.1,0-0.5,0-1.3
            		c0-0.8-0.6-0.5-1.3-1.4c-0.7-0.8-1.5-0.8-1.9-0.4c-0.4,0.4-0.1,1.2-0.8,1.4c-0.6,0.3-0.4,1.5-0.9,1.6c-0.5,0.1-0.2-2.2-0.7-2.4
            		c-0.4-0.2-0.4-0.8,0.3-0.8c0.7-0.1,1.1-0.8,0.5-0.8c-0.6,0-1.6-0.6-2-0.8c-0.4-0.2-1.1,0.8-1.5,0.4c-0.4-0.4,0-1.2,0.1-1.7
            		c0-0.5,0.7,0.2,1.2-0.4c0.4-0.6-1.2-0.6-1.2-1.3s-1.3-0.8-1.8-1.1c-0.4-0.2,0.3-1.3,0.1-1.8c-0.2-0.4-1.9-1.9-2.6-1.8
            		c-0.7,0.1-0.9-0.9-0.5-0.9c0.4,0,0.7-0.2,1.5-1.1c0.8-0.8,0.8-1.1,0.3-1.3c-0.5-0.2-0.8-0.7,0.1-0.7c0.9,0,2.6,0.7,3.1,0.3
            		c0.5-0.4,2.2-3,2.9-4c0.7-0.9-0.5-1-2.1-0.9c-1.6,0.1-2-0.6-3.3-0.7c-1.3-0.1-3.8,0.3-4.2,0.7c-0.4,0.4,0.7,0.8,0.5,1.2
            		c-0.1,0.4-0.9-0.3-1.3-0.1c-0.4,0.2,0.1,0.8,0,2.3c-0.1,1.6,0.3,1.6,0.8,2.2c0.4,0.6,0.4,1.3,0.4,2.3c0,1-0.9,0.9-1.2,1.2
            		c-0.3,0.2,0.8,0.6,0.6,1.2c-0.2,0.6-1.1-0.4-1.6-0.2c-0.5,0.2-1,1.7-0.4,2.2c0.6,0.5,0.8,0.4,0.4,1c-0.4,0.6-0.9,1.4,0.1,2.3
            		c1.1,0.8,3,0.8,4.1,1.4c1,0.7-0.4,0.3,0,1c0.4,0.8-0.7,1-0.6,1.6c0,0.6,0.4,0.3,1-0.7c0.5-1,0.9,0.3,0.9,1.1c0,0.8-0.8,0.4-1.4,1.2
            		c-0.6,0.8-1.1,1-2,0.8c-0.8-0.2-0.4,1.2,0,2c0.4,0.8-0.1,1-1.1,0.8c-1-0.3-1.6-0.9-1.1-1.6c0.5-0.8,0.8-1.7,0.3-1.6
            		c-0.6,0.1-1.9,0-2.3-0.7c-0.4-0.6,0.3-0.4,1.5-0.2c1.2,0.1,0.2-0.8,1.1-0.8c0.9,0,1.6,0.5,2.3-0.2c0.8-0.7-0.5-2-1.1-1.7
            		c-0.6,0.2-1.6,0.5-1.6-0.1s1.1-0.2,1.6-0.3c0.6-0.1-0.3-0.9-1.2-0.7c-0.9,0.2-1.5,0.3-2.2-0.5c-0.7-0.8-2-1.2-2.3,0.5
            		c-0.3,1.7-1.6,0.8-2.1,1.4c-0.5,0.6,0.6,1,1.8,1c1.2,0,2.1,1.2,1.5,1.2c-0.6,0-0.3,0.8-0.9,0.5c-0.6-0.3-1.2,0-0.8,0.5
            		c0.4,0.5-0.2,0.8-0.1,1.3c0,0.5-0.9,0-1.9,0c-0.9-0.1-2.7,0.2-3.9,0.5c-1.2,0.3-2.6-0.2-3.2-0.8c-0.5-0.6-1.2-0.5-2.1-0.5
            		c-0.9,0-0.6-0.8-1.8-0.9c-1.2,0-0.9-1-1-1.6c-0.1-0.6-2.5-0.4-3.3,0.1c-0.8,0.5-2.5,0.3-2.9,1.3c-0.4,1,0.4,0.8,0.9,0.8
            		c0.7,0,0.6-0.8,1.9-0.6c1.2,0.2,2.1-1.3,2.7-0.8c0.5,0.5-2.1,1.6-3.3,1.7c-1.2,0.2-0.9,1.1,0.2,2.8c1.1,1.6-0.5,0.7-0.5,1.5
            		c0,0.8-2.1-0.3-1.2-0.5c0.8-0.3,0.4-1.1-0.2-1.6c-0.7-0.5-1.1,0-1-0.6c0.1-0.6-0.7,0-1.3-0.4c-0.6-0.5-0.9-0.9-1.8-0.4
            		c-0.8,0.4-1.8,0.3-3.7,0.6c-1.9,0.3-5.3,0.2-5.9-0.4c-0.6-0.6,1.6-1.7,2.3-1.6c0.6,0.2,0.3-0.7-0.8-1.5c-1-0.8-3.3-1.3-3.3-0.8
            		c0,0.5-1.3,0-2.9-0.3c-1.6-0.3-1.9-0.9-2.9-0.9c-1,0-2.7-0.4-3.9-1.3c-1.2-1-4.2-0.7-4.5,0.2c-0.4,0.9-1.3,0.8-2.3,0.8
            		c-1,0,0.4-1.1,0.1-1.3c-0.5-0.3,0-1.5-0.7-1.6c-0.7-0.1-1.4,2.9-2.7,2.9c-1.3-0.1-1.9-3.2-3-4c-1.1-0.8-2.1-0.8-1.2,0.4
            		c0.9,1.2-0.8,0.4-0.7,1.2c0.1,0.8-1.5,1.7-1.5,1.3c0-0.4-0.9-0.9-2,0.3c-1.1,1.2-1.8,1.1-1.9,0.6c-0.1-0.4-3.3,1.2-3.1,1.7
            		c0.1,0.5-0.5,0.9-1.3,0.9c-0.8,0-0.2-0.8,0.5-1.1c0.8-0.4,2.8-2.1,3.9-2.2c1.1-0.2,3.1-1,3.2-1.5c0-0.5-1-0.1-1.7-0.2
            		c-0.7-0.1-2,0.7-3.2,1.2c-1.2,0.5-2.6,0.4-2.2,1.1c0.4,0.7-1.1-0.1-1.4,0.4c-0.3,0.5-0.9,0-0.4-0.3c0.4-0.3-1.1-0.8-1.1-0.4
            		c0,0.3-0.3,0.7-1.3,0.7c-1.1,0-2.1,0.8-1.4,1.3c0.7,0.5,1.2,0.8,0.8,1.2c-0.4,0.4-1.5-0.9-3-0.8c-1.6,0.1-4.7-1.7-4.7-2.2
            		c0-0.4-2.2-0.1-3.7-0.3c-0.4,0-0.7-0.1-0.9-0.2c-1.3-0.5-3.2-1.4-4.2-1.2c-1,0.2-3.1,0.5-3.8,0.1c-0.7-0.4-1.7-0.7-3.2-0.4
            		c-1.5,0.2-2.4-0.8-4.3-1.2c-1.9-0.4-4.5,0.1-5.1,0.4c-0.6,0.2-0.5-0.7-1.3-0.6c-0.8,0.1-0.2-0.4-0.6-1c-0.4-0.7-2.7,0-3.5,0
            		c-0.8,0.1-1-0.4-1-0.8c0-0.4-0.7-0.3-1,0.3c-0.3,0.5-1.2,0.6-1.6,0.3c-0.4-0.4,0.8-0.5,0.8-0.9c0-0.4-1.2-0.6-1.6-0.8
            		c-0.4-0.1-1.2,0.4-2.1,1.3c-0.9,0.8-2.3,0.8-3.2,0.7c-0.8-0.1-1.9,0.1-1.9,0.6c0,0.5,1.1,0.4,0.4,1.1c-0.6,0.6-0.8-0.8-1.5-0.2
            		c-0.6,0.7-2.5,0.6-2.9,0.5c-0.4-0.1-1.9,1.5-2.4,2c-0.4,0.4,0.4,0.8-0.8,2.1c-1.2,1.3-4.4,1-5.1,1c-0.7,0-0.2,0.8-0.5,1.2
            		c-0.4,0.5,0.4,0.9,2.2,1.4c1.8,0.5,3.1,2.9,3.3,3.5c0.2,0.6,2.5,0,3.4,0.1c0.9,0.1-0.1,1.4,0.7,1.7c0.8,0.4,1.9-0.2,2.1,0.4
            		c0.2,0.6-1.5,0-2.4,0.7c-0.9,0.7-1.3,1-1.7,0.5c-0.4-0.5-1.8-0.2-2.6-0.1c-0.8,0.1,0-0.9,0-1.4c0-0.4-1-0.8-2.5,0.1
            		c-1.5,0.8-0.9,0.5-1.1,1.2c-0.2,0.7-0.8-0.4-1.7,0.2c-0.9,0.6-2.5,1-2.7,1.6c-0.3,0.6,2,1,2.9,1.2c0.9,0.2-1.1,0.8-0.4,1
            		c0.7,0.2,0.4,0.9,1.5,1.5c1.1,0.6,3.6,0,4.3,0c0.7,0,1.3,0.8,1.8,0.2c0.5-0.6,2.5-2.2,3.6-1.2c1,0.9-1.1,0.8-0.6,1.4
            		c0.5,0.6,1.2,2,0.2,2.7c-1,0.7-1.9,0.2-2.5,0.2c-0.5,0-0.2,1-1.1,1.6c-0.8,0.7-1.5-0.7-2.5-0.7c-1.1,0-0.8,1.4-0.8,2
            		c0,0.7-1.6-0.1-2,1.5c-0.4,1.6-2.1,0.2-1,1.8c1.1,1.6,0.9,0.6,1.8,1.2c0.9,0.6-1,1.7-0.1,1.9c0.9,0.2,1.9,1.8,2.5,2.4
            		c0.6,0.6,1.4-0.3,2.4-0.4c0.9,0,0.5-1.3,1-0.8c0.5,0.5,1.3,1.9,0.8,2.3c-0.5,0.4-0.1,1.5-0.2,2c-0.1,0.5,2.1,0.4,2.2-0.3
            		c0.1-0.6,1.8-0.8,2.8,0.2c0.9,1,1.2,1.2,1.2,0.4c0-0.8,0.8-1.7,0.8-1c0,0.7,0.8,0.8,2.2,0.2c1.4-0.6,0.8,0,0,0.9
            		c-1,1.2,0,3-0.7,3.2c-0.7,0.2-1.3,2-2.5,2.1c-1.2,0.1-3.1,2.3-3.4,2.7c-0.3,0.4-2.8-0.7-3.1,0.3c-0.4,1-1.9,1.8-1.5,2.2
            		c0.3,0.3,2.3-0.8,2.4-1.3c0.1-0.5,0.4-0.4,0.7,0c0.4,0.4,1.2-0.2,1.6-0.5c0.4-0.4,1.1-0.2,1.5-0.2c0.4,0,0.6-0.5,1.8-0.7
            		c1.2-0.2,0.9-0.6,1.2-1.2c0.3-0.6,3.1-2,3.7-2.1c0.5-0.1,0.2-1,0.8-1.1c0.6,0,1.5-0.8,2.2-1.4c0.8-0.6,0.9-0.4,1.5-0.6
            		c0.6-0.2,0.1-1.4,0.7-1.5c0.6-0.1,1-0.5,1-1c0-0.5-1.5-0.5-1.6-0.8c-0.1-0.3,1.2-1.5,1.6-1.4c0.5,0,1.3-0.5,1.3-1.2
            		c0-0.7,0.8-0.8,1.3-1.5c0.6-0.6,0.4-1.2,1-1.2c0.6,0,1.1-0.8,1.7-1.2c0.6-0.4,0,0.4,1.2,0.5c1.2,0,0.9,1.2,0,0.8
            		c-0.9-0.4-1.3-0.4-2.1,0.4c-0.8,0.8-0.2,1-0.8,1.8c-0.6,0.8-0.7,1.4,0.1,1.5c0.8,0.1,0,0.7-0.7,0.9c-0.8,0.2-0.2,0.7,0.8,0.7
            		c1,0,2.5-1.5,4-2.2c1.4-0.7,2.5,0,2.9-0.1c0.4-0.1-0.4-0.8,0-1c0.5-0.2-0.6-0.4-0.9-1.2c-0.3-0.8,0.5-0.3,0.7-1
            		c0.2-0.7,0.7-0.4,1.1-0.1c0.4,0.3,1.1-0.3,1.5,0.5c0.4,0.8,0.8-0.2,1.6,0.4c0.7,0.6-0.1,0.5-0.9,0.7c-0.8,0.2-0.4,1.2,0.2,0.7
            		c0.6-0.5,1.1-0.8,1.6-0.3c0.5,0.5,0.8,0.3,1.2-0.2c0.4-0.4,0.4,0,0.4,0.4c0,0.4,0.9,0.5,2.3,0.7c1.3,0.2,3.7-0.1,4.4-0.1
            		c0.6,0,0.4,0.9,1.8,1.2c1.4,0.3,1.7-1.4,2.5-0.8c0.7,0.6,0,0.8-0.4,1.2c-0.4,0.4,0.4,0.5,1,0.7c0.5,0.2,1.8,0.6,2.4,1.2
            		c0.6,0.6,0.9,1.2,2.4,1.9c1.4,0.7,0-1.8,1.2-1c1.1,0.8,0.7,0,1.6,0.8c0.9,0.8,0.6-0.4,0.2-1.7c-0.4-1.3,0.4-0.4,0.8,0.2
            		c0.4,0.6,0.4,1.8,0.1,2.6c-0.3,0.8-1.6,0.1-1.5-0.3c0.1-0.4-1.3-0.3-1.4,0.4c0,0.6,0.9,1.8,1.6,2c0.7,0.1,0.2,1.2,0.6,1.4
            		c0.6,0.2,0.6,1.3,0.9,1.2c0.4-0.2,0.2-1.6-0.2-2.2c-0.4-0.6-0.2-1.5,0.3-1.3c0.5,0.2,0,1.1,0.3,1.4c0.3,0.3,0.6-0.3,1.2-0.7
            		c0.6-0.4-0.3-1.4,0-1.9c0.2-0.5,0.9,0.4,1.2,1.4c0.3,0.9-0.7,0.9-0.7,1.4c0,0.5-0.8,0.3-1.1,0.5c-0.3,0.3-0.1,2.1,0.2,2.1
            		c0.4,0,0.6-1.5,0.8-0.7c0.2,0.8,1.1-1,1.3-0.3c0.2,0.7,1.3,1.5,1.7,1.3c0.4-0.2-0.2-0.9-0.8-1c-0.6,0-0.5-1.1,0-1.1
            		c0.6,0,1.6,1.5,1.6,2c0,0.5-0.5,0.5-1,1c-0.5,0.5-1.2-0.9-1.7-0.8c-0.6,0,0,0.9,0.3,1.6c0.3,0.6-0.7,1.5,0,2
            		c0.8,0.5,0.5-0.3,0.7-0.7c0.1-0.4,0.7,0.1,1.1,0.4c0.4,0.3,0.4-0.7,0.4-1.1c0-0.4,0.7-0.2,1,0.4c0.4,0.5,0.4-0.6,0.9-0.5
            		c0.5,0.1,0.2,0.9,0.2,1.4c0,0.5,0.9-0.4,0.8,0.1c0,0.1,0,0.2,0,0.2c0,0.4,0.4,0.6,0.9,1c0.5,0.4-1,0.4-0.5,0.8
            		c0.4,0.4-0.1,1,0.4,1.5c0.4,0.5,1.2,0.3,1.7-0.1c0.5-0.4,0.8,0.5,0.4,1.2c-0.4,0.7,0.4,1.2,0.8,0.7c0.4-0.5,1.2,0.8,1.6,1
            		c0.4,0.2,0.8,0.9,0.1,0.9c-0.7,0-0.6,1.2-0.1,1.2c0.5,0,0.4,0.7,0.4,1.1c0,0.4,1.6,1,2.1,0.8c0.6-0.2,1.1,0.4,1.4,0.8
            		c0.3,0.5,1.3,0.8,1.7,0.5c0.4-0.3,0.7,0.6,0.8,1.2c0.1,0.6,1,0.3,1.5,0.2c0.5,0,0.8,0.7,1.2,0.6c0.8-0.3,1,1.1,1.5,1.9
            		c0.1,0.1,0.1,0.2,0.2,0.3c0.7,0.7,0.1,1.2,0.4,2.1c0.2,0.8,0,2.1-0.6,2.1c-0.7,0-0.8-0.8-0.3-1c0.5-0.3,0.2-1.2-0.3-1.2
            		c-0.3,0-0.4-0.3-0.4-0.7c0-0.3,0.1-0.6,0.2-0.8c0.3-0.6-0.7-0.2-1.2-0.8c-0.6-0.6-2.1-0.5-2.3-1.1c-0.2-0.6-1.6-2.7-2.4-2.6
            		c-0.8,0-1.7,0.1-2.6-0.6c-0.9-0.7-1.5-0.7-2.1-0.3c-0.6,0.4,0.7,0.5,0.6,0.9c0,0.4,0.4,0.7,0.9,0.6c0.5-0.1,0.8,1,1.4,1.1
            		c0.5,0,0.7,0.9,1.3,0.9c0.6,0,0.8,0.7,1.1,1.1c0.3,0.4,1,0,1,0.4c0,0.4,0.7,0.7,0.9,1.1c0,0.1,0,0.1,0,0.2c0,0.5,0.3,1.7,1,2.7
            		c0.7,0.9,0.2,1.5,0.9,1.9c0.7,0.4,0.6,0.8,0,0.7c-0.6-0.1-0.2,0.5-0.4,1.8c-0.2,1.3-0.2,4.1-0.3,4.9c-0.1,0.8-1.1,2.2-0.5,3.1
            		c0.6,0.9,1,2.1,0.7,3.3c-0.3,1.1-0.3,1.8,0.2,2.6c0.5,0.8,0.1,2.3,0.6,2.6c0.5,0.3,1,0.9,1.5,1.7c0.5,0.8,0.9,0.3,1,1.1
            		c0.1,0.8,0.1,0.9,0.8,1.2c0.7,0.3,0.3,1.1,0.3,1.6c0,0.4,0.9,1.2,2.1,2.3c1.2,1,0.2,1.9,1,1.9c0.8,0,1.6,0.5,2.5,1
            		c0.8,0.5,1.1,0.2,1.7,0.4c0.6,0.1,1.6,1.4,1.9,2.2c0.1,0.4,0.2,0.8,0.5,1.3c0.2,0.5,0.6,1.1,1.1,1.7c0.9,1.2,1.6,3.2,1.7,3.9
            		c0.1,0.7,1.4,1.3,2.5,2c1.1,0.7,1.1,2.3,1,2.7c-0.1,0.4-1.5-0.3-1.6,0.1c-0.1,0.4,1.8,2,2.5,1.9c0.7-0.1,1.1,0.4,2.2,1.3
            		c1.1,1,1,2.1,0.6,2.5c-0.4,0.4,0.9,1.1,2.3,2.1c1.4,0.9,2,2.1,2.1,2.6c0.1,0.5,0.8,0,1-0.6c0.2-0.7-0.4-0.8-0.4-1.6
            		c0-0.8-1.2-0.6-1.7-0.7c-0.5-0.1,0-1.2-0.5-1.8c-0.5-0.5-1-1.9-1.3-2.9c-0.3-1-1.6-2-2.2-3c-0.6-1.1-1.2-1.9-1.6-2.2
            		c-0.4-0.3,0.6-0.7,0.2-1.1c-0.4-0.4-0.9-0.4-1.6-0.7c-0.7-0.3-1.1-0.8-1.1-1.6c0-0.7-0.4-2.4-0.7-3c-0.2-0.6,0.5-0.5,0.7-0.2
            		c0.2,0.3,0.4,0.5,0.7,0.4c0.3-0.2,0.8,0,1.1,0.4c0.2,0.5,1,0,1.3,0.3c0.4,0.2-0.8,0.5,0.4,2.5c1.2,2.1,0.4,0.9,0.4,2.3
            		c0,1.4,0.7,0.8,0.9,0.5c0.2-0.2,0.8,0.4,1.4,1.2c0.6,0.7,1.7,0.8,1.7,1.2c0,0.4,0.2,1.2,1,1.3c0.8,0.1,0.9,1,1.4,1.1
            		c0.5,0.1,0.6,0.6,0.3,1.2c-0.3,0.6,0,1.1,1.2,1.6c1.2,0.5,0.7,0.6,1.6,1.5c1,0.9,3.7,4,4.3,4.8c0.6,0.8,0.8,1.6,1,2.2
            		c0.2,0.6-0.4,0.9-0.1,1.5c0.4,0.5-0.7,0.3-0.6,0.7c0,0.4,0.6,2.4,1.6,2.5c1,0.1,2.1,1.3,2.9,2c0.8,0.7,2,0.5,3.2,1
            		c1.2,0.4,2.2,1.5,4,2c1.7,0.5,3.4,1.2,4.5,2c1.1,0.7,2.5,0.4,4.1-0.2c1.6-0.6,2.8,0.2,3.7,0.5c0.4,0.2,1.3,0.9,2.2,1.7c0,0,0,0,0,0
            		c0.9,0.8,1.8,1.6,2.2,1.9c0.8,0.5,2.2,0,2.8,0.5c0,0,0,0,0.1,0c0.7,0.5,3.2,1.3,3.9,1.3c0.3,0,0.5-0.1,0.6-0.3
            		c0.2-0.1,0.3-0.2,0.6,0c0.3,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.6,0.3,0.8c0.7,0.4,1.7,2,2.5,2.4c0.4,0.2,0.6,0.5,0.7,0.8
            		c0.1,0.3,0.1,0.6-0.2,0.8c-0.4,0.3-0.4,1.3,0.5,1.7c1,0.4,0.7-0.2,1.2-0.2c0.5,0,0.4,0.8,0.9,0.8c0.4,0,1.5,0.5,1.4,1.2
            		c0,0.7,0.4,1,1.2,1c0.1,0,0.3,0,0.5,0c0.9,0.1,2,0.5,2.6,1.2c0.7,0.8,1.1,0.3,1.5,1c0.4,0.7,1.3,0,1.6-0.4c0.2-0.4-0.5-0.6-0.6-1.1
            		c-0.1-0.5,0.9-0.3,1.1-1c0.2-0.7,1.6-1,2.2-0.7c0.6,0.3,1.2,0.7,0.6,1.4c-0.4,0.5,0.4,1.4,1.2,2.1c0.3,0.2,0.5,0.5,0.6,0.6
            		c0.6,0.7,0.2,1.2,0.4,1.6c0.2,0.4,0.4,0.9,0,1.1c-0.4,0.2,0,2.7,0.4,3.1c0.4,0.4,0,0.8-0.4,1.7c-0.4,0.9-0.8,1.3-1.6,1.3
            		c-0.8,0-0.8,1.3-1,1.3c-0.2,0-0.4,0.5-0.4,0.8c0,0.1,0,0.2,0,0.3c0,0.4-0.6,0.7-1.4,0.7c-0.8,0-0.7,0.9-0.7,1.5
            		c0,0.6-0.6,0.6-0.9,1.2c-0.3,0.6,0.1,0.8-0.5,1.1c-0.6,0.3-0.8,0.8-0.4,1.4c0.4,0.5,0,1-0.1,1.3c-0.1,0.3,0.5,0.4,1.2,1
            		c0.6,0.6,0.7-0.2,1,0.2c0.3,0.4-0.2,1.2-0.9,1.2c-0.1,0-0.1,0-0.2,0c-0.7,0.1-1.3,1.1-1.7,1.8c-0.4,0.8,0.6,2.2,0.8,2.8
            		c0.1,0.5-0.5,0-0.6,0.5c-0.1,0.5,1.5,1.2,2.2,1.7c0.7,0.5,1.3,2.1,2,2.8s0.7,1.3,1.4,3.1c0.7,1.7,2.1,4.7,3.1,5.9
            		c1,1.2,1.4,2.2,1.2,2.6c-0.2,0.4,0,1.3,0.8,1.8c0.7,0.5,1.3,1.3,3.2,2.2c1.9,0.9,5.3,2.7,5.8,3.6c0.5,0.8,2.1,1.6,2.4,1.8
            		c0.1,0,0.1,0.2,0.1,0.4c0,0.5-0.2,1.3,0,1.8c0.3,0.7,0.4,3.9,0.1,5.2c-0.3,1.2-0.5,2.3-0.8,3c-0.3,0.8,0.6,0.8,0.3,1.2
            		c-0.4,0.4-0.2,1.2-0.2,2.7c0,1.4-0.8,3.8-0.9,4.6c0,0.8-0.6,1.2-0.5,2.1c0.1,0.8-0.4,1.3-0.9,1.7c-0.5,0.4,0.8,1.7,0.8,2.4
            		c0,0.7-1,1-0.8,2.2c0.3,1.4,0,3.9,0,4.4c0,0.5-0.6,0.4-0.4,1.4c0.1,0.9-0.6,1.2-0.7,2.5c-0.1,1.3-1.2,3.3-1.6,4.3
            		c-0.4,1-0.4,1.6-0.9,1.6c-0.5,0-0.7,0.6-0.3,1.5c0.4,0.9-0.3,1.6,0.3,2.5c0.5,0.9,0.4,1.2,0.1,2.1c-0.3,0.8-0.6,0.9-0.6,1.7
            		c0,0.8-0.6,1.5-0.3,2.4c0.4,0.9,0.3,1.2-0.2,1.2c-0.4,0-0.7,0.7-0.8,2.2c-0.2,1.5-0.1,1.4,0.9,1.3c1.1-0.1,0.2-3.6,0.9-3.6
            		c0.7,0,0.1-0.6,0.8-0.8c0.7-0.2,0.1,0.6,0.5,0.8c0.4,0.1,0.8,0.5,0.1,0.9c-0.6,0.4,0.1,1.2-0.2,1.7s-0.8,1-0.5,1.6
            		c0.3,0.6-0.3,0.5-0.4,1.3c0,0.8,0.4,0.8,0.1,1c-0.3,0.2,0.4,0.8,0,0.8c-0.4,0.1-0.2,0.7,0.1,1.2c0.3,0.5-0.6,0.2-1,0.6
            		c-0.4,0.4,0.4,0.8,0.2,1.3c-0.3,0.5-1-0.3-1.3-0.5c-0.3-0.3,0.5-0.8,0.9-1.3c0.4-0.5-0.4-1.7-1.1-1.5c-0.7,0.3,0.7,1,0.7,1.6
            		c0,0.6-1.1-0.1-1.1,0.5c0,0.6-0.8,0.4-1.2,0.8c-0.4,0.4,0.5,0.8,0.1,1c-0.4,0.1-1.2,0.8-1.2,1.2c0,0.4,0.5,0.5,0.5,0.1
            		c0-0.4,0.7-0.6,0.9,0c0.2,0.6,0.5,0,1.2,0.1c0.6,0.1,0.5,1.1,0.2,1c-0.4,0-0.7,0.6-0.6,1.2c0.1,0.7,1.3,0,1.9,0.2
            		c0.5,0.2,0.4,1.1,0,0.8c-0.3-0.3-1-0.8-1.3-0.3c-0.3,0.5-0.8,0.3-1.2,0.1c-0.4-0.2-1.8,0.8-1.3,1.1c0.5,0.4,0.4,0.9,0.8,0.9
            		c0.3,0,0.8-0.3,1-0.6c0.2-0.4,0.8-0.7,0.8,0.1c0,0.8-0.8,0.4-1,0.8c-0.2,0.4-0.5,0.8-1.1,1.2c-0.5,0.4,0,1.1,0.4,0.8
            		c0.4-0.2-0.1,0.5,0.5,0.6c0.6,0.1,0.8-0.4,0.6-0.8c-0.1-0.4,0.3-1.4,0.8-0.9c0.5,0.5-0.3,1-0.3,1.4c0,0.4-0.3,1-0.3,1.5
            		c0,0.5-0.6,0.2-0.5,0.8c0,0.5-1.2,0.2-0.7,0.5c0.6,0.4-0.4,0.8,0,1.2c0.3,0.4,0.6-0.5,1.1-0.3c0.5,0.2,0.4,0.3,1,0
            		c0.6-0.3,1,0.5,0.4,0.7c-0.6,0.2-1.2,0.8-0.9,0.9c0.3,0.1,1-0.4,1.2,0.1c0.2,0.6,0.6,0.4,0.7-0.2c0-0.6,1.1-0.1,1.3,0.4
            		c0.2,0.5-1.1,0.8-1.8,0.7c-0.7-0.1-1,0.6-0.6,1.2c0.4,0.6,1,0,1.5-0.5c0.5-0.5,2.9-0.4,3.1,0.2c0.2,0.5-1.1,0.1-1.2,0.4
            		c-0.1,0.3-1.1,0.6-1.2,0.2c-0.1-0.4-1.1,0.1-1.1,0.7c0,0.6-0.8,0.6,0,0.8c0.8,0.2,0.6-0.8,1.8-0.8c1.2,0.1,1.9-1.2,2.3-0.4
            		c0.4,0.7-1,1.3-1.5,0.8c-0.5-0.4-1.1,0.3-1,1c0,0.7-1.4-0.2-2.1-0.3c-0.7-0.1-0.4,0.7,0.2,1.3c0.6,0.7,1-0.1,1.7,0.4
            		c0.8,0.5,0.8,1,1.3,0.5c0.5-0.5,0-0.4-0.4-0.7c-0.4-0.2,0-1,0.8-0.6c0.8,0.4,1.8-0.4,1.6-1.4c-0.3-1.1,0.8-1.6,1.8-1.9
            		c1-0.4,0.5,0.7-0.3,1c-0.8,0.3-0.9,0.5-0.3,1.2c0.6,0.6,1.3-0.4,1.8,0.1c0.5,0.5-0.9,0.4-1.1,0.9c-0.2,0.5,0.8,1.1,0.1,1.4
            		c-0.8,0.4-0.4-1.5-0.8-1.6c-0.4-0.2-0.8,0.2-0.4,1.2c0.4,1-0.5,0.1-1-0.4c-0.4-0.5-1.1,0.2-0.5,0.8c0.5,0.5-1,0-1,0.8
            		c0,0.8,0.7,0.1,1.5,0.3c0.8,0.2,0.4,1.3,1,1.8c0.6,0.4,0.1-1.4,0.7-1.6c0.6-0.1,0,1.4,1.4,1.9c1.4,0.4,0.1-0.3,0.7-0.7
            		c0.6-0.4,1.4,1.3,2.1,1.5c0.7,0.2,0.2-0.8-0.3-1.3c-0.5-0.5,0.5-0.2,1.2,0c0.7,0.2,1.2,0.3,1.3-0.2c0.1-0.2,0.2-0.2,0.3-0.1
            		c0.3,0.1,0.6,0.3,0.9,0c0.4-0.5,1.8-0.2,2.5-0.7c0.7-0.4-0.2-0.6-1.3-0.6c-1.1,0-3.3-2.1-4-2.6c-0.7-0.5-0.2-1.1-1-0.9
            		c-0.8,0.1-0.3-0.6,0.2-0.8c0.4-0.2-0.2-1-0.7-1.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.6,0-1.9,0.1-1.6-0.8c0-0.2,0.1-0.2,0.2-0.3
            		c0.5-0.2,1.6,0.5,1.9,0.3c0.3-0.3-1.1-1.5-1.1-1.9c0-0.4-0.2-1.1-0.2-1.5c0-0.4-0.2-1.4,0.4-1.9c0.6-0.4,0.7-0.9,1.1-0.8
            		c0.4,0.1,1.2-0.4,1.2-1.4c0-1.1,0.5-1.5,1.4-2.2c1-0.8,2.6-1.6,2.4-2c-0.2-0.4,0.4-1.5,0.4-2c0-0.4-1.5-0.4-2-0.5
            		c-0.5-0.2-2.3-1.4-1.4-3.1c0.9-1.6,2.8-1.8,3.3-1.9c0.5,0,0-0.8,0.5-1.1c0.4-0.3,0.5-0.7,0.5-1.4c0-0.8,0-2,0.8-2.1
            		c0.8-0.1,1-0.5,0.5-0.7c-0.5-0.1-0.7-0.7,0.1-0.8c0.8-0.1,0.5,0.7,1.1,0.7c0.6,0,1.6-1,0.8-1.6c-0.8-0.6-1,0.3-1.4,0.4
            		c-0.4,0.1-0.7-0.5-1.1-0.5c-0.4,0-0.1-1.1-0.3-1.5c-0.2-0.4-0.4-1.4-0.1-1.9c0.4-0.5,1.9,0.8,3.2,0.8c1.3,0.1,2.3-0.7,2.3-1.2
            		c0-0.5-0.6-1.4,0.2-1.9c0.8-0.5,0.1-0.7-0.1-1.4c-0.2-0.7,0.4-1.2,0.7-0.8c0.4,0.4,3,0.1,5.2-0.3c2.2-0.4,3.7-1.2,3.8-1.9
            		c0.1-0.6,1.5-2.1,1.8-2.6c0.3-0.5,0-1.2-0.8-1.3c-0.9-0.1-0.8-1.2-0.4-1.7c0.5-0.6-0.3-1.1-1.1-1.2c-0.8-0.1-2.2-0.7-1.4-1.4
            		c0.4-0.3,0.5-0.3,0.7-0.1c0.2,0.2,0.4,0.4,0.8,0.4c0.8-0.1,1.6,0.2,2.6,0.7c1,0.4,0.6-0.1,1.6,0c0.9,0.1,2,0.2,3-0.4
            		c0.7-0.4,1.1-1.2,1.6-1.9c0.3-0.3,0.5-0.6,0.9-0.9c1.2-0.9,0.9-1.4,1.5-2.5c0.6-1.1,0.1-1.5,1.1-2.5c1-0.9-0.2-1.6,1.1-2.1
            		c1.2-0.6,1.1,0.5,0,1.8c-1,1.3-1.2,1.7,0.1,0.7c1.3-1,1.8-2.4,2.6-3.9c0.8-1.6,1.3-2,2-2.1c0.7-0.1,0.7-1.7,0.6-2.9
            		c-0.1-1.2-0.4-2.2,0-3c0.5-0.8,0-1.5,0.6-1.5c0.6,0,1.6-0.9,2.5-1.9c1-1,1.6-1.1,2.6-1.2c1-0.1,0.4-0.4,1.1-0.6
            		c0.7-0.2,1.1-0.8,1.6-1.2c0.4-0.4,2.3,0,3.3,0c1.1,0,1.8,0.1,1.8-0.6c0-0.7,0.5-1.2,1.1-1.2c0.6,0,0.9-0.2,0.9-1.1s0.3-1.4,0.8-1.8
            		c0.5-0.4,0.6-1.3,0.8-1.9c0.2-0.6,0.8-0.2,0.8-1.1c0-0.8,0.3-1.8,0.3-2.5c0-0.7,0.7-0.6,1-1.1c0.3-0.5-0.3-1.1,0.2-2.5
            		c0.5-1.4,0-2.7,0-3.9c0-1.2,0-2.5,0.4-2.9c0.4-0.4,0.2-0.6-0.1-0.8c-0.4-0.2,0-0.7,0.4-0.6c0.3,0.1,0.7,0.8,1.2,0.4
            		c0.6-0.4,1.1-1.9,1.6-3.1c0.5-1.2,1.3-1.2,1.9-1.5s2-1.8,2.8-4c0.8-2.2,0-4.3-0.4-5.8C312.7,275.7,312.2,276,311.3,276z
            		 M226.7,169.8c1.4,0.2,2.3-0.8,2.8-0.2c0,0,0.1,0.1,0.1,0.1c0.6,1.2-1.7,1.9-3.9,2c-0.7,0-1.2,0.1-1.6,0.2
            		c-0.9,0.1-1.2,0.2-1.4-0.4C222.2,170.5,225.3,169.6,226.7,169.8z M223.6,172.9c0,0.1,0,0.1,0,0.2c-0.3,0.7-3.1,2-4.6,2.8
            		c-1.7,0.9-4,0.4-4-0.9c0-1.3,1.2-1.1,1.5-0.4c0.1,0.2,0.4,0.1,0.8-0.2c0.7-0.5,1.7-1.3,2.5-1.3c1.3,0.1,1.7,0.9,2.6,0.3
            		C223.2,172.8,223.4,172.5,223.6,172.9z M217.2,164.8c1.2,0.4,3.1-0.5,3.7,1c0.6,1.5,2.1,2.7,0.7,2.8c-1.2,0.1-2.7-1.5-2.6-0.6
            		c0.1,0.9-0.6,0.4-0.6,1.8c0,1.3-0.9,3.2-1.4,3.1c0,0,0,0-0.1,0c-0.5-0.2,0.3-2.2-0.8-2.6c-1.1-0.4-2.3,1.5-2.1,0.4
            		c0.2-1.2,1.8-2.1,0.8-3.7c-1-1.5-2.8-1.5-3.1-0.4c-0.4,1.2-1.6,0.6-2.1,1.5c-0.4,0.9-0.9,2.9-0.5,4.3c0.4,1.4-0.1,2.1-1.4,2.9
            		c-1.3,0.8-2-1.2-1.5-3.8c0.4-1.8,1.3-3.5,0.4-3.5c-0.9,0,1.2-2.1,3-2.6c1.9-0.4,4.2-0.3,4.2-0.8c0-0.1,0.1-0.2,0.2-0.2
            		C214.7,164.2,216.2,164.5,217.2,164.8z M205.4,157.5c1.2,0,4.1,0.3,4.3,1.8c0.2,1.5,2,0.1,2.1,1.5c0.2,1.2,1.3,2.2,1.1,2.8
            		c0,0.1-0.1,0.2-0.2,0.3c-0.7,0.5-0.5-0.8-1.9-0.8c-1.3,0-2.7,1.2-3.7,0.7c-1.1-0.5-0.5-1.2-1.5-1.2c-1,0,0.7-1.9-0.7-1.1
            		c-1.4,0.8-3.6,2.8-4.5,1.9c-1-0.9-1.9,0.3-2.6-0.4c-0.7-0.7,2.5-2.7,4-2.6c0.4,0,0.7-0.1,1-0.4
            		C203.7,159.2,204.5,157.5,205.4,157.5z M186.1,144.6c0.7,0.9,1.8,4.5,2.4,5.7c0.6,1.2,0.6,3-0.2,3.1c-1,0.1,0.1-2.6-1.7-3.6
            		c-2.1-1.2-3-3.3-3-4.5C183.6,144.2,185.4,143.7,186.1,144.6z M149.7,122.4c0.1-0.6,0.7-1.6,1.6-1.6s0.8-1.2-0.6-1.9
            		c-1.5-0.7,2.1-1.2,2.9,0c0.8,1.1,2.3,2,3.1,1.1c0.8-0.8,5.5-3.1,5.5-2s-3.4,1.8-4.8,3c-1.4,1.2-1.3,0.9-2.5,1.1
            		c-1.2,0.2-0.2,1.4-1.7,1.5c-1.5,0.1-5,0.2-5.5-0.6C147.5,122.8,149.6,123,149.7,122.4z M135.2,110c0.7-0.1,0.2-0.8,1.1-1.2
            		c0.9-0.4-0.2-0.9,0.1-1.3c0.3-0.4,2.2,0.4,2.2-0.3c0-0.7-1.9-1.3-2.5-0.9c-0.6,0.4-2.1,1.4-3.9,0.6c-0.6-0.2,4.5-0.8,5.3-1.6
            		c0.9-0.8,4.7-1.7,5-1c0.3,0.7-2.1,1.2-0.9,1.8c1.1,0.6,3,0.2,3.4-0.5c0.4-0.7,2.3-0.6,1.1,0.9c-1.2,1.5-1.6,1.7-2.8,1.5
            		c-1.2-0.2-1.9,0.4-1.3,0.8c0.6,0.4,0.6,0.8-0.6,1.1c-1.2,0.3-2.1,1.2-2.6,1.1c-0.5-0.1,2.1-2,1.5-2.5c-0.7-0.5-2.3,0.9-2.4,1.5
            		c0,0.6-1.1,0.6-1.8,0.6C135.4,110.7,134.5,110,135.2,110z M203.9,234.8C203.8,234.9,203.8,234.9,203.9,234.8
            		C203.8,234.9,203.8,234.9,203.9,234.8z M203.7,235.1C203.7,235.1,203.6,235.1,203.7,235.1C203.6,235.1,203.7,235.1,203.7,235.1z
            		 M203.7,229C203.7,229,203.7,229,203.7,229C203.7,229,203.7,229,203.7,229z M200.3,229c-0.1,0-0.2,0-0.2,0
            		C200.2,229,200.2,229,200.3,229z M200.3,229c0.3,0,0.9,0,1.5,0C201.2,229,200.6,229,200.3,229z M202.7,229.1
            		C202.7,229.1,202.7,229.1,202.7,229.1C202.7,229.1,202.7,229.1,202.7,229.1z M202.2,229.1c-0.1,0-0.1,0-0.2,0
            		C202.1,229.1,202.2,229.1,202.2,229.1z M247.3,261.7c0,0-0.1,0-0.1,0.1C247.2,261.8,247.2,261.7,247.3,261.7z M247.8,261.5
            		C247.8,261.5,247.8,261.5,247.8,261.5C247.8,261.5,247.8,261.5,247.8,261.5z M246.7,261.9C246.7,261.9,246.6,261.9,246.7,261.9
            		C246.6,261.9,246.7,261.9,246.7,261.9z M244.1,288.1c-0.1,0-0.2-0.1-0.3-0.1C243.9,288.1,244,288.1,244.1,288.1z M243.7,288
            		c-0.1,0-0.2,0-0.3-0.1c-0.2,0-0.3,0-0.5,0C243.2,288,243.5,288,243.7,288z M240.8,274.1c-0.1,0-0.2,0.1-0.3,0.2
            		C240.6,274.2,240.7,274.1,240.8,274.1z M241,274c-0.1,0-0.1,0-0.2,0.1C240.9,274,241,274,241,274z M242.2,274.1
            		c-0.4,0-0.7-0.2-1.1-0.1C241.5,273.9,241.8,274.1,242.2,274.1z M242.9,287.9c-0.3,0-0.7,0-1,0C242.2,287.9,242.5,287.9,242.9,287.9
            		z M243.3,301.8c0,0.1,0.1,0.2,0.2,0.3C243.4,302,243.3,301.9,243.3,301.8z M243.8,302.5c-0.1-0.1-0.2-0.2-0.3-0.3
            		C243.6,302.3,243.7,302.4,243.8,302.5z M246.8,313C246.8,313,246.8,313,246.8,313C246.8,313,246.8,313,246.8,313z M247.5,312.7
            		C247.5,312.7,247.4,312.7,247.5,312.7C247.4,312.7,247.5,312.7,247.5,312.7z M247.1,312.9C247.1,312.9,247.1,312.9,247.1,312.9
            		C247.1,312.9,247.1,312.9,247.1,312.9z M246.1,262.1c0,0,0.1,0,0.1,0C246.2,262.1,246.1,262.1,246.1,262.1z M274.6,261.3
            		c0.1,0,0.3,0,0.4,0C274.8,261.4,274.7,261.3,274.6,261.3z M266.7,305.8C266.7,305.8,266.7,305.8,266.7,305.8
            		C266.7,305.8,266.7,305.8,266.7,305.8z M267.1,305C267.1,305.1,267.1,305.1,267.1,305C267.1,305.1,267.1,305.1,267.1,305z
            		 M266.3,306.1c-0.1,0-0.1,0-0.2,0C266.2,306.2,266.2,306.1,266.3,306.1z M273.5,318.4c0-0.1,0-0.1,0-0.2
            		C273.6,318.3,273.6,318.4,273.5,318.4z M273.6,318c0-0.1,0-0.2,0.1-0.3C273.6,317.8,273.6,317.9,273.6,318z M273.7,317.4
            		c0-0.2,0.1-0.4,0.1-0.6C273.8,317,273.8,317.2,273.7,317.4z M273.9,316.6c0-0.1,0-0.2,0.1-0.4C273.9,316.4,273.9,316.5,273.9,316.6
            		z M274,316.1c0-0.1,0-0.2,0-0.3C274,315.8,274,315.9,274,316.1z M274,315.6c0-0.1,0-0.2,0-0.3C274,315.4,274,315.5,274,315.6z
            		 M273.7,315c0.1,0,0.1,0.1,0.2,0.2C273.9,315.1,273.8,315,273.7,315z M270.3,261.9C270.3,261.9,270.3,261.9,270.3,261.9
            		C270.3,261.9,270.3,261.9,270.3,261.9z M269.9,261.9c0,0,0.1,0,0.1,0C270,261.9,270,261.9,269.9,261.9z M268.7,261.6
            		c0.1,0,0.1,0,0.2,0C268.8,261.6,268.8,261.6,268.7,261.6z M269.1,261.7c0.1,0,0.1,0,0.2,0C269.2,261.7,269.2,261.7,269.1,261.7z
            		 M268.4,261.6c0,0,0.1,0,0.1,0C268.5,261.6,268.5,261.6,268.4,261.6z M261.2,256C261.2,256,261.2,256,261.2,256
            		C261.2,256,261.2,256,261.2,256z M261.9,255.1c0.1,0,0.2,0,0.3,0C262.1,255.1,262,255.1,261.9,255.1z M261.7,255.2c0,0,0.1,0,0.1,0
            		C261.8,255.1,261.8,255.2,261.7,255.2z M261.6,255.3C261.6,255.3,261.6,255.3,261.6,255.3C261.6,255.3,261.6,255.3,261.6,255.3z
            		 M261.1,256.1c-0.1,0-0.1,0.1-0.2,0.1C261,256.2,261.1,256.2,261.1,256.1z M257.3,311c0,0-0.1,0-0.2,0
            		C257.2,311,257.3,311,257.3,311z M257.1,311c-0.1,0-0.1,0-0.2,0C256.9,311,257,311,257.1,311z M254.9,310.9
            		C254.9,310.9,254.8,310.9,254.9,310.9C254.8,310.9,254.9,310.9,254.9,310.9z M255.1,310.8C255.1,310.8,255.1,310.8,255.1,310.8
            		C255.1,310.8,255.1,310.8,255.1,310.8z M255.5,310.8C255.5,310.8,255.5,310.8,255.5,310.8C255.5,310.8,255.5,310.8,255.5,310.8z
            		 M267.5,328.9c-0.1,0.1-0.1,0.1-0.2,0.2C267.4,329,267.5,328.9,267.5,328.9z M268.1,328.2c-0.2,0.2-0.4,0.4-0.5,0.6
            		C267.7,328.6,267.9,328.4,268.1,328.2z M268.5,327.8c-0.1,0.1-0.2,0.2-0.3,0.3C268.3,328,268.4,327.9,268.5,327.8z M268.9,327.3
            		c-0.1,0.1-0.1,0.1-0.2,0.2C268.8,327.4,268.9,327.4,268.9,327.3z M275.1,261.3c0.2,0,0.4,0,0.6-0.1
            		C275.4,261.3,275.3,261.3,275.1,261.3z M273.9,260.9c0.2,0.2,0.4,0.3,0.6,0.4c-0.4-0.1-0.7-0.4-0.8-0.7
            		C273.7,260.7,273.8,260.8,273.9,260.9z M269.8,261.8c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0C269.6,261.8,269.7,261.8,269.8,261.8z
            		 M230.8,266.1C230.8,266.1,230.8,266.1,230.8,266.1c0,0,0-0.1-0.1-0.1C230.7,266,230.8,266,230.8,266.1z M231.5,267
            		C231.4,267,231.4,267,231.5,267C231.4,267,231.4,267,231.5,267z M231.4,266.8C231.4,266.8,231.4,266.8,231.4,266.8
            		C231.4,266.8,231.4,266.8,231.4,266.8z M231.2,266.6C231.2,266.6,231.2,266.6,231.2,266.6C231.2,266.6,231.2,266.6,231.2,266.6z
            		 M231.1,266.4C231,266.4,231,266.3,231.1,266.4C231,266.3,231,266.4,231.1,266.4z M231.3,267.5c0.1,0,0.1-0.1,0.1-0.1
            		C231.4,267.4,231.3,267.5,231.3,267.5z M231.5,267.1C231.5,267.1,231.5,267.1,231.5,267.1C231.5,267.1,231.5,267.1,231.5,267.1z
            		 M231.4,267.3C231.5,267.3,231.5,267.3,231.4,267.3C231.5,267.3,231.5,267.3,231.4,267.3z M241.4,288.1c0.1,0,0.2-0.1,0.4-0.1
            		C241.7,288,241.6,288,241.4,288.1z M247.9,312.5C247.9,312.5,247.9,312.5,247.9,312.5C247.9,312.5,247.9,312.5,247.9,312.5
            		C247.9,312.5,247.9,312.5,247.9,312.5z M248.6,311.9C248.6,311.9,248.7,311.9,248.6,311.9C248.7,311.9,248.6,311.9,248.6,311.9z
            		 M267.2,329.2c0,0.1-0.1,0.1-0.1,0.2C267.2,329.3,267.2,329.3,267.2,329.2z M267,329.6c0,0,0,0.1-0.1,0.1
            		C267,329.6,267,329.6,267,329.6z"/>
            	<path :class="amcolor" d="M233.5,224.1c0.4-0.4-2.4-2.2-3.7-2.2c-1.3,0-5.7-3-9.7-3.9c-4-0.9-7.5,2-7.1,2.5c0.5,0.7,2.5-0.9,3.5-1.6
            		c1-0.7,1.9,0.4,2,0.8c0.1,0.4,1.3,0.7,3.4,0.8c2.1,0.1,2.3,1.8,4.3,2.2c2,0.4,0,1.2,1,1.6C228,224.6,233.1,224.6,233.5,224.1z"/>
            	<path :class="amcolor" d="M225.2,228c0.4,0.6,3.7,1.5,4.1,0.7C229.6,227.9,224.7,227.3,225.2,228z"/>
            	<path :class="amcolor" d="M248.1,228.2c0.8,1.2,3.1,0.5,3.3,0C251.8,227.3,247.3,227,248.1,228.2z"/>
            	<path :class="amcolor" d="M263.2,379.7c-1-0.2-3.3,1.8-2.3,2.3C261.9,382.6,264.2,379.9,263.2,379.7z"/>
            	<path :class="amcolor" d="M263.5,382.2c0.7,0.4,3.6-0.9,3.3-1.5C266.1,379.4,262.8,381.9,263.5,382.2z"/>
            	<path :class="amcolor" d="M199,265.4c-0.9,1.3,0.9,2.6,1.2,2C200.7,266.7,199.9,264.1,199,265.4z"/>
            	<path :class="amcolor" d="M353.1,43.2c-2.2,0-4,0.4-4.1,1.9c-0.1,1.5-2.9-0.3-4.1,0.2c-1.2,0.5-0.6-1.2-1.8-0.7c-1.2,0.6-2.5,2-3.9,2.6
            		c-1.4,0.6-2.5,2.1-3.4,2.2c-0.9,0.1,1.9-2.7,3.3-4.2c1.4-1.4,0.7-3.1-1.2-2.9c-1.9,0.2-1.5,1.4-2.6,1.6c-1.1,0.2-6.2,2.8-6.4,2
            		c-0.2-0.8,4.2-2.1,4.2-2.6c-0.1-0.5-5.6-0.6-8.1-0.2c-2.5,0.4-6.9,1.8-7,1.1c-0.1-0.7,5.1-1.7,7.7-2c2.7-0.3,7.4,0.2,9.5-0.7
            		c2-0.8,5.1-0.9,5.6-1.5c0.5-0.7-2.3-1.5-3.7-1.5c-1.4,0.1-2.9-0.1-2.7-0.8c0.1-0.7-2.2-0.9-2.4-1.4c-0.2-0.5-4.7-0.1-5.6-0.7
            		c-1-0.6-3.7-0.9-6.1-0.7c-2.4,0.2-7.3,0.1-8.4,0.2c-1.1,0.1-1.8,0.5-2.9,0.5c-1-0.1-2.6,0.3-2,1c1.1,1.2-1.6,1.7-1.4,0.8
            		c0.2-0.9-2.3-1.3-3.1-0.7c-0.9,0.7-4.9-0.8-5.5,0c-0.6,0.8-5.3,0.5-6.7,0.8c-1.4,0.2,2.1,1.2,2,1.8c-0.1,0.6-4.5,0-3.6,1.1
            		c0.9,1.1,3.7,1.8,5.1,3.2c1.4,1.4-1.1,0.5-2.6-0.5c-1.5-1-3.5-0.7-4.5-1.7c-1-1-4.5-1.8-5.4-1.2c-1.1,0.7,2.4,2,2.4,2.8
            		c0,0.8-2.4-0.2-2.8,0.1c-0.4,0.3-2.5-2-3.7-2c-1.2,0-0.2,1.1-0.2,2.6c0,1.5-2.3,2.4-1.7,1.4c0.7-1,0.2-3.6-1.1-4.1
            		c-1.2-0.5-4.5,1-6.1,1c-1.7-0.1-3.9,0.5-2.4,1.3c1.4,0.8-0.1,1.3-1.3,0.4c-1.2-0.9-5.4,0-4.5,0.6c0.8,0.6,1.1,2.3,0.6,3
            		c-0.5,0.7-2.3-0.7-3.9-0.6c-1.6,0.1-9.6,3.9-9.1,4.9c0.5,1,3.9,0.2,4.9,0.8c1.1,0.6-0.4,2.8-1.7,3.7c-1.3,0.9-6.2,0.1-6.3,1.1
            		c-0.1,1.1-7.7,1.2-7.7,3c0,0.7,0.4,1.3,1.2,1.5c1.1,0.2,2.4-0.1,3.3,1c1,1.1,3.6,1.1,5.5,0.4c1.8-0.7,3.2,0.1,3.1,1.2
            		c-0.1,1.1-4.2-0.4-5.4,0.4c-1.2,0.8-5.1,0.1-4.9,1c0.2,0.8,2.1,0.7,3.6,1c1.5,0.3,0.1,1,0.1,1.6c-0.1,0.7,0.8,0.5,2.4,1.3
            		c1.5,0.8,4.1,1.2,3.3,0.4c-0.8-0.9,1.6-0.8,2-0.2c0.4,0.5,1.5-0.8,2.7-0.5c1.1,0.3,1.5-1.1,2.4-0.5c0.9,0.6,5.1,1,6.5,1.7
            		c1.4,0.8,3.4,1,3.2,2.1c-0.2,1.1,1.2,2,2.8,2.7c1.6,0.7,1.9,2.3,1.8,3.3c-0.1,1,1.7,1.7,1.4,2.2c-0.3,0.5-0.1,1.1,1,2.1
            		c1.1,1-1.7,1.7-1,2.5c0.7,0.8-1,2.4,1,2.7c1.9,0.3,1.6-1.3,3-1.3c1.4,0-0.1,1.2,0.7,2c0.8,0.8,2.4,0.6,3.9,1.8
            		c1.5,1.2,0.5,1.8-0.8,0.8c-1.4-1-5.4-0.8-5.4-0.4c0,0.4,4.4,2.9,5.4,2.5c1-0.4,2.4,1.5,1.8,2.1c-0.6,0.6-0.3,2.1-0.4,2.8
            		c-0.1,0.7-1.6,0-2.6,0.1c-1,0.1-1.6,0.4-1.6,1.4c0,1-1.5,1.7-1.7,3c-0.2,1.2,1.2,1.2,2,1.7c0.8,0.5-1.4,0.9-1.5,1.8
            		c-0.1,0.9,2,2.5,2.8,2.9c0.8,0.4,0.1,2.4,0.4,3.4c0.3,1,1.3-0.2,1.2,1.1c-0.1,1.3,1,1.2,1,2c0.1,0.8,1.9,0.7,1.5,1.7
            		c-0.4,1.1,0.5,1.8,1,2.4c0.4,0.6,2,1.6,2.3,2.4c0.3,0.8,1.2,1.8,2.3,1.5c1.1-0.3,1.1,0.7,2,0.6c0.8-0.1,2.1,0.4,2.4,1
            		c0.2,0.7,2.8,1.2,3.5,1.4c0.7,0.1,0.8-1.2,1.5-1.5c0.7-0.4,0.4-3,1-3.2c0.6-0.2,0.4-2.9-0.2-3.2c-0.6-0.2-0.4-1.2,1-1
            		c1.4,0.2,0.9-1.2,1.5-1.4c0.7-0.2,0.4-1.4,0.8-1.5c0.5-0.1,0.4-1,0.1-1.4c-0.4-0.5,0.1-0.8,0.8-1c0.7-0.1,0.5-1.1-0.4-1.5
            		c-1-0.4-0.7-1.4,0.3-0.8c1,0.7,1.7,0.2,1.1-0.5c-0.5-0.7,0.7-1.1,1.9-1.2c1.2-0.1,1.9-0.8,1.7-1.7c-0.2-0.9,1.4-0.9,1-0.1
            		c-0.4,0.8-0.2,1.7,0.4,1.1c0.6-0.6,1.7-0.2,3.7-0.8c2-0.6,4-2.3,4.5-3.9c0.5-1.6,2.8-1.4,2.5-2.5c-0.3-1.1,0.4-1.5,1.9-0.8
            		c1.5,0.7,0.3-0.5,2-0.5c1.7,0,1.4-0.6,3-0.7c1.6-0.1,5.8-0.8,7.2-2c1.4-1.2,4.5-2.2,5.7-3c1.2-0.8,1.5-1.4,0.8-1
            		c-0.8,0.4-2.4,0.5-3.6,0.2c-1.2-0.3-3.1-1.3-4.5-0.5c-1.4,0.8-0.5-1.1,0.6-1.3c1.1-0.2,0.7-0.7,0.4-1.9c-0.3-1.2,1.9-0.4,2.9,1
            		c1,1.4,2.8,2,4.4,1.5c1.6-0.5,0.1-1.8,0.7-2.7c0.7-0.9-4.9-3.7-5.2-4.6c-0.3-1,1.4-0.3,3,0.4c1.5,0.7,1.9-1.2,1.9-2.1
            		c0-0.8-3.3-0.9-4.8-0.1c-1.5,0.8-2.9-1.1-0.9-1.3c2-0.2,0.2-1.1,0.8-1.7c0.6-0.6,3.1,1.7,4.5,1.3c1.3-0.4,2.3,0.1,3.4-0.8
            		c1.1-0.9-2-1.7-2.7-2.5c-0.7-0.8,3.3-0.5,4.5-0.4c1.2,0.1,1.5-1.7,0.5-1.4c-1,0.4-4.3-1.2-3.6-2.3c0.7-1.1,2,0.2,3.5-0.6
            		c1.5-0.8,0.1-3.7-1-3.7c-1.1,0-3.6-0.4-3.6-0.9c0-0.5-2.1-1-1.5-1.5c0.6-0.5,1.4,0.7,2.7-0.4c1.4-1,4.2,0.2,5.5,0
            		c1.2-0.2-0.5-2.6-1.2-2.1c-0.7,0.4-3.1,0.5-3.3-0.7c-0.2-1.2,2.3,0,2.7-0.6c0.4-0.6-3.4-2.1-4-0.9c-0.6,1.2-2.7,0.8-1.6,0.1
            		c1.1-0.7,1.4-2.7,1.2-3.7c-0.1-1,4-1.1,3.6-2.7c-0.5-1.7,2-2,3.3-2c1.4,0-0.1-1.8-1.4-1.6c-1.3,0.2-2.7,1.7-3.3,1.1
            		c-0.7-0.6,1.5-1.7,2.7-1.7c1.2,0,4-0.1,5.1-0.7c1-0.6-1.8-1.1-3.7-0.9c-1.9,0.2-1.9-0.6,1-0.7c2.9-0.1,2.4-0.7,4.4-0.9
            		c2-0.2,1.4-1.1,2.6-1.1c1.2-0.1,4.5-1.4,4.5-2.1S355.3,43.2,353.1,43.2z"/>
            	<path :class="amcolor" d="M279,95c0.1-1-1-0.4-1.6-1.5c-0.6-1.1-3.6-1.6-3.9-0.7c-0.1,0.4-1.1,0.9-0.3,1.7c0.8,0.8,1.3,0.5,2.2,1.3
            		C276.3,96.7,279,95.9,279,95z"/>
            	<path :class="amcolor" d="M307.3,387.7c0.2,0.8,1,0.4,1.7,1.1c0.8,0.7,1.5,2,2.3,0.8C312.1,388.4,307.2,387.3,307.3,387.7z"/>
            	<path :class="amcolor" d="M189.3,53.3c1.3,0.7,0.5,1.5,2,2.1c1.4,0.6,5.5-0.8,5.9,0.2c0.4,1-4.2,1.1-3.6,1.7c0.6,0.6,3.2,1.6,2.9,2.1
            		c-0.4,0.5,4.3,2.1,4.7,1.3c0.4-0.7,1.7-0.5,2.9-0.1c1.2,0.4,1.2-2.8,2.1-2.2c0.8,0.5,0.7-1.2,2.1-1.7c1.3-0.5,3.3-0.5,3.4-1.2
            		c0.1-0.4-0.4-1.1-2-0.9c-1.6,0.2-2.7-0.4-2-1.3c0.7-0.9-1.8-1.2-0.9-1.9c0.9-0.7-1.7-1.4-1.4-0.4c0.3,1.1-1.6,0.1-1.9-0.9
            		c-0.3-1-3.9-1.3-6.3-3.5c-2.4-2.1-4.9-0.7-3.5-0.4c1.4,0.3,1.3,1,0.2,0.9c-1.2-0.1-3.2,0.1-1.3,0.8c1.9,0.7-0.2,1-1.7,1
            		c-1.5,0-1.6,2,0.4,2.2c2,0.3,0.5,1.4-1.1,1C188.4,51.8,187.8,52.6,189.3,53.3z"/>
            	<path :class="amcolor" d="M202.1,63.3c-1.6-0.8-3.6,0.9-2,1.5C201.6,65.4,203.7,64.1,202.1,63.3z"/>
            	<path :class="amcolor" d="M220.3,79.3c-0.5,1.2,1.2,1.2,1.2,2.5c0.1,1.3,1.8,1.8,3,1c1.2-0.8,4.8,0.2,4.8-0.8c0-1-3.7-2.8-5.1-2.5
            		C223,79.7,220.8,78.1,220.3,79.3z"/>
            	<path :class="amcolor" d="M195.4,62.6c-0.2-0.5-6.9,0.3-5.9,1.1C191.3,65,195.6,63.2,195.4,62.6z"/>
            	<path :class="amcolor" d="M183,88.1c2,1.3,1.3-1.1,3.6-1c2.2,0.1,2.3-3.6,2.7-4.9c0.4-1.3-1.8-1.2-1.2-0.3c0.5,1-0.1,2-0.3,0.8
            		c-0.2-1.2-1.9-0.1-2.5-1c-0.6-0.9,2-1,2.6-2.1c0.6-1.2-2.1-1-1.4-1.9c0.6-0.9-3.9,0.1-2.6,0.6c1.3,0.5,0.1,1.2-1.2,0.4
            		c-1.2-0.9-4.7,0.6-3.5,1.6c1,0.8,3.9-0.5,2.2,1.8c-1.7,2.3-2.4-0.6-4-0.4c-1.6,0.3-0.9,2.2,1.5,2.9C181.3,85.4,181,86.8,183,88.1z"
            		/>
            	<path :class="amcolor" d="M194.4,75.6c1.6-0.1,0.8-2.1-1-3.4c-1.8-1.3-5.8,1.4-5.1,2C189.2,74.8,192.8,75.7,194.4,75.6z"/>
            	<path :class="amcolor" d="M172.2,57.6c1.1-0.7,3,0.4,2.7,1.2c-0.4,0.8-3.4-0.2-3.2,0.5c0.1,0.3,0.4,1.3,2.6,0.7c2.1-0.6,3.9-0.2,5.4,1
            		c1.4,1.2,2.8,1.6,3.7,0.4c1-1.2-1.2-1.3-0.8-2.2c0.4-0.9-1.4-1.4-2.4-1.4c-1,0-1.3-1.8-2.1-1.4c-0.8,0.4-1.3,1-1.3-0.2
            		c0-1.2-4.6-1.2-5.8-0.8C169.7,55.9,171.2,58.3,172.2,57.6z"/>
            	<path :class="amcolor" d="M184.4,53.4c0.6-1.1-3.8-1.9-2.7-1C182.4,53,183.8,54.5,184.4,53.4z"/>
            	<path :class="amcolor" d="M184.4,67.4c-0.1,0.9-2.1-0.2-3.7-0.8c-1.6-0.6-0.6,1.2,0.6,2.3c1.2,1.1-0.6,0.5-2.3-0.6
            		c-1.7-1.2-1.5,0.8-0.7,1.4c0.8,0.6-0.2,1.5-1.2,0.1c-1.1-1.4-1.6-2.9-3.3-3c-1.7-0.1-0.9,1.6-0.3,2.5c0.9,1.2,1.8,1.4,3.2,2.2
            		c1.4,0.8,3.7-0.4,4.8-0.1c1.1,0.3-1.2,1.6-0.4,2.5c0.7,0.9,2.7,0.3,4.2,0.2c1.5-0.1,0.8-1,1.7-1.6c0.9-0.6-0.9-0.5-0.2-2.1
            		C187.5,69,184.5,66.5,184.4,67.4z"/>
            	<path :class="amcolor" d="M188.2,62.2c1.3,0,2.4-0.2,3.6-0.6c1.2-0.4-0.6-0.4,0.2-1.5c0.8-1.1-2-1.2-2.1-0.8c-0.2,0.4-3.7-2.1-4.2-1.3
            		C185.1,58.7,186.8,62.2,188.2,62.2z"/>
            	<path :class="amcolor" d="M172.8,65.1c0.7-0.9-2.9-3.7-3-2.4C169.6,64,172.1,66.1,172.8,65.1z"/>
            	<path :class="amcolor" d="M231.4,102.5c1.1-0.2,0.9-1.8,0.3-2.7c-0.4-0.6-1.6-0.7-2.6-0.6c-1,0.1-2.7,1.6-1.8,3.1
            		C228.2,103.8,230.4,102.7,231.4,102.5z"/>
            	<path :class="amcolor" d="M174.4,73.7c0.6-0.7-1.3-2.2-1.9-1C172,73.9,173.7,74.4,174.4,73.7z"/>
            	<path :class="amcolor" d="M222.5,121.5c0.6-0.1,1.9-2.3,0.3-2.2C221.2,119.3,221.5,121.6,222.5,121.5z"/>
            	<path :class="amcolor" d="M200,44.5c1.2-0.8,2.2-0.3,1.2,0.3c-1.1,0.5-0.7,0.9,0.8,0.8c1.5-0.1-0.6,0.4-0.6,1.3c0,0.9,1.8,0.4,1.9,1.2
            		c0.1,0.9,3.9,1.2,5.1,0.2c1.2-1.1,0.7,0.3,0.6,1c-0.1,0.7,5.4,1.3,5.7,0.4c0.3-0.9,1.1-0.5,2.1-0.3c1.1,0.3,6.3-1,6.4-2.1
            		c0.1-1.1,1.7,0.5,0,1.7c-1.7,1.2-5.1,1-6.6,1.4c-1.5,0.4,1,1.5,2.5,2.7c1.5,1.2-2,0.5-3.4-1c-1.4-1.5-4.6-1.5-6.3-1.4
            		c-1.7,0.1-1.2,3.2,0.1,3.2c1.2,0,2.5,0.4,3.7,2.4c1.2,2,3.9,1.5,3.9,2.4c0,0.9-3.5-0.7-5.4-1.1c-2-0.4-5.1,0.9-5.2,2.3
            		c-0.1,1.4,2.9,1.2,4.8,0.1c2-1.2,0.5,0.4-0.4,1.2c-1,0.9,2.3,1.6,2.3,2.7c0,1.1-2.4,0.7-2.7-0.3c-0.3-1-1.2-2-3.5-1.8
            		c-2.3,0.2-1.7,1.8-0.3,2c1.4,0.2,1.5,1.2-0.1,1.2c-1.6,0-5.2,1-4.1,2.1c1.2,1.2,5.8,0,6.6,0.7c0.8,0.7,3,1,3.6,0.2
            		c0.5-0.8,2.3-0.4,4.1-0.4c1.8,0,2.2,0.3,3,1c0.8,0.7,2.2,0.4,3.2-0.3c1-0.7,1-0.7,2.2-0.7c1.2,0,1.5-0.8,0.9-1.8
            		c-0.6-1-2.2,0.7-2.4,0c-0.2-0.9-1.7-1.2-4.2-0.8c-2.5,0.4-1.4-1.6,0.1-1.2c1.5,0.4,3.6,0.3,5.3-0.2c1.7-0.4,0.4-1.2,0.4-2
            		c0-0.7,2.1-0.3,3.7-0.3c1.6,0,3.9-2.6,4-4.1c0.1-1.5-3.6-1.2-5.4-1.2c-1.8,0,1.2-1.3,4.5-1.2c3.4,0.2,1.9-1.3,2.5-1.9
            		c0.6-0.5,2.2,0.6,3.9,0.3c1.7-0.4,0.5-1.8,1.7-1.8c0.8,0,2.7-1.2,6.3-3.3c3.7-2.1,6.5-1.7,6.7-2.9c0.2-1.2-5.1,0-6-0.4
            		c-0.9-0.4,3.7-1.3,4.7-1.1c1.1,0.3,2.5,0,6.2-1.8c3.7-1.8,2.1-2.2,0.5-2c-1.5,0.3-3.2-0.3-3.1-1.2c0.1-1-2.2-0.1-2.3-0.6
            		c-0.1-0.5-2.2-0.3-4.5,0.6c-2.3,0.9-0.2-0.9,0.8-1.2c1-0.4-5.9,0-7-0.8c-1.2-0.8-2.5,1.3-3.6,0.3c-1.1-1.1-3.8-0.2-3.5,0.6
            		c0.4,0.8-0.4,0.8-1.5-0.1c-1.1-0.9-3.1,0.1-5-0.2c-1.9-0.3-1.1,1.5-3.1,0.6c-2.1-0.9-4.3-0.4-3.7-0.2c0.6,0.3-0.2,0.8-1.1,0.5
            		c-0.9-0.3-1.7,0.1-1.2,1c0.4,0.9-3.3-0.3-3.3,0.7c0,1-1.3,1.5-2.4,0.6c-1.1-0.9-4.5-1.2-3.5-0.4c1,0.8-3,0.4-2,1.2
            		c1.1,0.8-1.3,1.4-1.3,1c0-0.4-2.2-1.2-3.1-0.4s-1.2,1.8-1.6,1.2c-0.4-0.5-2.4-0.4-4.3,0.4C196.7,44.8,198.8,45.3,200,44.5z"/>
            	<path :class="amcolor" d="M237.8,89.7c0.9-0.1,2-0.8,1.7-1.6c-0.3-0.8-2.6-0.9-3,0.1c-0.4,1-2.3-1.1-2.3-1.9c0-0.8-2.9-0.1-2.5-1.4
            		c0.4-1.3-3.3-1.7-4.3-1.7c-1,0-2.4,0.8-2.2,1.7c0.2,0.9-1.1,0.5-1.2-0.4c-0.2-1-2.6,1.1-3.3,1.1c-0.7,0,0.4-2.6,0.1-3.4
            		c-0.4-0.8-0.9-1.1-1.4-2.4c-0.5-1.3-3.2-0.8-3.9,0c-0.7,0.8-3,0.4-4.3,1.7c-1.2,1.2,0.1,2.5,0.6,2.9c0.5,0.4-1.9,1.2-1.1,1.9
            		c0.8,0.7,2.1,0.4,2.1,1.5c0.1,1.2-2.9-0.4-3.4-1.3c-0.4-1,0.2-1.9-0.4-2.6c-0.6-0.7-0.1-1.6,1.1-2.9c1.2-1.2,2.1-1.1,2.1-1.8
            		c0-0.7-5-0.7-7.9,2c-2.9,2.7-2.1,6.2-1.9,7c0.2,0.7,2.8,0.4,4.3,0.8c1.5,0.4,0.7,1.1-0.4,0.9c-1.2-0.2-3.1-0.5-3,0.3
            		c0.1,0.8,2.5,2.3,3.9,2c1.4-0.4,1.4-0.4,2.1,0.4c0.6,0.8,2.3,0.5,4,0.6c1.7,0.1,3.7,0.7,4.5,0.8c0.8,0.1,1.8-0.4,2.1-0.9
            		c0.3-0.4,3.6,0.7,4.5,0.6c1-0.1,0.4-1-0.3-1.2c-0.6-0.3-0.4-1.4,0.4-1c0.8,0.4,2.1,0.7,2.4,1.5c0.2,0.7,1,0.4,1.1,1
            		c0.1,0.6,2.9,1.3,3,2.1c0.1,0.7-2.8,1.1-2,1.7c0.8,0.6,2.1-0.8,3.1-0.9c1.1-0.1,1.1,2.3,1.9,2c1.1-0.5,2,0.4,3,2
            		c1.1,1.5-0.1,2.9,0,3.5c0.1,0.5,2.1,0.4,3.3-0.3c1.2-0.6,2.2,0.7,3.1,1.5c0.9,0.8-3.3,2.1-2.9,1.4c0.4-0.7-2.5-2.9-5.1-1.9
            		c-2.6,1-0.8,2.5-0.5,3.4c0.3,0.9-2.3,1.8-4.7,1c-2.4-0.8-1.7,0.5-2.7,0.5c-1,0-2.1,1.5-1.2,2.5c0.9,1,2.9,0.1,4.5,0.2
            		c1.6,0.1,1.8,0.5,2-0.4c0.2-0.9,1.9-0.4,3-0.2c1.2,0.3,0.9,1.9,2.3,2.1c1.4,0.2,0.3,1.6,1.2,2.6c0.9,1,3.7,0.4,4.8,1.5
            		c1.1,1.1,5.6,3.2,6.2,2.4c0.7-0.9-3.9-5-5.3-5.4c-1.3-0.4,1.3-0.8,3,0.5c1.7,1.3,4.2,2,5.4,0.4c1.2-1.6-1.1-1.2-1.1-2.3
            		s-0.8-2.8-2.1-2.8c-1.2,0-4.5-2.5-3.3-3c1.2-0.5-0.4-1.2,0.5-2.2c0.9-1,1.8,0.9,2.9,1c1.1,0.1,1.2,1.4,2.9,2.9
            		c1.7,1.4,1.9,0.4,2.1-0.5c0.3-1,2.1-0.2,2.1-1.2c-0.1-1,1.5-2.1,2.6-2.6c1.1-0.4-0.9-1.5-2.3-1.4c-1.4,0.1-1.7-0.9-1.7-1.5
            		s-2.7-2.1-3.6-1.9c-0.9,0.3-2.4-1.2-3.6-1.2c-1.2-0.1-2.9-1-2.7-2.2c0.2-1.2,2.3,0.4,2.8-0.6c0.4-1-2.2-0.4-2.3-1.2
            		c-0.1-0.7,0.6-0.4,1.2-0.9c0.5-0.4-0.4-1.3-1.1-1.7c-0.6-0.4-0.8,0.7-1.4,0.7c-0.6,0-0.1-1,0.4-1.6c0.4-0.6-2.1-1.3-3.1-1
            		c-1.1,0.4-1.4-0.3-1.4-1c0-0.7-1.9,0-2.6,0.5C237.8,90.9,236.9,89.8,237.8,89.7z"/>
            	<path :class="amcolor" d="M177,93.8c0.5-0.6,2.8,1.2,2.9-0.1c0.1-1.3-2.5-2-3.6-2.7c-1.1-0.7-1.5-0.4-3.2-1.6c-1.7-1.2,0.4-1.9-0.8-2.9
            		c-1.2-1-1.7-3.7-0.8-4.5c0.9-0.9,1.9-2.2,0.3-2.9c-1.6-0.6-4.2,0.5-3.9,0.9c0.3,0.4,2.5,1.6,2.5,2.1c0,0.5-2.1-1.5-2.8-1.1
            		c-0.7,0.4-1.5-0.9-2.1,0c-0.6,0.9,0.8,4,1.3,4.9c0.5,0.9-0.5,0.9-1.2,1.1c-0.7,0.2-0.8-2.5-1.5-3.5c-0.7-1-3.6-2.1-3.7-1.4
            		c-0.2,0.7,1.8,0.7,1.5,1.7c-0.3,1-1.3-0.6-2.3,0.2c-1,0.8-0.8,0.3-0.6-0.5c0.2-0.8-1.4-1.7-3.2-1.5c-1.8,0.2-0.8,1.4-1.3,1.9
            		c-0.5,0.4-3.2-0.3-1.5-0.6c1.7-0.4,1-1.3,0.4-2.3c-0.6-1-2.3,0.2-5.1,1.2c-2.8,1-4,2.1-3.7,2.1c0.4,0.1,0.4,0.5-0.6,1.4
            		c-1.1,0.9,0,1.7,1,1.7c1,0,0.1,0.8,0.8,1c0.6,0.2,3.8-1.2,4.7-0.6c0.9,0.6-5.3,1-5.3,2.1c0,1.1,3.4,1.7,5.9,1.2
            		c2.5-0.4,6.9,0.4,6.9,1c0,0.6-2.9,0.4-5.2,0.4c-2.2-0.1-6.1,0.6-5.9,1.3c0.2,0.8,0.4,0.8,2.8,1.9c2.3,1.1,4.7-0.5,4.6,1
            		c-0.1,1.5,1.2,1.9,4.4,2c3.1,0.1,4.5-1.4,6.1-1.3c1.5,0.1,2.2-0.2,2.9-1.2c0.7-1.1,1.3-0.4,1.5,0.1c0.2,0.5,2.1,0.4,2.6,0.9
            		c1.1,1.1,4.9,0.8,6.3,0.1c1.4-0.7-0.6-2.7-1.3-1.8c-0.7,0.9-1.3,0.4-1.5-0.1C174.9,94.7,176.5,94.5,177,93.8z"/>
            	<path :class="amcolor" d="M197.5,73.9c0.6,1.8,1.3,0.5,2.3,1.2c1,0.7,2.5,1.2,3.3,0.4c0.8-0.7,1.2-1.2,1.4-0.6c0.4,1,2.4,1.2,6.7,1.2
            		c4.3,0.1,3.2-1.8,4.5-1.1c1.2,0.7,4.1,0.7,5.4,0.4c1.2-0.3,1.9-2.2,1.8-3.2c-0.1-1-9.6-1.8-11.3-0.8c-1.7,1-4.4-0.4-5.7,0.3
            		c-1.3,0.7-0.9-0.9-3.3-1c-2.4-0.1,0.2-1.3,0.6-1.9c0.4-0.5-3.7-2-5.4-1.6c-1.7,0.4-2.7-0.5-4.3-1.2c-1.6-0.7-6-0.5-5.1,0.7
            		c0.6,0.9,5.8,2.8,6.3,1.9c0.5-0.9,1.9,1.1,2.7,2.1C198.3,71.7,196.9,72.1,197.5,73.9z"/>
            	<path :class="amcolor" d="M221.1,114.6c0.2-0.9-2.9-1.2-2.8-2.2c0.2-1-2.9-1.8-3.9-2.3c-1-0.5-2.5-0.8-2.5-1.9c0-1.1-2.1-0.3-2.2,1.3
            		c-0.1,1.6-1,1.2-0.5,2.8c0.4,1.5-2,1.9-1.7,2.7c0.3,0.8,1.4-0.1,2.5-0.1c1.1,0-0.3,1.5,1.1,1.6c1.1,0.1,2.9-1.2,3.4-1.9
            		c0.4-0.7,1.3-1,2.6-0.1C218.2,115.4,220.9,115.5,221.1,114.6z"/>
            	<path :class="amcolor" d="M214.7,119.5c1.4,0.9,3.6-1.5,3.4-2.2C217.9,116.6,212.9,118.4,214.7,119.5z"/>
            	<path :class="amcolor" d="M143.1,71.9c1.6,0.2,3.2-1.2,3.1-2.1C146.2,69,142.4,71.8,143.1,71.9z"/>
            	<path :class="amcolor" d="M132.4,86.5c1.6,0.4,1.8,3.2,2.9,3.2c0.7,0,0.2-0.9,1.3-0.9s0.9-0.6,2-0.5c1.1,0.1,2-0.3,2-1.5
            		c0-1.2,0.8-1.7,1.9-2c1.1-0.3,0.1-1.6,1.6-2c1.5-0.4,5.7-2.1,6.4-2.7c0.7-0.5-0.9-1.2-2.5-2.2c-1.6-1-2.9-1.2-4.1-0.4
            		c-1.2,0.8-0.3-0.9-1.2-0.4c-1,0.4-3.7-0.7-4-1.2c-0.4-0.4-5.2,1-6.3,1c-1.2,0,0.1,1.2,0.9,1.9c0.8,0.6-2,2.6-1.4,3
            		c0.5,0.4-0.5,1.6-1.8,3C128.7,86.3,130.8,86.2,132.4,86.5z"/>
            	<path :class="amcolor" d="M271.3,151.4c0.8-0.5-0.1-1-1-0.7c-0.9,0.4-3.3,3.6-3.3,4.5s-0.1,2-0.9,1.9c-0.8-0.1-1.8,0.7-1.1,1.2
            		c0.7,0.5-1.7,1.8-0.6,2.4c0.8,0.4,2-0.2,3.1-0.1c1.1,0.1,2,0.2,2.9-0.5c0.9-0.7,0.9,0.6,1.8,0.5c1-0.1-0.5,0.8-0.5,1.4
            		c0.1,0.7,1-0.4,1.7-0.7c0.7-0.4,1.6,0,1.2,0.6c-0.4,0.6,2,1.2,2.5,0.2c0.5-1-0.5-2.4-0.9-1.8c-0.4,0.7-0.9-0.5-0.1-1.1
            		c0.8-0.6-0.1-0.8-0.8-0.5c-0.7,0.4-0.5-1.6,0.1-2c0.7-0.4-1.6-1.1-1.6-0.4c0,0.7-1.6,0.2-1.8-0.4c-0.2-0.6-1.3-0.8-0.9-1.2
            		c0.4-0.4-1.4-0.7-1.5,0.1c-0.2,0.7-0.7-0.5,0.2-1.4C270.8,152.7,270.5,151.9,271.3,151.4z"/>
            	<path :class="amcolor" d="M253.7,155.2c0.2,0.7,5.4,2.9,5.5,1.9C259.4,156.1,253.6,154.6,253.7,155.2z"/>
            	<path :class="amcolor" d="M115.2,144.1c-1.6,0.9,2.3,4.9,3.1,4.4c0.6-0.4-1-2.1-1-3.1C117.3,144.4,116.8,143.3,115.2,144.1z"/>
            	<path :class="amcolor" d="M157.3,60.4c0.9,0.4,1.8-0.8,2.4-0.3c0.6,0.5,3.4,1,3.4-0.4c0-1.4-3.5-1.6-4.4-1.1c-0.9,0.5-5,0.7-3.8,1.6
            		C155.6,60.8,156.4,60,157.3,60.4z"/>
            	<path :class="amcolor" d="M161.7,62.1c1.2-0.2,0.6-1.1-1.2-0.8c-1.9,0.3-5.1,0.3-5.1,1.6c0,1.1,0.6,1.6,2.7,1.8c2.1,0.2,3.8-1.8,2.9-1.8
            		C159.9,62.9,160.5,62.3,161.7,62.1z"/>
            	<path :class="amcolor" d="M135.7,69.4c0.6,0.3,0,1.5,0.7,1.2c0.7-0.4,0.9-0.4,1.4-0.3c1,0.3,1.2-0.5,1.6-1c0.4-0.4,0.3,1.6,1.4,1.6
            		c1,0,0.8-2.1,1.7-1.5s1.2-0.3,1.3-0.9c0.2-0.6,0.2-1.7,1.2-1.9c1-0.2,0.2,1,0.6,1.8c0.6,1.2,1.8,0.1,1.9-0.4
            		c0.1-0.5,1.7-0.2,1.6-0.8c-0.1-0.6,0.4-0.8,0-1.5c-0.4-0.7,0.7-0.5,1.2-1.1c0.5-0.5-0.7-0.1-1.6-0.9c-0.9-0.8-1.2,0.3-1.2,0.8
            		c0,0.5-1.7,0.1-2.7-0.3c-1-0.4-2.2,1.2-3.7,1.9c-1.4,0.7-1.5,2-2.9,1.9C137.1,67.9,135.1,69.1,135.7,69.4z"/>
            	<path :class="amcolor" d="M154.3,62.5c0.4-1.1-3-0.9-2.7-0.5C152.2,62.8,153.9,63.6,154.3,62.5z"/>
            	<path :class="amcolor" d="M149.7,69c-1.2,0.1-1.2,1.3,0.3,1.3c1.4,0,1.9,0.4-0.2,0.4c-2.1-0.1-2.9,1.7-1.5,1.3c1.4-0.4,3.1-0.4,2.1,0
            		c-1.1,0.4-4,0.6-3.6,1.2c0.3,0.4,1.4,0.3,2.6,0.7s1.9,0.7,2.8-0.3c0.9-1,2.1-2,1.6-0.6c-0.4,1.3,1.4,0.4,3.8,0.3
            		c2.4-0.1-0.4,1.4-2.9,1.6s-2.1,1.4-0.1,1.9c2,0.4,6.2-1.2,7.1-2.2c1-1,1.9,0.5,2.9-0.2c1-0.7,2.9,0.1,4.5-0.3
            		c1.7-0.4,2-3.6,0.7-4.1c-1.2-0.5-1.2,0.6-2.2,0.8c-1,0.2-1.5-1.1-2.3-1.9c-0.8-0.8-0.3-2.3-1.4-2.1c-1.1,0.2-3.7,2.1-2.1,2.2
            		c1.5,0.2,1,1,0.2,1.3c-0.8,0.4,1.9,1,1.5,1.5c-0.4,0.5-4.5,0.1-4.6-0.8c-0.2-0.9-3.4-2.5-4.7-2.1c-1.3,0.4-0.8-1.1-2.1-1.2
            		C150.5,67.6,150.9,68.9,149.7,69z"/>
            	<path :class="amcolor" d="M67.6,222.7c-0.7,0.1-0.2,1.3,0.2,1C68.5,223.2,68.3,222.6,67.6,222.7z"/>
            	<path :class="amcolor" d="M69.1,224.4c-1.1,0.1-0.5,2.9,0.1,2.5c0.4-0.3,1.2-0.4,1.4-0.8C71,225.5,70.2,224.3,69.1,224.4z"/>
            	<path :class="amcolor" d="M75.8,131.2c0-0.5-0.7-0.4-1.6,0.6c-0.9,1-1.6,1.6-1.8,2.1c-0.1,0.5-1.2-0.1-1.5,0.5c-0.3,0.6,0.2,2,0.8,1.6
            		c0.6-0.4,0.7,0,1.1,0c0.4,0,2.2-1.2,2.6-1.9c0.4-0.6-0.7-0.8-0.8-1.2c0-0.4,0.9-0.4,1.4-0.7C76.7,132,75.7,131.7,75.8,131.2z"/>
            	<path :class="amcolor" d="M47.4,126c-0.1-0.7-2,0-1.8,0.6c0.2,0.7,2,1.1,2.8,0.8c0.7-0.2,0.9-0.9,0.4-1.4
            		C48.3,125.6,47.5,126.7,47.4,126z"/>
            	<path :class="amcolor" d="M53.2,141.7c-1-0.4-3.6,1.2-2.7,1.3c0.6,0.1,0.9-0.5,1.6-0.5C52.8,142.5,54.2,142.1,53.2,141.7z"/>
            	<path :class="amcolor" d="M45.6,145.7c1.1-0.4,1.5-1,1.1-1.5C46.2,143.7,44.8,146.1,45.6,145.7z"/>
            	<path :class="amcolor" d="M60.7,220.5c0.6,0.6,1,0.5,1.2-0.1C62,219.8,60,219.8,60.7,220.5z"/>
            	<path :class="amcolor" d="M63.8,221.8c0.4,0.7,0.9,0.8,1.2,0.2C65.4,221.4,63.3,221,63.8,221.8z"/>
            	<path :class="amcolor" d="M226.1,213.6c-0.4-0.7-0.7-0.2-0.7,0.6c0,0.8,0.7,0.9,0.7,1.4c0,0.5,0.7,1.1,0.8,0.8c0.2-0.5-0.5-0.9-0.5-1.2
            		C226.3,214.8,226.6,214.3,226.1,213.6z"/>
            	<path :class="amcolor" d="M259,245.2c0.2,0.2,1.1,0,1.5-0.2c0.4-0.2,0.4-0.8-0.1-1.2c-0.5-0.4-1.9,0.2-1.5,0.4
            		C259.4,244.4,258.8,244.9,259,245.2z"/>
            </g>
            </svg>
          </el-col>
        </el-row>
        <el-row type="flex" id="countrylist">
          <el-col :xs="0" :sm="3">&nbsp;</el-col>
          <el-col :xs="0" :sm="18">
            <el-collapse v-model="listindex">
              <el-collapse-item v-for="(region,index) in this[this.$route.params.title]" :name="index">
                <template slot="title">
                  <img :src="require('../assets/'+region.url+'.svg')" width="40" alt="">
                  <span style="margin-left:10px;font-size:25px;color:black;" v-show="region.name.indexOf('-')<0">{{region.name}}</span>
                  <span style="margin-left:10px;font-size:25px;color:black;" v-show="region.name.indexOf('-')>0">{{region.name.replace('-',' ')}}</span>
                </template>
                <el-row>
                  <el-col :sm="16">
                    <div v-for="shop in region.shops" style="font-size:19px;float:left;text-align:left;">
                      <p style="font-weight:bold;display:inline-block;margin:10px 0 0 0;">{{shop.name}}</p>
                      <br>
                      <p style="display:inline-block;text-align:left;margin:0px;min-width:500px;">{{shop.address}}</p>
                      <br>
                    </div>
                  </el-col>
                  <el-col :sm="8">
                    <img :src="require('../assets/'+region.url+'Pic.jpg')" style="" alt="">
                    <br>
                    <div style="float:left;margin-top:10px;margin-left:24px;text-align:left;">
                      <img v-show="region.link.fb.length!=0" src="../assets/facebook.svg" width="24" style="margin-right:7px;text-align:left;cursor:pointer;" alt="" @click="openNew(region.link.fb)">
                      <img v-show="region.link.ig.length!=0" src="../assets/instagram.svg" width="24" style="margin-right:7px;text-align:left;cursor:pointer;" alt="" @click="openNew(region.link.ig)">
                      <img v-show="region.link.web.length!=0" src="../assets/shopweb.svg" style="cursor:pointer;" alt="" @click="openNew(region.link.web)">
                    </div>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-col>
          <el-col :xs="0" :sm="3">&nbsp;</el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="24" :sm="0">
            <el-row style="margin-bottom:20px;">
              <el-select v-model="value" :placeholder="value" style="width:100%;" @change="select(value)">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-row>
              <span v-if="this.$route.params.title!=undefined">
              <div v-for="(region,index) in this[this.$route.params.title]" :name="index">
                <el-row style="text-align:left;">
                  <img :src="require('../assets/'+region.url+'.svg')" width="20" style="" alt="">
                  <span style="margin-left:5px;margin-bottom:15px;font-size:22px;color:black;" v-show="region.name.indexOf('-')<0">{{region.name}}</span>
                  <span style="margin-left:5px;margin-bottom:15px;font-size:22px;color:black;" v-show="region.name.indexOf('-')>0">{{region.name.replace('-',' ')}}</span>
                </el-row>
                <el-row>
                  <el-col :xs="24" :sm="0">
                    <div v-for="shop in region.shops" style="font-size:14px;float:left;text-align:left;">
                      <p style="font-weight:bold;display:inline-block;margin:10px 0 0 0;">{{shop.name}}</p>
                      <br>
                      <p style="display:inline-block;text-align:left;margin:0px;">{{shop.address}}</p>
                      <br>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :xs="24" :sm="0" style="margin-bottom:30px;">
                    <img :src="require('../assets/'+region.url+'Pic.jpg')" style="width:100%;margin-top:20px;" alt="">
                    <br>
                    <div style="float:left;margin-top:10px;margin-left:0px;text-align:left;">
                      <img v-show="region.link.fb.length!=0" src="../assets/facebook.svg" width="24" style="margin-right:7px;text-align:left;cursor:pointer;" alt="" @click="openNew(region.link.fb)">
                      <img v-show="region.link.ig.length!=0" src="../assets/instagram.svg" width="24" style="margin-right:7px;text-align:left;cursor:pointer;" alt="" @click="openNew(region.link.ig)">
                      <img v-show="region.link.web.length!=0" src="../assets/shopweb.svg" style="cursor:pointer;" alt="" @click="openNew(region.link.web)">
                    </div>
                  </el-col>
                </el-row>
              </div>
              </span>
          </el-col>
        </el-row>
      </el-main>
      <el-footer style="height:143px;padding:0px;">
        <Footer/>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import {gsap} from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Shop',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      listindex:['0'],
      index:999,
      eucolor: 'st0',
      asiacolor: 'st0',
      oceancolor: 'st0',
      amcolor: 'st0',
      continent: 'asia',
      value: 'am',
      options: [
        {label:'America',value:'am'},
        {label:'Oceania',value:'ocean'},
        {label:'Europe',value:'eu'},
        {label:'Asia',value:'asia'}],
      asia:[
        {
          name:this.$t('China'),
          url:'China',
          shops:[
            {
              name:'Grand Classic, Fuzhou',
              address:'7F, Grand Classic Store, No268, 817 North Road, Gulou District, Fuzhou',
            },
          ],
          link:{
            fb:'',
            ig:'',
            web:'',
          }
        },
        {
          name:this.$t('Japan'),
          url:'Japan',
          shops:[
            {
              name:this.$t('Kyomachi'),
              address:this.$t('1-2-11 Uomachi Kokurakita, Kitakyushu, Fukuoka'),
            },
            {
              name:this.$t('Uomachi'),
              address:this.$t('3-2-19 Uomachi Kokurakita, Kitakyushu, Fukuoka'),
            },
            {
              name:this.$t('Kokura Castle'),
              address:this.$t('2-1 Jonai, Kokurakita Ward, Kitakyushu, Fukuoka'),
            },
          ],
          link:{
            fb:'https://www.facebook.com/kokura.tsujiri/',
            ig:'https://www.instagram.com/tsujiri_japan/',
            web:'http://www.tsujiri.co.jp/',
          },
        },
        {
          name:this.$t('Philippines'),
          url:'Philippines',
          shops:[
            {
              name:'S Maison',
              address:'2nd Floor S Maison, Seaside Blvd, Pasay, Metro Manila',
            },
            {
              name:'Podium',
              address:'2/F Phase 2, The Podium, ADB Ave., Ortigas Center, Mandaluyong, Metro Manila',
            },
            {
              name:'Aura',
              address:'SM Aura Premium, Unit 012 Lower Ground Floor  26th St Cor Mckinley Parkway Fort Bonifacio Taguig City',
            },
            {
              name:'Greenbelt',
              address:'Ayala Center, Unit 2-049, 2nd Floor, Greenbelt Dr, Makati City',
            },
            {
              name:'Rockwell',
              address:'3F, Power Plant Mall, Rockwell, Makati City',
            },
            {
              name:'SM City Clark',
              address:'Manuel A. Roxas Highway, Clark Freeport, Barangay Malabanias, Angeles City, Pampanga, Philippines',
            },
          ],
          link:{
            fb:'https://www.facebook.com/tsujiri.ph/',
            ig:'https://www.instagram.com/tsujiri_ph/',
            web:'',
          },
        },
        {
          name:this.$t('Singapore'),
          url:'Singapore',
          shops:[
            {
              name:'The Central',
              address:'#01-74 The Central, 6 Eu Tong Sen Street, 059817',
            },{
              name:'White Sand',
              address:'1 Pasir Ris Central St 3 #01-27 White Sands Singapore 518457',
            },{
              name:'Sengkang Grand Mall',
              address:'70 Compassvale Bow, Singapore 544692',
            },{
              name:'Tanjong Pagar',
              address:'124 Tanjong Pagar Road, Singapore 088533',
            },{
              name:'One Holland Village',
              address:'7 Holland Village Way, Singapore 275748',
            },
          ],
          link:{
            fb:'https://www.facebook.com/tsujiri.sg/',
            ig:'https://www.instagram.com/tsujiri_sg/',
            web:'http://www.tsujiri.sg/',
          },
        },
        {
          name:this.$t('Taiwan'),
          url:'Taiwan',
          shops:[
            {
              name:'Taipei Xinyi Far Eastern',
              address:'B2F., No. 58, Songren Rd., Xinyi Dist., Taipei City',
            },
          ],
          link:{
            fb:'https://www.facebook.com/tsujiri.tw/',
            ig:'https://www.instagram.com/tsujiri_tw/',
            web:'http://tsujiri.com.tw/',
          },
        },
        {
          name:this.$t('Thailand'),
          url:'Thailand',
          shops:[
            {
              name:'Central World',
              address:'999, Central World 7th Floor No. KB703, 9 Rama I Rd, Pathum Wan District, Bangkok',
            },
            {
              name:'Thonglor',
              address:'101 Thong Lo Rd, Khlong Tan Nuea, Watthana, Bangkok',
            },
          ],
          link:{
            fb:'https://www.facebook.com/tsujiri.th/',
            ig:'https://www.instagram.com/tsujiri_th/',
            web:'',
          },
        },
      ],
      am:[
        {
          name:this.$t('Canada(Alberta)'),
          url:'Canada(Alberta)',
          shops:[
            {
              name:'Edmonton',
              address:'10173 109 St NW, Edmonton, AB T5J 3M5',
            },
            {
              name:'Calgary',
              address:'205B 16 Ave NE Calgary, AB T2E 1J9',
            },
          ],
          link:{
            fb:'https://www.facebook.com/pg/TSUJIRIAlberta/',
            ig:'https://www.instagram.com/tsujiri_edmonton/',
            web:'https://tsujirialberta.com/',
          },
        },
        {
          name:this.$t('Canada(British Columbia)'),
          url:'Canada(British-Columbia)',
          shops:[
            {
              name:'Richmond',
              address:'1535-4777 Mcclelland Road Richmond, BC V6X 0M5',
            },
            {
              name:'Toyota Richmond',
              address:'13100 Smallwood Place, Richmond, BC, Canada, BC V6V 2B6',
            },
          ],
          link:{
            fb:'https://www.facebook.com/TsujiriRichmond/',
            ig:'https://www.instagram.com/tsujiri_bc/',
            web:'',
          },
        },
        {
          name:this.$t('Canada(Ontario)'),
          url:'Canada(Ontario)',
          shops:[
            {
              name:'Dundas',
              address:'147 Dundas St W, Toronto, ON M5G 1C5',
            },
            {
              name:'Waterloo',
              address:'Unit 3, 330 Phillip St. Waterloo, ON, Canada, ON N2L 3W9',
            },
          ],
          link:{
            fb:'https://www.facebook.com/TSUJIRIToronto/',
            ig:'https://www.instagram.com/tsujiri_toronto/',
            web:'',
          },
        },
        {
          name:this.$t('Canada(Quebéc)'),
          url:'Canada(Quebéc)',
          shops:[
            {
              name:'Montreal',
              address:'1420 Crescent St, Montreal, QC H3G 2B7',
            },
          ],
          link:{
            fb:'',
            ig:'',
            web:'',
          },
        },
      ],
      ocean:[
        {
          name:this.$t('New Zealand'),
          url:'New-Zealand',
          shops:[
            {
              name:'Lorne Street',
              address:'Shop 4, 10-14 Lorne Street, Auckland Central, Auckland',
            },
            {
              name:'Newmarket Shop',
              address:'277/309 Broadway, Newmarket, Auckland',
            },
            {
              name:'Albany',
              address:'1/59 Corinthian Drive, Albany, Auckland 0632',
            },
          ],
          link:{
            fb:'https://www.facebook.com/tsujiri.nz/',
            ig:'https://www.instagram.com/tsujiri_nz/',
            web:'',
          },
        },
      ],
      eu:[
        {
          name:this.$t('United Kingdom'),
          url:'United-Kingdom',
          shops:[
            {
              name:'Newport Court',
              address:'33 Newport Ct., London WC2H 7PQ England ',
            },{
              name:'Manchester',
              address:'Upper Ground Floor, 50 Faulkner Street, Manchester M1 4FH England ',
            },{
              name:'Liverpool',
              address:'29E Hope St, Liverpool L1 9BQ England ',
            },{
              name:'Camden',
              address:'1 Water Lane, London NW1 8NZ England ',
            },{
              name:'Newcastle',
              address:'66 St Andrew’s street. Newcastle upon Tyne, NE1 5SF England ',
            },{
              name:'Manchester Circle Square',
              address:'Unit T3B, 3 Valette Walk, Circle Square, Manchester, M1 7GA England ',
            },
          ],
          link:{
            fb:'https://www.facebook.com/tsujiriuk/',
            ig:'https://www.instagram.com/tsujiri_uk/',
            web:'https://tsujiri.co.uk/',
          },
        },
      ],
    };
  },
  mounted() {
    window.scroll(0,0)
  },
  methods: {
    hover(x,y){
      var color = x+'color'
      if(this[color]=='st0'){
        //const whole = document.getElementById('mapsvg')
        this[color]='st1'
      }else{
        this[color]='st0'
      }
    },
    select(a){
      var url = '/shop/'+a
      this.$router.push(url)
      this.listindex = []
      gsap.to(window, {duration: 0.8, scrollTo: "#countrylist", ease: "power4"});
    },
    openNew(url){
      window.open(url)
    },
  },
};
</script>
<style>
.el-input__inner{
  background-color: rgba(255,255,255,0.3);
}
.el-collapse-item__wrap{
  background-color: transparent;
}
.el-collapse {
  border-top: 1px solid rgb(151,151,151,.3);
  border-bottom: 1px solid rgb(151,151,151,.3);
}
.el-collapse-item{
  border-bottom: 1px solid rgb(151,151,151,.3);
}
.el-collapse-item__header{
  height:70px;
  background-color: transparent;
  border:0px;
}
</style>
<style scoped>
#shop{
  background-image: url('../assets/aboutbg.webp');
  background-repeat: repeat-y;
  background-size: 100%;
}
.st0{fill:#7BAAA1;stroke:#7BAAA1;stroke-miterlimit:10;}
.st1{fill:#0B7560;stroke:#0B7560;stroke-miterlimit:10;}
.st2{fill:#CACBCC;stroke:#CACBCC;stroke-miterlimit:10;}
</style>
